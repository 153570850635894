import AuStaFragment from './fragment/au-sta-fragment';
import GenderStaFragment from './fragment/gender-sta-fragment';
import IapChartFragment from './fragment/iap-chart-fragment';
import IapStaFragment from './fragment/iap-sta-fragment';
import LoginChartFragment from './fragment/login-chart-fragment';
import SignupChartFragment from './fragment/signup-chart-fragment';
import SignupStaFragment from './fragment/signup-sta-fragment';
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";

const Dashboard = ({ rootStore }) => {
  if (!rootStore.isSuper) {
    return null;
  }

  return (
    <div>
      <div className="row">
        <div className="col-xl-2 col-md-5">
          <IapStaFragment />
        </div>
        <div className="col-xl-4 col-md-7">
          <SignupStaFragment />
        </div>
        <div className="col-xl-4 col-md-6">
          <GenderStaFragment />
        </div>
        <div className="col-xl-2 col-md-6">
          <AuStaFragment />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8">
          <IapChartFragment />
        </div>
        <div className="col-xl-4">
          <div className="row">
            <div className="col-xl-12">
              <SignupChartFragment />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <LoginChartFragment />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default withRouter(inject('rootStore')(Dashboard));
