import { Component, createRef } from "react";
import DateTime from 'react-datetime';
import moment from "moment";
import $ from 'jquery';

import strings from "../../lang/strings";
import Container from "../../components/control/container";
import DataTable from "../../components/control/data-table";
import { FORMAT } from "../../config/const";

class StaSignup extends Component {

  constructor(props) {
    super(props);

    this.tableRef = createRef();
    this.state = {
      type: 0,
      date_from: '',
      date_to: ''
    }
  }

  refreshTable = refresh => {
    this.tableRef?.current.draw(refresh);
  }

  changeViewMode = (type) => {
    this.setState({
      type: type
    }, () => {
      this.refreshTable(true);
    });
  }

  render() {
    const self = this;
    let columnNo = 0;

    return (
      <Container title={strings.menu.sta_signup}>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form-horizontal form-inline'>
              <div className="btn-group">
                <button
                  className={'btn btn-sm ' + (this.state.type === 0 ? 'btn-success' : 'btn-white')}
                  onClick={() => this.changeViewMode(0)}>{strings.sta.daily}</button>
                <button
                  className={'btn btn-sm ' + (this.state.type === 1 ? 'btn-success' : 'btn-white')}
                  onClick={() => this.changeViewMode(1)}>{strings.sta.monthly}</button>
              </div>
              <DateTime
                dateFormat={FORMAT.DATE}
                timeFormat={false}
                inputProps={{ placeholder: strings.sta.select_start_time }}
                defaultValue={this.state.date_from}
                closeOnSelect={true}
                className="ml-md-2"
                onChange={(e) => {
                  if (typeof e === 'string') {
                    this.setState({ date_from: e });
                  } else {
                    this.setState({ date_from: e.format(FORMAT.DATE) });
                  }
                }}/>
              <DateTime
                dateFormat={FORMAT.DATE}
                timeFormat={false}
                inputProps={{ placeholder: strings.sta.select_end_time }}
                defaultValue={this.state.date_to}
                closeOnSelect={true}
                className="ml-md-1"
                onChange={(e) => {
                  if (typeof e === 'string') {
                    this.setState({ date_to: e });
                  } else {
                    this.setState({ date_to: e.format(FORMAT.DATE) });
                  }
                }}/>
              <button
                type='button' className='btn btn-success btn-sm ml-md-2'
                onClick={() => this.refreshTable(true)}>
                {strings.common.search}
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-7 mt-3'>
            <DataTable
              ref={this.tableRef}
              options={{
                columnDefs: [{
                  targets: [columnNo++], data: 'date', orderable: false,
                  render: value => {
                    if (self.state.type === 1) {
                      return value;
                    } else {
                      const week_idx = moment(value).weekday();
                      const week_color = week_idx == 0 ? 'red' : week_idx == 6 ? 'blue' : '';
                      return value + ` <span style="color: ${week_color}">(${strings.sta.week_list[week_idx]})</span>`;
                    }
                  }
                }, {
                  targets: [columnNo++], data: 'google_male_count', orderable: false,
                  render: value => value.currencyFormat()
                }, {
                  targets: [columnNo++], data: 'google_female_count', orderable: false,
                  render: value => value.currencyFormat()
                }, {
                  targets: [columnNo++], data: 'google_count', orderable: false,
                  createdCell: (td, value) => {
                    $(td).html('<span style="color: blue">' + value.currencyFormat() + '</span>');
                  }
                }, {
                  targets: [columnNo++], data: 'apple_male_count', orderable: false,
                  render: value => value.currencyFormat()
                }, {
                  targets: [columnNo++], data: 'apple_female_count', orderable: false,
                  render: value => value.currencyFormat()
                }, {
                  targets: [columnNo++], data: 'apple_count', orderable: false,
                  createdCell: (td, value) => {
                    $(td).html('<span style="color: blue">' + value.currencyFormat() + '</span>');
                  }
                }, {
                  targets: [columnNo++], data: 'onestore_male_count', orderable: false,
                  render: value => value.currencyFormat()
                }, {
                  targets: [columnNo++], data: 'onestore_female_count', orderable: false,
                  render: value => value.currencyFormat()
                }, {
                  targets: [columnNo++], data: 'onestore_count', orderable: false,
                  createdCell: (td, value) => {
                    $(td).html('<span style="color: blue">' + value.currencyFormat() + '</span>');
                  }
                }, {
                  targets: [columnNo++], data: 'invite_count', orderable: false,
                  render: value => value.currencyFormat()
                }, {
                  targets: [columnNo++], data: 'male_count', orderable: false,
                  render: value => '<b>' + value.currencyFormat() + '</b>'
                }, {
                  targets: [columnNo++], data: 'female_count', orderable: false,
                  render: value => '<b>' + value.currencyFormat() + '</b>'
                }, {
                  targets: [columnNo++], data: 'total_count', orderable: false,
                  createdCell: (td, value) => {
                    $(td).html('<span style="color: blue">' + value.currencyFormat() + '</span>');
                  }
                },],
                order: [[1, 'DESC']],
                ajax: {
                  url: 'sta/signup',
                  data: (req) => {
                    req.date_from = self.state.date_from;
                    req.date_to = self.state.date_to;
                    req.is_monthly = self.state.type;
                  }
                },
              }}>
              <thead>
              <tr>
                <th rowSpan={2} width="20%">{strings.sta.date}</th>
                <th colSpan={3}>{strings.sta.sales_google}</th>
                <th colSpan={3}>{strings.sta.sales_appstore}</th>
                <th colSpan={3}>{strings.sta.sales_onestore}</th>
                <th rowSpan={2}>{strings.sta.referrer_signup}</th>
                <th rowSpan={2}>{strings.common.male}</th>
                <th rowSpan={2}>{strings.common.female}</th>
                <th rowSpan={2}>{strings.common.total}</th>
              </tr>
              <tr>
                <th>{strings.common.male}</th>
                <th>{strings.common.female}</th>
                <th>{strings.common.total}</th>
                <th>{strings.common.male}</th>
                <th>{strings.common.female}</th>
                <th>{strings.common.total}</th>
                <th>{strings.common.male}</th>
                <th>{strings.common.female}</th>
                <th>{strings.common.total}</th>
              </tr>
              </thead>
              <tbody/>
            </DataTable>
          </div>
        </div>
      </Container>
    );
  }
}

export default StaSignup;
