import React from 'react';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { rootStore } from "./mobx/store";
import { inject, observer } from "mobx-react";
import { ReactNotifications, Store } from 'react-notifications-component';
import Lightbox from 'react-image-lightbox';

import { PageSettings } from './config/page-settings.js';
import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content.jsx';
import LoadingBar from "./components/control/loading-bar";
import CommonModal from "./components/control/modal";
import strings from "./lang/strings";
import { API_URL, GENDER, HEADER, RESULT_CODE } from "./config/const";
import MemberDetailPopup from "./pages/member/popup/detail-popup";
import ConciergePopup from "./pages/member/popup/concierge-popup";


class App extends React.Component {
  constructor(props) {
    super(props);

    this.toggleMobileSidebar = () => {
      this.setState(state => ({
        pageSidebarToggled: !state.pageSidebarToggled
      }));
    }
    this.handleSetPageSidebar = (value) => {
      this.setState({
        pageSidebar: value
      });
    }
    this.handleSetPageHeader = (value) => {
      this.setState({
        pageHeader: value
      });
    };
    this.handleSetPageBoxedLayout = (value) => {
      if (value === true) {
        document.body.classList.add('boxed-layout');
      } else {
        document.body.classList.remove('boxed-layout');
      }
    }
    this.toggleLoadingBar = isLoading => {
      this.setState({ pageLoading: isLoading });
    }
    this.addNotification = (title, message, type = 'success') => {
      Store.addNotification({
        title,
        message,
        type,
        insert: 'top',
        container: 'top-right',
        /*animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],*/
        dismiss: {
          duration: 5000,
          // onScreen: true
        }
      });
    }
    this.showGallery = (image_list, index = 0) => {
      this.setState({
        galleryShow: true,
        galleryData: image_list,
        galleryIndex: index,
      });
    }
    this.showModal = (title, text, okBtn, cancelBtn, cb) => {
      this.setState({
        modal: {
          show: true,
          title: title,
          text: text,
          subText: '',
          okBtn: okBtn,
          cancelBtn: cancelBtn,
          cb: cb
        }
      });
    }
    this.showAlert = (text, cb, okBtn = strings.modal.confirm) => {
      this.showModal(strings.modal.alert, text, okBtn, '', cb);
    }
    this.showConfirm = (text, cb, okBtn = strings.modal.ok, cancelBtn = strings.modal.no) => {
      this.showModal(strings.modal.confirm, text, okBtn, cancelBtn, cb);
    }
    this.showUserPopup = (user_id, callback) => {
      this.setState(state => ({
        member_detail: {
          ...state.member_detail,
          isOpen: true,
          id: user_id,
          callback: callback
        }
      }));
    }
    this.hideUserPopup = () => {
      this.setState({
        member_detail: {
          isOpen: false,
        }
      });
    }
    this.showConciergePopup = (user, callback) => {
      this.setState(state => ({
        concierge_popup: {
          ...state.concierge_popup,
          isOpen: true,
          user,
          callback
        }
      }));
    }
    this.hideConciergePopup = () => {
      this.setState(state => ({
        concierge_popup: {
          ...state.concierge_popup,
          isOpen: false
        }
      }), () => this.state.concierge_popup.callback?.());
    }
    this.getSettingList = (...name) => {
      let item = this.props.rootStore.getSetting;
      for (props of name) {
        item = item[props];
      }
      return item;
    }
    this.getSettingName = (code, ...name) => {
      let item = this.props.rootStore.getSetting;

      if (name == 'auth') {
        return item[name]?.find(it => it.code == code)?.name ?? '';
      }

      for (props of name) {
        item = item[props];
      }
      return Array.isArray(item) ? (item?.find(it => it.code == code)?.name ?? '') : item[code];
    }
    this.api = axios.create({
      baseURL: API_URL + '/admin/',
      headers: {
        [HEADER.LANG]: 'ko'
      }
    })
    this.post = async (uri, params, cb, showProgress = true) => {
      await this.request('POST', uri, params, cb, showProgress);
    }
    this.get = async (uri, params, cb, showProgress = true) => {
      await this.request('GET', uri, params, cb, showProgress);
    }
    this.delete = async (uri, params, cb, showProgress = true) => {
      await this.request('DELETE', uri, params, cb, showProgress);
    }
    this.request = async (method, uri, params, cb, showProgress = true) => {
      if (showProgress) {
        this.toggleLoadingBar(true);
      }

      this.api.defaults.headers.common[HEADER.AUTH_TOKEN] = this.props.rootStore.token;

      try {
        let { data } = method !== 'GET' ?
          await this.api[method.toLowerCase()](uri, params)
          :
          await this.api.get(uri, { params });

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        if (Number(data.result_code) === RESULT_CODE.SUCCESS) {
          cb?.(data.result_data);
        } else {
          this.handleApiError(data);
        }
      } catch (err) {
        console.log(err);

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        this.showAlert(err.message);
      }
    }

    this.handleApiError = e => {
      if (Number(e.result_code) === RESULT_CODE.EMPTY_TOKEN ||
        Number(e.result_code) === RESULT_CODE.TOKEN_EXPIRED) {
        this.props.history.replace('/login');
        return;
      }

      this.showAlert(e.result_msg);
    }

    this.resetAlarmList = () => {
      this.setState(state => ({
        resetAlarm: !state.resetAlarm
      }));
    }

    this.state = {
      hasScroll: false,

      pageHeader: true,
      handleSetPageHeader: this.handleSetPageHeader,

      pageSidebar: true,
      pageSidebarToggled: false,
      handleSetPageSidebar: this.handleSetPageSidebar,
      toggleMobileSidebar: this.toggleMobileSidebar,

      handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

      pageLoading: false,
      toggleLoadingBar: this.toggleLoadingBar,

      modal: {
        show: false,
        title: '',
        text: '',
        subText: '',
        okBtn: '',
        cancelBtn: '',
        cb: null
      },
      showModal: this.showModal,
      showAlert: this.showAlert,
      showConfirm: this.showConfirm,

      addNotification: this.addNotification,

      showGallery: this.showGallery,
      galleryShow: false,
      galleryData: [],
      galleryIndex: 0,

      post: this.post,
      get: this.get,
      delete: this.delete,

      getSettingName: this.getSettingName,
      getSettingList: this.getSettingList,

      member_detail: {
        isOpen: false,
        id: 0,
        callback: null
      },
      showUserPopup: this.showUserPopup,

      concierge_popup: {
        isOpen: false,
        user: {},
        callback: null
      },
      showConciergePopup: this.showConciergePopup,

      resetAlarm: false,
      resetAlarmList: this.resetAlarmList,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.getSettingInfo();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getSettingInfo = async () => {
    await this.get(
      'dashboard/setting',
      {},
      response => {
        this.props.rootStore.saveSetting(response);
      },
      false
    );
  }

  handleScroll = () => {
    this.setState({
      hasScroll: window.scrollY > 0
    });
    let elm = document.getElementsByClassName('nvtooltip');
    for (let i = 0; i < elm.length; i++) {
      elm[i].classList.add('d-none');
    }
  }

  onModalClose = res => {
    this.setState(state => ({
      modal: { ...state.modal, show: false }
    }), () => this.state.modal.cb?.(res));
  }

  render() {
    return (
      <PageSettings.Provider value={this.state}>
        <div className={
          'fade page-sidebar-fixed show page-container page-header-fixed ' +
          (this.state.pageSidebar ? '' : 'page-without-sidebar ') +
          (this.state.pageSidebarToggled ? 'page-sidebar-toggled ' : '') +
          (this.state.hasScroll ? 'has-scroll ' : '')
        }>
          <ReactNotifications />
          {this.state.pageHeader && <Header />}
          {this.state.pageSidebar && <Sidebar />}
          <Content />
          <CommonModal {...this.state.modal} onModalClose={this.onModalClose} />
          {this.state.galleryShow &&
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 10000 } }}
            mainSrc={this.state.galleryData[this.state.galleryIndex]}
            nextSrc={this.state.galleryData[(this.state.galleryIndex + 1) % this.state.galleryData.length]}
            prevSrc={this.state.galleryData[(this.state.galleryIndex + this.state.galleryData.length - 1) % this.state.galleryData.length]}
            onCloseRequest={() => this.setState({
              galleryShow: false,
              galleryData: [],
              galleryIndex: 0
            })}
            onMovePrevRequest={() =>
              this.setState({
                galleryIndex: (this.state.galleryIndex + this.state.galleryData.length - 1) % this.state.galleryData.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                galleryIndex: (this.state.galleryIndex + 1) % this.state.galleryData.length,
              })
            }
          />
          }
          {this.state.pageLoading && <LoadingBar />}
          <MemberDetailPopup
            {...this.state.member_detail}
            onClose={() => this.hideUserPopup()}
          />
          <ConciergePopup
            {...this.state.concierge_popup}
            onClose={() => this.hideConciergePopup()} />
        </div>
      </PageSettings.Provider>
    )
  }
}

export default withRouter(inject('rootStore')(observer(App)));
