import React from 'react';
import { Link, Route } from 'react-router-dom';

class SidebarNavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1
    };
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    this.setState(state => ({
      active: (state.active === i ? -1 : i),
      clicked: 1
    }));
  }

  render() {
    let icon = this.props.data.icon && <i className={this.props.data.icon}/>;
    let img = this.props.data.img && <div className="icon-img"><img src={this.props.data.img} alt=""/></div>;
    let caret = (this.props.data.children && !this.props.data.badge) && <b className="caret"/>;
    let label = this.props.data.label && <span className="label label-theme m-l-5">{this.props.data.label}</span>;
    let badge = this.props.data.badge && <span className="badge pull-right">{this.props.data.badge}</span>;
    let title = this.props.data.title && <span>{this.props.data.title} {label}</span>;
    let highlight = this.props.data.highlight && <i className="fa fa-paper-plane text-theme"/>;

    return (
      <Route path={this.props.data.path} exact={this.props.data.exact} children={({match}) => (
        <li
          className={(match ? "active " : "") + ((this.props.active || (this.props.clicked === -1 && match) || this.props.data.search) ? 'expand ' : 'closed ') + (this.props.data.children ? "has-sub " : "")}>
          {this.props.data.children ? (
            <Link to={this.props.data.path}
                  onClick={this.props.expand}>{caret} {badge} {img} {icon} {title} {highlight}</Link>
          ) : (
            <Link to={this.props.data.path}>{caret} {img} {icon} {badge} {title} {highlight}</Link>
          )}
          {this.props.data.children && (
            <ul
              className={"sub-menu " + (((this.props.active || (this.props.clicked === -1 && match) || this.props.data.search)) ? 'd-block ' : 'd-none')}>
              {this.props.data.children && this.props.data.children.map((submenu, i) => (
                <SidebarNavList
                  data={submenu}
                  key={i}
                  expand={(e) => this.handleExpand(e, i, match)}
                  active={i === this.state.active}
                  clicked={this.state.clicked}
                />
              ))}
            </ul>
          )}
        </li>
      )}/>
    );
  }
}

export default SidebarNavList;
