import React, { useContext, useRef, useState } from "react";
import { PageSettings } from "../../config/page-settings";

import $ from "jquery";
import Container from "../../components/control/container";
import DataTable from "../../components/control/data-table";
import SearchSelect from "../../components/control/search-select";
import { STATUS } from "../../config/const";
import strings from "../../lang/strings";
import SignupMsgPopup from "./popup/signup-msg-popup";

const SignupMsg = () => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    id: 0
  });
  const hidePopup = () => setPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });

  const onAdd = id => {
    setPopup(prev => {
      return {
        ...prev,
        isOpen: true,
        id
      };
    });
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `setting/signup-msg/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  let areaList = [''];
  for (let area of context.getSettingList('resident')) {
    if (!areaList.includes(area.group_name)) {
      areaList.push(area.group_name);
    }
  }

  return (
    <Container title={strings.menu.setting_signup_msg}>
      <div className='row'>
        <div className='col-md-10 col-xl-8 col-xxl-4'>
          <div className='form-horizontal form-inline'>
            <div className="form-inline">
              <label className="col-form-label justify-content-end font-weight-bold mr-2">
                {strings.common.gender}:
              </label>
              <SearchSelect
                id='gender'
                value={''}
                data={[{ value: '', name: '전체' }, ...strings.search_option.gender]}
                onChange={v => {
                }} />
            </div>

            <div className="form-inline">
              <label className="col-form-label width-60 justify-content-end font-weight-bold mr-2">
                {strings.member.location}:
              </label>
              <div className="">
                <select className='form-control width-full' id='location'
                  onChange={() => { }}>
                  {areaList.map(it => <option key={it} value={it}>{it == '' ? '전체' : it}</option>)}
                </select>
              </div>
            </div>

            <button type='button' className='btn btn-success btn-sm ml-md-1'
              onClick={() => refreshTable(true)}>{strings.common.search}</button>
            <button type='button' className='btn btn-primary btn-sm ml-md-2'
              onClick={() => onAdd(0)}>{strings.common.add}</button>
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col-md-12'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'gender', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<a href="javascript:;">${value.genderStr()}</a>`)
                    .find('a')
                    .on('click', () => onAdd(item.id));
                },
              }, {
                targets: [columnNo++], data: 'location', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => onAdd(item.id));
                },
              }, {
                targets: [columnNo++], data: 'content', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => onAdd(item.id));
                },
              }, {
                targets: [columnNo++], data: 'image', orderable: false,
                createdCell: (td, value) => {
                  if (value) {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    });
                  } else {
                    $(td).html('');
                  }
                }
              }, {
                targets: [columnNo++], data: 'free_key', orderable: false,
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              order: [[4, 'DESC']],
              ajax: {
                url: 'setting/signup-msg',
                data: (req) => {
                  req.gender = $('#gender').val();
                  req.column = 'location'
                  req.keyword = $('#location').val();
                }
              },
            }}>
            <thead>
              <tr>
                <th>#</th>
                <th style={{ width: '10%' }}>{strings.common.gender}</th>
                <th style={{ width: '20%' }}>{strings.common.location}</th>
                <th style={{ width: '30%' }}>{strings.common.content}</th>
                <th>{strings.common.image}</th>
                <th>{strings.member.free_key}</th>
                <th>{strings.common.create_date}</th>
                <th>{strings.common.manage}</th>
              </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      {popup.isOpen &&
        <SignupMsgPopup {...popup} onClose={res => {
          hidePopup();
          if (res === true) {
            refreshTable(false);
          }
        }} />
      }
    </Container>
  );

}

export default SignupMsg;
