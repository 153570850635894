import React, { useRef, useState } from "react";
import DataTable from "../../../components/control/data-table";
import strings from "../../../lang/strings";
import FreeKeyPopup from "./free-key-popup";
import { USER_STATUS } from "../../../config/const";
import { inject } from "mobx-react";

const MemberDetailPopupKeyLog = ({ user, onRefresh, rootStore }) => {

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    id: 0,
    nickname: '',
  });

  const hidePopup = () => setPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });

  const onFreeKey = () => {
    if (!rootStore.isSuper) {
      return;
    }
    setPopup({
      isOpen: true,
      id: user.id,
      nickname: user.nickname,
    });
  }

  return (
    <div className='row'>
      <div className='col-md-8'>
        <div className='m-b-0'>
          <span className='m-r-10'>{strings.member.now} {strings.member.key}: </span>
          <h5 className='d-inline text-dark'>{user.key.currencyFormat()}</h5>
          {/*무료키 지급은 정상회원들에게만 하는걸로...*/}
          {
            user.status == USER_STATUS.NORMAL && rootStore.isSuper &&
            <button type='button' className='btn btn-sm btn-indigo m-l-10' onClick={onFreeKey}>
              {strings.member.free_key}
            </button>
          }
        </div>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'key', orderable: false,
              render: value =>
                value > 0 ? `<span class="male">${value}</span>` : `<span class="female">${value}</span>`
            }, {
              targets: [columnNo++], data: 'key_total', orderable: false,
              render: value => value.currencyFormat()
            }, {
              targets: [columnNo++], data: 'content', orderable: false,
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
              render: value => value.dateFormat()
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'member/key/log',
              data: (req) => {
                req.id = user.id;
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.member.change_key}</th>
            <th>{strings.member.total_key}</th>
            <th>{strings.common.content}</th>
            <th>{strings.member.change_date}</th>
          </tr>
          </thead>
          <tbody/>
        </DataTable>
      </div>
      <FreeKeyPopup
        {...popup}
        onSave={() => {
          onRefresh?.();
          refreshTable(false);
        }}
        onClose={hidePopup}/>
    </div>
  );
};

export default inject('rootStore')(MemberDetailPopupKeyLog);
