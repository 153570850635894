import React, { useContext, useEffect, useState } from "react";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { API_URL, STATUS } from "../../../config/const";
import ImageSelect from "../../../components/control/image-select";

const LoungePopupInfo = ({ id }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState({});
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    getInfo();
  }, [id]);

  const getInfo = () => {
    context.get(
      '/lounge/detail/' + id,
      {},
      response => {
        setInfo(response);
        setPhotos(response.photo.split('#').map(it => {
          return {
            url: it.getFullUrl().getThumbnailUrl(),
            origin: it.getFullUrl().getThumbnailUrl(),
            file: null
          };
        }));
      }
    );
  };
  const onChange = e => {
    setInfo(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  const onImageChange = (e, idx) => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setPhotos(prev => {
        return prev.map((it, index) => {
          if (index == idx) {
            it.url = reader.result;
            it.file = file;
          }
          return it;
        })
      });
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = index => {
    setPhotos(prev => {
      return prev.map((it, idx) => {
        if (idx == index) {
          if (it.file != null) {
            it.url = it.origin;
            it.file = null;
          } else {
            it.url = '';
            it.origin = '';
          }
        }
        return it;
      });
    });
  }

  const onSave = e => {
    e.preventDefault();

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      if (photos.filter(it => it.file != null).length > 0) {
        let formData = new FormData();
        photos.filter(it => it.file != null).map(it => it.file).forEach(file => {
          formData.append('file', file, file.name);
        });

        context.post(
          'upload/images/lounge',
          formData,
          response => {
            save(response.image);
          }
        );
        return;
      }

      save();
    });
  }

  const save = (upload_files = []) => {
    const photo = photos.map(it => {
      if (it.file != null && upload_files.length > 0) {
        return upload_files.shift();
      }
      return it.url.replace(API_URL + '/', '');
    }).join('#');

    context.post(
      'lounge/save',
      {
        id: info?.id,
        alias: info?.alias,
        category: info?.category,
        title: info?.title,
        content: info?.content,
        photo,
        mini_public: info?.mini_public,
        best: info?.best
      },
      response => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        setInfo(response);
      }
    );
  }

  return (
    <div className='row'>
      <form onSubmit={onSave}>
        <div className="col-xs-12">
          <div className='row'>
            <label className='col-xl-2 col-form-label'>ID</label>
            <label className='col-xl-8 col-form-label'>{info?.id}</label>
            {
              info?.status == STATUS.DELETED ?
                <label className='col-xl-2 col-form-label text-danger'>[{strings.common.deleted}]</label>
                :
                <button className='btn btn-sm btn-primary col-xl-2'>{strings.common.save}</button>
            }
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.random_nickname}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="alias"
                     value={info?.alias || ''} required={(info?.mini_public ?? STATUS.YES) != STATUS.YES}
                     onChange={onChange}/>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.category}</label>
            <div className="col-xl-8">
              <select className='form-control' value={info?.category || 1} id='category' onChange={onChange}>
                {context.getSettingList('lounge_category').filter(it => it.code > 1).map(it => (
                  <option key={it.code} value={it.code}>{it.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.common.title}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="title"
                     value={info?.title || ''} required
                     onChange={onChange}/>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.common.content}</label>
            <div className="col-xl-8">
              <textarea className='form-control' id="content"
                        value={info?.content || ''} rows={15} required
                        onChange={onChange}/>
            </div>
          </div>
          <div className='row m-t-5'>
            <label className='col-xl-2 col-form-label'>{strings.common.image}</label>
            <div className='col-xs-10'>
              <div className='row'>
                {photos.map((image, index) => (
                  <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3' key={index}>
                    <ImageSelect
                      height='80%'
                      image_url={image.url}
                      onSelect={e => onImageChange(e, index)}
                      onDelete={() => onImageDelete(index)}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.mini_public}</label>
            <div className='col-xs-8 form-inline'>
              <label className='control-label'
                     onClick={() => onChange({ target: { id: 'mini_public', value: STATUS.YES } })}>
                <input className='' type='radio' name='mini_public'
                       checked={(info?.mini_public ?? STATUS.YES) == STATUS.YES}
                       readOnly
                       value={STATUS.YES}/>&nbsp;{strings.lounge.public}
              </label>
              <label className='control-label m-l-10'
                     onClick={() => onChange({ target: { id: 'mini_public', value: STATUS.NO } })}>
                <input className='' type='radio' name='mini_public'
                       checked={info?.mini_public == STATUS.NO}
                       readOnly
                       value={STATUS.NO}/>&nbsp;{strings.lounge.private}
              </label>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.best}</label>
            <div className='col-xs-8 form-inline'>
              <label className='control-label'
                     onClick={() => onChange({ target: { id: 'best', value: STATUS.YES } })}>
                <input className='' type='radio' name='best'
                       checked={(info?.best ?? STATUS.YES) == STATUS.YES}
                       readOnly
                       value={STATUS.YES}/>&nbsp;{strings.lounge.best_setting}
              </label>
              <label className='control-label m-l-10'
                     onClick={() => onChange({ target: { id: 'best', value: STATUS.NO } })}>
                <input className='' type='radio' name='best'
                       checked={info?.best == STATUS.NO}
                       readOnly
                       value={STATUS.NO}/>&nbsp;{strings.lounge.best_release}
              </label>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.visit_cnt}</label>
            <label className='col-xl-8 col-form-label'>{info?.visit_cnt?.currencyFormat() ?? '-'}</label>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.like_cnt}</label>
            <label className='col-xl-8 col-form-label'>{info?.like_cnt?.currencyFormat() ?? '-'}</label>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.cmt_cnt}</label>
            <label className='col-xl-8 col-form-label'>{info?.cmt_cnt?.currencyFormat() ?? '-'}</label>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.blame_cnt}</label>
            <label className='col-xl-8 col-form-label'>{info?.blame_cnt?.currencyFormat() ?? '-'}</label>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.common.create_date}</label>
            <label className='col-xl-8 col-form-label'>{info?.create_date ?? '-'}</label>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoungePopupInfo;
