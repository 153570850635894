import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import querystring from "query-string";
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';

import strings from "../../lang/strings";
import { GENDER } from "../../config/const";

import AliasFragment from "./fragment/alias-fragment";

export const AliasTabs = {
  dessert: 'dessert',
  adjective: 'adjective',
};

const Alias = ({ location, history }) => {

  const [tab, setTab] = useState(AliasTabs.dessert);
  const [gender, setGender] = useState('');

  useEffect(() => {
    const searchQS = querystring.parse(location.search);
    setTab(searchQS.tab || AliasTabs.dessert);
    setGender(searchQS.gender || '');
  }, [location.search]);

  const toggleTab = (tab, gender) => {
    const searchQS = {};
    searchQS.tab = tab;
    if (gender) {
      searchQS.gender = gender;
    }
    history.push('?' + querystring.stringify(searchQS));
  }

  const tabs = [
    { tab: AliasTabs.dessert, gender: '', title: strings.alias.dessert },
    { tab: AliasTabs.adjective, gender: GENDER.FEMALE, title: `${strings.alias.adjective} (${strings.common.female})` },
    { tab: AliasTabs.adjective, gender: GENDER.MALE, title: `${strings.alias.adjective} (${strings.common.male})` },
  ];

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {tabs.map((menu, idx) => (
          <NavItem key={idx}>
            <NavLink className={classnames({ active: tab === menu.tab && gender === menu.gender })}
                     onClick={() => toggleTab(menu.tab, menu.gender)}>
              <span className="d-none d-xs-inline">{menu.title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        <AliasFragment tab={tab} gender={gender}/>
      </PanelBody>
    </Panel>
  );
}

export default withRouter(Alias);
