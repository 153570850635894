import { useContext, useState } from "react";
import classnames from "classnames";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import strings from "../../../lang/strings";
import { USER_STATUS } from "../../../config/const";
import { PageSettings } from "../../../config/page-settings";

import PenaltyPopup, { PenaltyType } from "../../lounge/popup/penalty-popup";
import MeetingPopupInfo from "./meeting-popup-info";
import MeetingPopupUser from "./meeting-popup-user";
import MeetingPopupChat from "./meeting-popup-chat";
import MeetingPopupReport from "./meeting-popup-report";
import MeetingPopupPay from "./meeting-popup-pay";
import MeetingPopupReportUser from "./meeting-popup-report-user";
import MeetingPopupReview from "./meeting-popup-review";

const MeetingPopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);
  const [activeTab, setActiveTab] = useState(0);

  const toggleModal = result => onClose?.(result);
  const toggleTab = tab => setActiveTab(tab);

  const onBlock = (user_id, user_status, callback) => {
    const content = user_status == USER_STATUS.BLOCK ? strings.modal.unblock_confirm : strings.modal.block_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/block',
        {
          id: user_id,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          callback?.();
        }
      );
    });
  }

  const [penalty, setPenaltyPopup] = useState({
    isOpen: false,
    type: PenaltyType.Meeting,
    user: null,
  });
  const hidePenaltyPopup = () => setPenaltyPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });
  const onPenalty = (user_id, user_nickname) => {
    setPenaltyPopup(prev => {
      return {
        ...prev,
        isOpen: true,
        user: { id: user_id, nickname: user_nickname },
      }
    });
  };

  const tabs = [{
    title: strings.meeting.meeting_info,
    component: () => <MeetingPopupInfo id={id}/>
  }, {
    title: strings.meeting.apply_list,
    component: () => <MeetingPopupUser id={id} onBlock={onBlock} onPenalty={onPenalty}/>
  }, {
    title: strings.meeting.pay_list,
    component: () => <MeetingPopupPay id={id}/>
  }, {
    title: strings.meeting.blame_list,
    component: () => <MeetingPopupReport id={id} onBlock={onBlock} onPenalty={onPenalty}/>
  }, {
    title: strings.meeting.chat_list,
    component: () => <MeetingPopupChat id={id} onBlock={onBlock} onPenalty={onPenalty}/>
  }, {
    title: strings.meeting.report_in_chat,
    component: () => <MeetingPopupReportUser id={id} onBlock={onBlock} onPenalty={onPenalty}/>
  }, {
    title: strings.menu.meeting_review,
    component: () => <MeetingPopupReview id={id}/>
  }];

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} onOpened={() => setActiveTab(0)} size={'xl'}>
        <ModalHeader className="popup-title" toggle={toggleModal}>
          {strings.meeting.detail}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-xl-2">
              {tabs.map((menu, idx) => (
                <button
                  key={`menu-${idx}`} type="button" onClick={() => toggleTab(idx)}
                  className={classnames(
                    'width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1',
                    { active: activeTab == idx }
                  )}>
                  {menu.title}
                </button>
              ))}
            </div>
            <div className="col-xl-10">
              {tabs.map((menu, idx) => (
                activeTab == idx &&
                <div key={`frg-${idx}`}
                     style={{ maxHeight: 'calc(100vh - 200px)', overflowX: 'hidden', overflowY: 'auto' }}>
                  {menu.component()}
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        </ModalFooter>
      </Modal>
      <PenaltyPopup {...penalty} onClose={() => {
        hidePenaltyPopup();
      }}/>
    </>
  );
}

export default MeetingPopup;
