import React, { useEffect, useState } from "react";
import strings from "../../lang/strings";
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from "reactstrap";
import { Panel, PanelBody } from "../../components/panel/panel";
import { withRouter } from "react-router-dom";
import querystring from "query-string";
import { GENDER } from "../../config/const";
import MemberPendingFragment from "./fragment/pending-fragment";

const MemberPending = ({ location, history, type }) => {

  const tabs = [
    { tab: '1', gender: '', title: strings.common.total },
    { tab: '2', gender: GENDER.FEMALE, title: strings.common.female },
    { tab: '3', gender: GENDER.MALE, title: strings.common.male },
    /*{ tab: '4', gender: GENDER.FEMALE, title: [strings.common.female, strings.member.reinspect].join(' ') },
    { tab: '5', gender: GENDER.MALE, title: [strings.common.male, strings.member.reinspect].join(' ') },*/
  ];

  const [tab, setTab] = useState(tabs[0].tab);
  const [gender, setGender] = useState(tabs[0].gender);

  useEffect(() => {
    const searchQS = querystring.parse(location.search);
    setTab(searchQS.tab || tabs[0].tab);
    setGender(searchQS.gender || tabs[0].gender);
  }, [location.search]);

  const toggleTab = (tab, gender) => {
    const searchQS = { tab };
    if (gender) {
      searchQS.gender = gender;
    }
    history.push('?' + querystring.stringify(searchQS));
  }

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {tabs.map(menu => (
          <NavItem key={menu.tab}>
            <NavLink
              className={classnames({ active: tab === menu.tab })}
              onClick={() => toggleTab(menu.tab, menu.gender)}>
              <span className="d-none d-xs-inline">{menu.title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        <MemberPendingFragment type={type} tab={tab} gender={gender}/>
      </PanelBody>
    </Panel>
  );
}

export default withRouter(MemberPending);
