import React, { useContext, useRef } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import Container from "../../components/control/container";

const MemberConcierge = () => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.member;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onCell = user_id => {
    context.showUserPopup(user_id, () => {
      refreshTable(false);
      context.resetAlarmList();
    });
  };

  const onChat = user => {
    context.showConciergePopup(user, () => refreshTable(false));
  }

  return (
    <Container title={strings.menu.member_concierge}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'email', orderable: false,
              }, {
                targets: [columnNo++], data: 'profile', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).image_lightbox(value, () => {
                    context.showGallery(value.split('#').map(it => it.getFullUrl()));
                  }, item.single);
                  if (item.unread_cnt > 0) {
                    $(td)
                      .css('position', 'relative')
                      .append('<span class="concierge-badge"></span>');
                  }
                }
              }, {
                targets: [columnNo++], data: 'nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(item.nickname, item.gender, () => onCell(item.id));
                }
              }, {
                targets: [columnNo++], data: 'level', orderable: false,
                render: value => strings.member.level_list[value],
              }, {
                targets: [columnNo++], data: 'status', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).user_status(item);
                }
              }, {
                targets: [columnNo++], data: 'birth', orderable: false,
                render: value => value.ageFormat()
              }, {
                targets: [columnNo++], data: 'phone', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(item.phone + '<br/>' + item.name);
                }
              }, {
                targets: [columnNo++], data: 'location', orderable: false,
              }, {
                targets: [columnNo++], data: 'job', orderable: false,
              }, {
                targets: [columnNo++], data: 'key', orderable: false,
                render: value => value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'signup_date', orderable: false,
                render: value => value.dateFormat()
              }, {
                targets: [columnNo++], data: 'login_date', orderable: false,
                render: value => value ? value.dateFormat() : '-'
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`[<a href="javascript:void(0);">${strings.member.concierge_chat}</a>]`)
                    .find('a')
                    .bind('click', () => onChat(item));
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'member/concierge/list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.member.id}</th>
              <th>{strings.member.profile}</th>
              <th>{strings.common.nickname}</th>
              <th>{strings.member.type}</th>
              <th>{strings.common.status}</th>
              <th>{strings.member.age}</th>
              <th>{strings.member.phone}</th>
              <th>{strings.member.location}</th>
              <th>{strings.member.job}</th>
              <th>{strings.member.key}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.member.last_login_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default MemberConcierge;
