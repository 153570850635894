import 'chart.js/auto';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

import Container from '../../../components/control/container';
import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';


const IapChartFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [count, setCount] = useState([]);
  useEffect(() => {
    context.get(
      'dashboard/iap_chart',
      {},
      response => {
        const date_list = [];
        const value_list = [];
        const count_list = [];

        response.forEach(entry => {
          date_list.push(moment(entry.date).format('M/D'));
          value_list.push(entry.total_value);
          count_list.push(entry.total_count);
        });

        setData(date_list);
        setValue(value_list);
        setCount(count_list);
      }
    );
  }, []);

  const [lineChartOptions, setLineChartOptions] = useState({});
  const [lineChartData, setLineChartData] = useState({ labels: [], datasets: [] });
  useEffect(() => {
    setLineChartData({
      labels: data,
      datasets: [{
        id: 'y1',
        label: strings.dashboard.iap_chart_money,
        data: value,
        fill: true,
        backgroundColor: 'rgba(0, 172, 172, 0.5)',
        borderColor: 'rgba(0, 172, 172, 0.6)',
        borderWidth: 2,
      }, {
        id: 'y2',
        data: count,
        label: strings.dashboard.iap_chart_count,
        fill: true,
        backgroundColor: 'rgba(52, 143, 226, 0.5)',
        borderColor: 'rgba(52, 143, 226, 0.6)',
        borderWidth: 2,
      }]
    });

    const max_value = Math.max.apply(Math, value);
    const max_count = Math.max.apply(Math, count);
    setLineChartOptions({
      height: 400,
      animation: false,
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
      plugins: {
        title: {
          display: false,
        }
      },
      scales: {
        y1: {
          type: 'linear',
          display: true,
          position: 'left',
          beginAtZero: true,
          min: 0,
          max: Math.max(max_value * 2, 1000000),
          ticks: {
            stepSize: 10000,
          }
        },
        y2: {
          type: 'linear',
          display: false,
          beginAtZero: true,
          min: 0,
          max: Math.max(max_count * 5, 200),
        }
      }
    });
  }, [data, value, count]);

  return (
    <div>
      <Container title={strings.dashboard.iap_chart_title} rightButton={(
        <Link to="/iap/sta" style={{ color: '#fff' }}>
          {strings.dashboard.just_go} <i className="fa fa-arrow-alt-circle-right"/>
        </Link>
      )}>
        <Line type={'line'} options={lineChartOptions} data={lineChartData}/>
      </Container>
    </div>
  );
}

export default IapChartFragment;
