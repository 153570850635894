import { STATUS } from "../../config/const";
import strings from "../../lang/strings";
import ReactPlayer from "react-player";
import React from "react";

const PendingItem = React.memo(({
                                  className = 'col-md-10 form-inline',
                                  user,
                                  pending,
                                  category,
                                  type = 'input',
                                  onChange,
                                  onAccept,
                                  onReject,
                                  children
                                }) => {
  return (
    <div className={className}>
      {
        children ?
          <div className='d-inline-flex flex-column'>
            <div className="justify-content-start p-b-0">
              {children}
            </div>
            {
              pending?.[category]?.reason && pending?.[category]?.status > STATUS.NORMAL &&
              <small className="f-s-12 text-danger-lighter">{pending?.[category]?.reason}</small>
            }
          </div>
          :
          category == 'video' ?
            <div className='d-flex flex-column'>
              <a href={(pending?.[category]?.content ?? user?.[category]).getFullUrl()}
                 target='_blank'
                 className='btn btn-primary m-b-5 col-md-3'>
                {strings.member.download_video}
              </a>
              <ReactPlayer url={(pending?.[category]?.content ?? user?.[category]).getFullUrl()}
                           controls
                           width='auto'
                           height='300px' />
              {
                pending?.[category]?.reason && pending?.[category]?.status > STATUS.NORMAL &&
                <small className="f-s-12 text-danger-lighter m-t-5">{pending?.[category]?.reason}</small>
              }

            </div>
            :
            <div className=''>
              <div className="justify-content-start p-b-0">
                {
                  type == "input" ?
                    <input className='form-control' id={category} onChange={onChange}
                           value={(pending?.[category]?.content ?? user?.[category]) || ''} />
                    :
                    <textarea className='form-control width-450' id={category} onChange={onChange}
                              rows={10}
                              value={(pending?.[category]?.content ?? user?.[category]) || ''} />
                }
              </div>
              {
                pending?.[category]?.reason && pending?.[category]?.status > STATUS.NORMAL &&
                <small className="f-s-12 text-danger-lighter">{pending?.[category]?.reason}</small>
              }
            </div>
      }
      {
        pending?.[category]?.content &&
        (pending?.[category]?.status == STATUS.NORMAL ?
            <div className='m-l-20'>
              <span className='btn btn-default btn-sm p-t-2 p-b-2'>{strings.common.accepted}</span>
            </div>
            :
            (pending?.[category]?.status == STATUS.REJECT ?
              <div className='m-l-20'>
                <span className='btn btn-default btn-sm p-t-2 p-b-2'>{strings.common.rejected}</span>
              </div>
              :
              <div className='m-l-20'>
                <button type='button' className='btn btn-info btn-sm p-t-2 p-b-2'
                        onClick={() => onAccept(category)}>
                  {strings.common.accept}
                </button>
                <button type='button' className='btn btn-danger btn-sm p-t-2 p-b-2 m-l-5'
                        onClick={() => onReject(category)}>
                  {strings.common.reject}
                </button>
              </div>)
        )
      }
    </div>
  );
});

export default PendingItem;
