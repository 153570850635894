import { useState } from "react";
import strings from "../../../lang/strings";
import PendingItem from "../../../components/control/pending-item";
import RejectPopup from "../popup/reject-popup";

const MemberDetailExtra = ({ user, pending, onChange, onPendingChange, onAccept, onReject, onCancel }) => {

  const [popup, setPopup] = useState({
    isOpen: false,
    title: '',
    text: '',
    category: ''
  });

  const showPopup = (title, text, category) => {
    setPopup({
      isOpen: true,
      title,
      text,
      category
    });
  };

  const hidePopup = () => setPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });

  return (
    <>
      <RejectPopup
        {...popup}
        reasons={strings.reject_reason.signup}
        onSave={reason => {
          onReject(popup.category, 0, reason);
          hidePopup();
        }}
        onClose={hidePopup}/>
      <div className='p-10'>
        <div className="form-inline">
          <label className="col-form-label width-200 justify-content-end font-weight-bold">
            {strings.member.about_me}:
          </label>
          <PendingItem
            className='col-md-8 form-inline'
            type='textarea'
            pending={pending}
            user={user}
            category='about_me'
            onChange={e => {
              if (pending.hasOwnProperty('about_me')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.member.about_me,
              pending?.[category]?.reason ?? '',
              category
            )}/>
        </div>
        <div className="form-inline m-t-10">
          <label className="col-form-label width-200 justify-content-end font-weight-bold">
            {strings.member.appeal_extra}:
          </label>
          <PendingItem
            className='col-md-8 form-inline'
            type='textarea'
            pending={pending}
            user={user}
            category='appeal_extra'
            onChange={e => {
              if (pending.hasOwnProperty('appeal_extra')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.member.appeal_extra,
              pending?.[category]?.reason ?? '',
              category
            )}/>
        </div>
        <div className="form-inline m-t-10">
          <label className="col-form-label width-200 justify-content-end font-weight-bold">
            {strings.member.intro}:
          </label>
          <PendingItem
            className='col-md-8 form-inline'
            type='textarea'
            pending={pending}
            user={user}
            category='intro'
            onChange={e => {
              if (pending.hasOwnProperty('intro')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.member.intro,
              pending?.[category]?.reason ?? '',
              category
            )}/>
        </div>
        <div className="form-inline m-t-10">
          <label className="col-form-label width-200 justify-content-end font-weight-bold">
            {strings.member.instagram_id}:
          </label>
          <PendingItem
            className='col-md-8 form-inline'
            pending={pending}
            user={user}
            category='instagram_id'
            onChange={e => {
              if (pending.hasOwnProperty('instagram_id')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.member.instagram_id,
              pending?.[category]?.reason ?? '',
              category
            )}/>
        </div>
        <div className="form-inline m-t-10">
          <label className="col-form-label width-200 justify-content-end font-weight-bold">
            {strings.member.youtube_id}:
          </label>
          <PendingItem
            className='col-md-8 form-inline'
            pending={pending}
            user={user}
            category='youtube_id'
            onChange={e => {
              if (pending.hasOwnProperty('youtube_id')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.member.youtube_id,
              pending?.[category]?.reason ?? '',
              category
            )}/>
        </div>
        <div className="form-inline m-t-10">
          <label className="col-form-label width-200 justify-content-end font-weight-bold">
            {strings.member.sns_id}:
          </label>
          <PendingItem
            className='col-md-8 form-inline'
            pending={pending}
            user={user}
            category='sns_id'
            onChange={e => {
              if (pending.hasOwnProperty('sns_id')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.member.sns_id,
              pending?.[category]?.reason ?? '',
              category
            )}/>
        </div>
      </div>
    </>
  );
}

export default MemberDetailExtra;
