import React, { useContext, useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import querystring from "query-string";

import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import { USER_STATUS } from "../../config/const";
import { Panel, PanelBody } from "../../components/panel/panel";
import PenaltyPopup, { PenaltyType } from "../lounge/popup/penalty-popup";
import BlameMeetingFragment from "./fragment/blame-meeting";
import BlameMemberFragment from "./fragment/blame-member";
import BlameMemberInChatFragment from "./fragment/blame-member_in_chat";

const MeetingBlame = ({ location, history }) => {

  const context = useContext(PageSettings);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const searchQS = querystring.parse(location.search);
    setTab(searchQS.tab || 0);
  }, [location.search]);

  const toggleTab = tab => {
    const searchQS = { tab };
    history.push('?' + querystring.stringify(searchQS));
  }

  const [penalty, setPenaltyPopup] = useState({
    isOpen: false,
    type: PenaltyType.Meeting,
    user: null
  });
  const hidePenaltyPopup = () => setPenaltyPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });

  const onUser = (user_id, callback) => {
    context.showUserPopup(user_id, () => callback?.());
  }

  const onPenalty = (user_id, user_nickname) => {
    setPenaltyPopup(prev => {
      return {
        ...prev,
        isOpen: true,
        user: { id: user_id, nickname: user_nickname }
      }
    });
  };

  const onBlock = (id, status, callback) => {
    const content = status == USER_STATUS.BLOCK ? strings.modal.unblock_confirm : strings.modal.block_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/block',
        {
          id: id,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          callback?.();
        }
      );
    });
  };

  const tabs = [
    {
      title: strings.meeting.blame_meeting,
      component: () => <BlameMeetingFragment onBlock={onBlock} onUser={onUser} onPenalty={onPenalty}/>
    },
    {
      title: strings.meeting.blame_member_in_chat,
      component: () => <BlameMemberInChatFragment onBlock={onBlock} onUser={onUser} onPenalty={onPenalty}/>
    },
    {
      title: strings.meeting.blame_member,
      component: () => <BlameMemberFragment onBlock={onBlock} onUser={onUser} onPenalty={onPenalty}/>
    },
  ];

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {tabs.map((it, idx) => (
          <NavItem key={idx}>
            <NavLink className={classnames({ active: tab == idx })}
                     onClick={() => toggleTab(idx)}>
              <span className="d-none d-xs-inline">{it.title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        {tabs.map((it, idx) => (
          tab == idx && <div key={idx}>{it.component()}</div>
        ))}
      </PanelBody>
      <PenaltyPopup {...penalty} onClose={() => {
        hidePenaltyPopup();
      }}/>
    </Panel>
  );

}

export default withRouter(MeetingBlame);
