import React, { useContext, useEffect, useRef } from "react";
import $ from 'jquery';

import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";
import { STATUS, USER_STATUS } from "../../../config/const";
import { inject } from "mobx-react";


const MemberStatusFragment = ({ tab, rootStore }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.member;
  const tabRef = useRef(tab);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  useEffect(() => {
    $('#searchColumn').val(searchOptions[0].value);
    $('#searchInput').val('');

    tabRef.current = tab;

    refreshTable(true);
  }, [tab]);

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };

  const onBlock = user => {
    const content = user.status == USER_STATUS.BLOCK ? strings.modal.unblock_confirm : strings.modal.block_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/block',
        {
          id: user.id,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  };

  const onToggleAutoCancel = user => {
    context.post(
      'member/toggle_auto_cancel/' + user.id,
      {},
      () => {
        context.addNotification(strings.modal.alert, strings.msg.applied);
        refreshTable(false);
      }
    );
  };

  const onDelete = user_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'member/' + user_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='searchColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)} />
          <SearchInput
            id='searchInput'
            action={() => refreshTable(true)} />
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'email', orderable: false,
            }, {
              targets: [columnNo++], data: 'profile', orderable: false,
              createdCell: (td, value, item) => {
                $(td).image_lightbox(value, () => {
                  context.showGallery(value.split('#').map(it => it.getFullUrl()));
                }, item.single);
              }
            }, {
              targets: [columnNo++], data: 'nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(
                  item.nickname,
                  item.gender,
                  () => onCell(item.id)
                );
              }
            }, {
              targets: [columnNo++], data: 'birth', orderable: false,
              render: value => value.ageFormat()
            }, {
              targets: [columnNo++], data: 'phone', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(item.phone + '<br/>' + (item.name ?? '-'));
              }
            }, {
              targets: [columnNo++], data: 'location', orderable: false,
            }, {
              targets: [columnNo++], data: 'job', orderable: false,
            }, {
              targets: [columnNo++], data: 'status', orderable: false,
              createdCell: (td, value, item) => {
                $(td).user_status(item);
              }
            }, {
              targets: [columnNo++], data: 'status_reason', orderable: false,
            }, {
              targets: [columnNo++], data: 'status_date', orderable: false,
              render: value => value?.dateFormat() ?? '-'
            }, {
              targets: [columnNo++], data: 'expire_date', orderable: false,
              render: value => value < 45 ? '-' : strings.formatString(strings.member.expired, value)
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html('');
                if (!rootStore.isSuper) {
                  return;
                }

                let btnIndex = 0;
                $(td)
                  .html(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.member.db_delete}</button>`)
                  .find(`button:eq(${btnIndex++})`)
                  .on('click', () => onDelete(item.id));
                if (item.status == USER_STATUS.BLOCK) {
                  $(td)
                    .append(`<button type='button' class='btn btn-outline-dark btn-sm text-nowrap'>${strings.member.unblock}</button>`)
                    .find(`button:eq(${btnIndex++})`)
                    .on('click', () => onBlock(item));
                }
                if ([USER_STATUS.BLOCK, USER_STATUS.LEAVE].includes(item.status)) {
                  $(td)
                    .append(`
                      <button type='button' class='btn btn-outline-info btn-sm text-nowrap'>
                        ${item.auto_delete == STATUS.NORMAL ? strings.member.pause_auto_delete : strings.member.cancel_pause_auto_delete}
                      </button>`)
                    .find(`button:eq(${btnIndex})`)
                    .on('click', () => onToggleAutoCancel(item));
                }
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'member/status_list',
              data: (req) => {
                req.type = tabRef.current;
                req.column = $('#searchColumn').val();
                req.keyword = $('#searchInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.member.id}</th>
            <th>{strings.member.profile}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.member.age}</th>
            <th>{strings.member.phone}</th>
            <th>{strings.member.location}</th>
            <th>{strings.member.job}</th>
            <th>{strings.common.status}</th>
            <th>{strings.lounge.penalty_reason}</th>
            <th>{strings.common.update_date}</th>
            <th>{strings.member.delete_expire}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );
}

export default inject('rootStore')(MemberStatusFragment);
