import React, { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";

import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";

import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import AliasPopup from "../popup/alias-popup";
import { AliasTabs } from "../alias";


const AliasFragment = ({ tab, gender }) => {

  const context = useContext(PageSettings);

  const type = tab === AliasTabs.dessert ? 2 : 1;
  const title = type === 2 ? strings.alias.dessert : strings.alias.adjective;

  useEffect(() => {
    $('#searchInput').val('');
    refreshTable(true);
  }, [tab, gender]);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, showPopup] = useState({
    show: false,
    item: null
  });
  const showItemPopup = id => {
    showPopup({
      show: true,
      item: { id, type, gender }
    });
  };

  const deleteItem = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `setting/alias/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  return (
    <React.Fragment>
      <input type='hidden' value={type} id='pageType'/>
      <input type='hidden' value={gender} id='genderType'/>
      <div className='row'>
        <div className='col-md-10 col-xl-6 col-xxl-4'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              value={'name'}
              data={[{ value: 'name', name: title }]}/>
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)}/>
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
            <button type='button' className='btn btn-danger btn-sm ml-md-2'
                    onClick={() => showItemPopup(0)}>{strings.common.add}</button>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-10 col-xl-6 col-xxl-4 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'name', orderable: false,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html('')
                    .append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.common.edit}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => showItemPopup(item.id))
                    .end()
                    .append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button>`)
                    .find('button:eq(1)')
                    .on('click', () => deleteItem(item.id));
                }
              }],
              order: [[0, 'ASC']],
              ajax: {
                url: 'setting/alias',
                data: (req) => {
                  req.type = $('#pageType').val();  // 1-adjective, 2-dessert
                  req.gender = $('#genderType').val();
                  req.column = 'name';
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th style={{ width: '50%' }}>{title}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody/>
          </DataTable>
        </div>
      </div>
      <AliasPopup
        isOpen={popup.show}
        info={popup.item}
        onClose={() => showPopup({ show: false, item: null })}
        onSave={() => {
          if (popup.item?.id) {
            showPopup({ show: false, item: null });
          }
          refreshTable(false);
        }}
      />
    </React.Fragment>
  );

}

export default AliasFragment;
