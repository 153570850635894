import { useContext, useEffect, useState } from "react";
import strings from "../../../lang/strings";
import PendingItem from "../../../components/control/pending-item";
import { PageSettings } from "../../../config/page-settings";
import { GENDER, STATUS } from "../../../config/const";
import RejectPopup from "../popup/reject-popup";
import ReactPlayer from "react-player";

const MemberDetailProfile = ({ user, pending, profiles, onAccept, onReject, onCancel, onProfile }) => {

  const context = useContext(PageSettings);

  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    let list = [];
    if (pending?.profile) {
      const images = pending?.profile.content.split('#').map(it => it.getFullUrl());
      const cancel_images_org = pending?.profile.image_cancel_org ? pending?.profile.image_cancel_org.split(',').map(it => Number(it)) : [];
      const cancel_images = pending?.profile.image_cancel ? pending?.profile.image_cancel.split(',').map(it => Number(it)) : [];
      const reasons = pending?.profile.reason.split('#');

      images.forEach((image, index) => {
        list.push({
          url: image,
          isEdit: cancel_images_org.includes(index + 1), // 수정된 이미지?
          status: cancel_images.includes(index + 1) ? (pending?.profile.reject_images.includes(index + 1) ? STATUS.REJECT : STATUS.PENDING) : STATUS.NORMAL, // 이미지상태(0-승인대기, 1-승인됨, 2-반려됨)
          reason: cancel_images.includes(index + 1) ? reasons[cancel_images.indexOf(index + 1)] : '', //반려사유
        });
      });
    }
    setPhotos(list);
  }, [pending]);

  const [popup, setPopup] = useState({
    isOpen: false,
    title: '',
    text: '',
    category: '',
    index: 0
  });

  const showPopup = (title, text, category, index = 0) => {
    setPopup({
      isOpen: true,
      title,
      text,
      category,
      index
    });
  };

  const hidePopup = () => setPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });

  return (
    <>
      <RejectPopup
        {...popup}
        reasons={strings.reject_reason.signup}
        onSave={reason => {
          onReject(popup.category, popup.index, reason);
          hidePopup();
        }}
        onClose={hidePopup}/>
      <div className='p-t-10'>
        <div className="form-inline align-items-start">
          <label className="col-form-label width-150 justify-content-end font-weight-bold">
            {strings.member.video}:
          </label>
          {
            pending.video && pending.video.content ?
              <PendingItem
                className='col-md-8 form-inline'
                pending={pending}
                user={user}
                category='video'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.video,
                  pending?.[category]?.reason ?? '',
                  category
                )}/>
              :
              user.video ?
                <div className='col-md-8 form-inline'>
                  <a href={user.video.getFullUrl()} target='_blank' className='btn btn-primary m-b-5 col-md-3'>
                    {strings.member.download_video}
                  </a>
                  <ReactPlayer url={user.video.getFullUrl()}
                               controls
                               width='auto'
                               height='300px'/>
                </div>
                :
                <label className="m-l-10 col-form-label justify-content-start">{strings.member.no_info}</label>
          }
        </div>
        <div className="form-inline align-items-start">
          <label className="col-form-label width-150 justify-content-end font-weight-bold">
            {strings.member.profile}:
          </label>
          <div className='col-md-9 form-inline'>
            {
              photos.length > 0 ?
                <div className='m-t-10'>
                  {photos.map((image, index) => (
                    <div key={`image-${index}`} className='d-flex flex-row align-items-center m-b-10'>
                      <img
                        src={image.url.getThumbnailUrl()}
                        style={{ width: 'auto', height: 80, cursor: 'pointer' }}
                        onClick={() => context.showGallery(photos.map(it => it.url), index)}/>
                      {
                        image.isEdit &&
                        <div className='d-inline-flex justify-content-start flex-column m-l-10'>
                          {
                            image.reason && image.status != STATUS.NORMAL &&
                            <small className="f-s-12 text-danger-lighter m-b-5">{image.reason}</small>
                          }
                          <div className='m-l-5'>
                            {
                              image.status == STATUS.PENDING &&
                              (pending?.profile?.status == STATUS.PENDING || pending?.profile?.status == STATUS.REAPPLY) ?
                                <>
                                  <button type='button' className='btn btn-info btn-sm p-t-2 p-b-2'
                                          onClick={() => onAccept('profile', index + 1)}>
                                    {strings.common.accept}
                                  </button>
                                  <button type='button' className='btn btn-danger btn-sm p-t-2 p-b-2 m-l-5'
                                          onClick={() => showPopup(strings.member.profile, image.reason, 'profile', index + 1)}>
                                    {strings.common.reject}
                                  </button>
                                </>
                                :
                                image.status == STATUS.NORMAL ?
                                  <button type='button' className='btn btn-default btn-sm p-t-2 p-b-2'>
                                    {strings.common.accepted}
                                  </button>
                                  :
                                  <button type='button' className='btn btn-default btn-sm p-t-2 p-b-2'>
                                    {strings.common.rejected}
                                  </button>
                            }
                          </div>
                        </div>
                      }
                    </div>
                  ))}
                </div>
                :
                user?.profile ?
                  user?.profile.split('#').map((url, index) => (
                    <img
                      key={index}
                      src={url.getFullUrl().getThumbnailUrl()}
                      style={{ width: 'auto', height: 80, cursor: 'pointer', marginRight: 10, marginTop: 10 }}
                      onClick={() => context.showGallery(user?.profile.split('#').map(it => it.getFullUrl()), index)}/>
                  ))
                  :
                  <label className="col-form-label justify-content-start">{strings.member.no_info}</label>
            }
          </div>
        </div>
        <div className="form-inline align-items-start">
          <label className="col-form-label width-150 justify-content-end font-weight-bold text-right">
            {strings.member.admin_profile}:
          </label>
          <div className='col-md-8 form-inline'>
            {
              (user?.gender == GENDER.MALE ? profiles.male : profiles.female).map(file => (
                <img
                  key={file}
                  src={file.getFullUrl()}
                  style={{
                    width: 'auto',
                    height: 80,
                    cursor: 'pointer',
                    boxSizing: 'content-box',
                    marginRight: 10,
                    marginTop: 10,
                    borderWidth: (user?.admin_profile && file.includes(user?.admin_profile)) ? 4 : 0,
                    borderColor: 'yellow',
                    borderStyle: 'solid'
                  }}
                  onClick={() => onProfile(file)}/>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default MemberDetailProfile;
