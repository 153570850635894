import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";

const DropdownNotification = ({ history, reset }) => {

  const context = useContext(PageSettings);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newsCount, setNewsCount] = useState(0);  // 전체 알람수
  const [matchReport, setMatchReport] = useState(0);  // 1:1 매칭 신고수
  const [meetingReport, setMeetingReport] = useState(0);  // 2:2 미팅글신고수
  const [meetingChatUserReport, setMeetingChatUserReport] = useState(0);  // 2:2 채팅방내신고수
  const [meetingUserReport, setMeetingUserReport] = useState(0);  // 2:2 유저신고수
  const [loungeReport, setLoungeReport] = useState(0);  // 스퀘어글신고수
  const [loungeCmtReport, setLoungeCmtReport] = useState(0);  // 스퀘어 댓글신고수
  const [loungeUserReport, setLoungeUserReport] = useState(0);  // 스퀘어 유저신고수
  const [conciergeCount, setConciergeCount] = useState(0);  // 새 큐레이터채팅수
  const [payCount, setPayCount] = useState(0);  // 새 결제내역수

  useEffect(() => {
    getNewsCount();

    // 1분간격으로 뉴스정보를 블러 온다.
    const timer = setInterval(() => getNewsCount(), 1000 * 60);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => getNewsCount(), [reset]);

  const toggle = () => setDropdownOpen(prev => !prev);

  const getNewsCount = () => {
    context.get(
      'manager/news_count',
      {},
      response => {
        setNewsCount(response.newsCount || 0);
        setMatchReport(response.matchReport || 0);
        setMeetingReport(response.meetingReport || 0);
        setMeetingChatUserReport(response.meetingChatUserReport || 0);
        setMeetingUserReport(response.meetingUserReport || 0);
        setLoungeReport(response.loungeReport || 0);
        setLoungeCmtReport(response.loungeCmtReport || 0);
        setLoungeUserReport(response.loungeUserReport || 0);
        setConciergeCount(response.conciergeCount || 0);
        setPayCount(response.payCount || 0);
      },
      false
    )
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown" tag="li">
      <DropdownToggle className="f-s-14" tag="a">
        <i className="fa fa-bell"/>
        {newsCount > 0 && <span className="label">{newsCount}</span>}
      </DropdownToggle>
      <DropdownMenu className="media-list dropdown-menu-right" tag="ul">
        {/*<DropdownItem className="dropdown-header" tag="li" header>NOTIFICATIONS (5)</DropdownItem>*/}
        {
          matchReport > 0 &&
          <DropdownItem className="media" onClick={() => history.push('/match/blame')}>
            <div className="media-left">
              <i className="fa fa-user media-object bg-silver-darker"/>
              {/*<i className="fab fa-twitch text-warning media-object-icon f-s-14"/>*/}
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                {strings.formatString(strings.noti.match_report, matchReport)}
              </h6>
              {/*<div className="text-muted f-s-11">3 minutes ago</div>*/}
            </div>
          </DropdownItem>
        }
        {
          meetingReport > 0 &&
          <DropdownItem className="media" onClick={() => history.push('/meeting/blame')}>
            <div className="media-left">
              <i className="fa fa-users media-object bg-silver-darker"/>
              {/*<i className="fab fa-twitch text-warning media-object-icon f-s-14"/>*/}
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                {strings.formatString(strings.noti.meeting_report, meetingReport)}
              </h6>
              {/*<div className="text-muted f-s-11">1 hour ago</div>*/}
            </div>
          </DropdownItem>
        }
        {
          meetingChatUserReport > 0 &&
          <DropdownItem className="media" onClick={() => history.push('/meeting/blame?tab=1')}>
            <div className="media-left">
              <i className="fa fa-users media-object bg-silver-darker"/>
              <i className="fab fa-twitch text-warning media-object-icon f-s-14"/>
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                {strings.formatString(strings.noti.meeting_chat_user_report, meetingChatUserReport)}
              </h6>
              {/*<div className="text-muted f-s-11">1 hour ago</div>*/}
            </div>
          </DropdownItem>
        }
        {
          meetingUserReport > 0 &&
          <DropdownItem className="media" onClick={() => history.push('/meeting/blame?tab=2')}>
            <div className="media-left">
              <i className="fa fa-users media-object bg-silver-darker"/>
              <i className="fab fa-twitch text-warning media-object-icon f-s-14"/>
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                {strings.formatString(strings.noti.meeting_user_report, meetingUserReport)}
              </h6>
              {/*<div className="text-muted f-s-11">1 hour ago</div>*/}
            </div>
          </DropdownItem>
        }
        {
          loungeReport > 0 &&
          <DropdownItem className="media" onClick={() => history.push('/lounge/blame')}>
            <div className="media-left">
              <i className="fa fa-clipboard media-object bg-silver-darker"/>
              {/*<i className="fab fa-google text-warning media-object-icon f-s-14"/>*/}
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                {strings.formatString(strings.noti.lounge_report, loungeReport)}
              </h6>
              {/*<div className="text-muted f-s-11">2 hour ago</div>*/}
            </div>
          </DropdownItem>
        }
        {
          loungeCmtReport > 0 &&
          <DropdownItem className="media" onClick={() => history.push('/lounge/blame?tab=1')}>
            <div className="media-left">
              <i className="fa fa-clipboard media-object bg-silver-darker"/>
              <i className="fab fa-twitch text-warning media-object-icon f-s-14"/>
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                {strings.formatString(strings.noti.lounge_cmt_report, loungeCmtReport)}
              </h6>
              {/*<div className="text-muted f-s-11">2 hour ago</div>*/}
            </div>
          </DropdownItem>
        }
        {
          loungeUserReport > 0 &&
          <DropdownItem className="media" onClick={() => history.push('/lounge/blame?tab=2')}>
            <div className="media-left">
              <i className="fa fa-clipboard media-object bg-silver-darker"/>
              <i className="fab fa-twitch text-warning media-object-icon f-s-14"/>
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                {strings.formatString(strings.noti.lounge_user_report, loungeUserReport)}
              </h6>
              {/*<div className="text-muted f-s-11">2 hour ago</div>*/}
            </div>
          </DropdownItem>
        }
        {
          conciergeCount > 0 &&
          <DropdownItem className="media" onClick={() => history.push('/concierge')}>
            <div className="media-left">
              <i className="fa fa-comments media-object bg-silver-darker"/>
              {/*<i className="fab fa-google text-warning media-object-icon f-s-14"/>*/}
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                {strings.formatString(strings.noti.concierge_count, conciergeCount)}
              </h6>
              {/*<div className="text-muted f-s-11">2 hour ago</div>*/}
            </div>
          </DropdownItem>
        }
        {
          payCount > 0 &&
          <DropdownItem className="media" onClick={() => history.push('/iap/log?tab=1')}>
            <div className="media-left">
              <i className="fa fa-dollar-sign media-object bg-silver-darker"/>
              {/*<i className="fab fa-google text-warning media-object-icon f-s-14"/>*/}
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                {strings.formatString(strings.noti.pay_count, payCount)}
              </h6>
              {/*<div className="text-muted f-s-11">2 hour ago</div>*/}
            </div>
          </DropdownItem>
        }
        {/*<DropdownItem className="dropdown-footer text-center">
            View more
          </DropdownItem>*/}
      </DropdownMenu>
    </Dropdown>
  );
}

export default withRouter(DropdownNotification);
