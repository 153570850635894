import strings from "../../../lang/strings";
import { GENDER } from "../../../config/const";
import PendingItem from "../../../components/control/pending-item";
import { useContext, useState } from "react";
import { PageSettings } from "../../../config/page-settings";
import RejectPopup from "../popup/reject-popup";

const MemberDetailMain = ({ user, pending, onChange, onPendingChange, onAccept, onReject, onCancel }) => {

  const context = useContext(PageSettings);
  const [popup, setPopup] = useState({
    isOpen: false,
    title: '',
    text: '',
    category: ''
  });

  const showPopup = (title, text, category) => {
    setPopup({
      isOpen: true,
      title,
      text,
      category
    });
  };

  const hidePopup = () => setPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });

  let locations = [];
  context.getSettingList('resident').forEach(it => {
    if (it.children.length < 1) {
      locations.push(it.label);
    } else {
      it.children.forEach(child => {
        locations.push(it.label + ' ' + child.label);
      });
    }
  });

  return (
    <>
      <RejectPopup
        {...popup}
        reasons={strings.reject_reason.signup}
        onSave={reason => {
          onReject(popup.category, 0, reason);
          hidePopup();
        }}
        onClose={hidePopup} />
      <div className='p-t-10'>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.name}:
          </label>
          <div className="col-md-3">
            <label className="col-form-label justify-content-start">
              {user?.name} ({user?.gender?.genderStr()})
            </label>
          </div>
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.email}:
          </label>
          <div className="col-md-3">
            <input className='form-control width-full' id='email' value={user?.email || ''} onChange={onChange} />
          </div>
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.phone}:
          </label>
          <div className="col-md-3">
            <input className='form-control width-full' id='phone' value={user?.phone || ''} onChange={onChange} />
          </div>
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.birth}:
          </label>
          <div className="col-md-3 form-inline">
            <input className='form-control' id='birth' value={user?.birth || ''} onChange={onChange} />
            {/*<label className="col-form-label justify-content-start">
              &nbsp;{strings.common.year}
            </label>*/}
          </div>
        </div>
        <div className="form-inline align-items-start">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.ritzy_comment}:
          </label>
          <div className="col-md-4">
            <textarea
              id='ritzy_comment'
              className='form-control width-full'
              rows={10}
              value={user?.ritzy_comment || ''}
              onChange={onChange} />
          </div>
        </div>
        <div className="form-inline align-items-start m-t-5">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.memo}:
          </label>
          <div className="col-md-4">
            <textarea
              id='memo'
              className='form-control width-full height-80'
              value={user?.memo || ''}
              onChange={onChange} />
          </div>
        </div>
        <div className="form-inline mt-1">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.common.nickname}:
          </label>
          <PendingItem
            pending={pending}
            user={user}
            category='nickname'
            onChange={e => {
              if (pending.hasOwnProperty('nickname')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.common.nickname,
              pending?.[category]?.reason ?? '',
              category
            )} />
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.job}:
          </label>
          <PendingItem
            pending={pending}
            user={user}
            category='job'
            onChange={e => {
              if (pending.hasOwnProperty('job')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.member.job,
              pending?.[category]?.reason ?? '',
              category
            )} />
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.location}:
          </label>
          {
            pending.hasOwnProperty('location') ?
              <PendingItem
                pending={pending}
                user={user}
                category='location'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.location,
                  pending?.location?.reason ?? '',
                  category
                )}>
                <select className='form-control width-full' id='location' value={pending?.location?.content || ''}
                        onChange={onPendingChange}>
                  {locations.map(it => <option key={it} value={it}>{it}</option>)}
                </select>
              </PendingItem>
              :
              <div className="col-md-3">
                <select className='form-control width-full' id='location' value={user?.location || ''}
                        onChange={onChange}>
                  {locations.map(it => <option key={it} value={it}>{it}</option>)}
                </select>
              </div>
          }

        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.school}:
          </label>
          {
            pending.hasOwnProperty('school') ?
              <PendingItem
                pending={pending}
                user={user}
                category='school'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.school,
                  pending?.school?.reason ?? '',
                  category
                )}>
                <select className='form-control width-full' id='school' value={pending?.school?.content ?? 0}
                        onChange={onPendingChange}>
                  {context.getSettingList('education').map(it =>
                    <option key={`school-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </PendingItem>
              :
              <div className="col-md-3">
                <select className='form-control width-full' id='school' value={user?.school ?? 0} onChange={onChange}>
                  {context.getSettingList('education').map(it =>
                    <option key={`school-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </div>
          }
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.family}:
          </label>
          <PendingItem
            pending={pending}
            user={user}
            category='family'
            onChange={e => {
              if (pending.hasOwnProperty('family')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.member.family,
              pending?.[category]?.reason ?? '',
              category
            )} />
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.single}:
          </label>
          {
            pending.hasOwnProperty('single') ?
              <PendingItem
                pending={pending}
                user={user}
                category='single'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.single,
                  pending?.single?.reason ?? '',
                  category
                )}>
                <div className="form-inline">
                  {Object.keys(strings.member.single_list).filter(it => it != '').map(key => (
                    <label key={key} className='m-r-10'
                           onClick={() => onPendingChange({ target: { id: 'single', value: key } })}>
                      <input type='radio' name='single' value={key} checked={pending?.single?.content == key}
                             readOnly />
                      &nbsp;{strings.member.single_list[key]}
                    </label>
                  ))}
                </div>
              </PendingItem>
              :
              <div className="col-md-3 form-inline">
                {Object.keys(strings.member.single_list).filter(it => it != '').map(key => (
                  <label key={key} className='m-r-10'
                         onClick={() => onChange({ target: { id: 'single', value: key } })}>
                    <input type='radio' name='single' value={key} checked={user?.single == key} readOnly />
                    &nbsp;{strings.member.single_list[key]}
                  </label>
                ))}
              </div>
          }
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.drink}:
          </label>
          {
            pending.hasOwnProperty('drink') ?
              <PendingItem
                pending={pending}
                user={user}
                category='drink'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.drink,
                  pending?.drink?.reason ?? '',
                  category
                )}>
                <select className='form-control width-full' id='drink' value={pending?.drink?.content ?? 0}
                        onChange={onPendingChange}>
                  {context.getSettingList('alcohol').map(it =>
                    <option key={`drink-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </PendingItem>
              :
              <div className="col-md-3">
                <select className='form-control width-full' id='drink' value={user?.drink ?? 0} onChange={onChange}>
                  {context.getSettingList('alcohol').map(it =>
                    <option key={`drink-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </div>
          }
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.religion}:
          </label>
          {
            pending.hasOwnProperty('religion') ?
              <PendingItem
                pending={pending}
                user={user}
                category='religion'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.religion,
                  pending?.religion?.reason ?? '',
                  category
                )}>
                <select className='form-control width-full' id='religion' value={pending?.religion?.content ?? 0}
                        onChange={onPendingChange}>
                  {context.getSettingList('religion').map(it =>
                    <option key={`religion-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </PendingItem>
              :
              <div className="col-md-3">
                <select className='form-control width-full' id='religion' value={user?.religion ?? 0}
                        onChange={onChange}>
                  {context.getSettingList('religion').map(it =>
                    <option key={`religion-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </div>
          }
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.smoke}:
          </label>
          {
            pending.hasOwnProperty('smoke') ?
              <PendingItem
                pending={pending}
                user={user}
                category='smoke'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.smoke,
                  pending?.smoke?.reason ?? '',
                  category
                )}>
                <div className="form-inline">
                  {Object.keys(strings.member.smoke_list).map(key => (
                    <label key={key} className='m-r-10'
                           onClick={() => onPendingChange({ target: { id: 'smoke', value: key } })}>
                      <input type='radio' name='smoke' value={key} checked={pending?.smoke?.content == key} readOnly />
                      &nbsp;{strings.member.smoke_list[key]}
                    </label>
                  ))}
                </div>
              </PendingItem>
              :
              <div className="col-md-10 form-inline">
                {Object.keys(strings.member.smoke_list).map(key => (
                  <label key={key} className='m-r-10' onClick={() => onChange({ target: { id: 'smoke', value: key } })}>
                    <input type='radio' name='smoke' value={key} checked={user?.smoke == key} readOnly />
                    &nbsp;{strings.member.smoke_list[key]}
                  </label>
                ))}
              </div>
          }
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.marriage_plan}:
          </label>
          {
            pending.hasOwnProperty('marriage_plan') ?
              <PendingItem
                pending={pending}
                user={user}
                category='marriage_plan'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.marriage_plan,
                  pending?.marriage_plan?.reason ?? '',
                  category
                )}>
                <select className='form-control width-full' id='marriage_plan'
                        value={pending?.marriage_plan?.content ?? 0}
                        onChange={onPendingChange}>
                  {context.getSettingList('marriage_plan').map(it =>
                    <option key={`marriage-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </PendingItem>
              :
              <div className="col-md-3">
                <select className='form-control width-full' id='marriage_plan' value={user?.marriage_plan ?? 0}
                        onChange={onChange}>
                  {context.getSettingList('marriage_plan').map(it =>
                    <option key={`marriage-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </div>
          }
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.height}:
          </label>
          <PendingItem
            pending={pending}
            user={user}
            category='height'
            onChange={e => {
              if (pending.hasOwnProperty('height')) {
                onPendingChange(e);
              } else {
                onChange(e);
              }
            }}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={category => showPopup(
              strings.member.height,
              pending?.[category]?.reason ?? '',
              category
            )} />
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.body_type}:
          </label>
          {
            pending.hasOwnProperty('body_type') ?
              <PendingItem
                pending={pending}
                user={user}
                category='body_type'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.body_type,
                  pending?.body_type?.reason ?? '',
                  category
                )}>
                <select className='form-control width-full' id='body_type'
                        value={pending?.body_type?.content ?? 0}
                        onChange={onPendingChange}>
                  {context.getSettingList('body_type', user?.gender == GENDER.MALE ? 'male' : 'female').map(it =>
                    <option key={`body-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </PendingItem>
              :
              <div className="col-md-3">
                <select className='form-control width-full' id='body_type' value={user?.body_type ?? 0}
                        onChange={onChange}>
                  {context.getSettingList('body_type', user?.gender == GENDER.MALE ? 'male' : 'female').map(it =>
                    <option key={`body-${it.code}`} value={it.code}>{it.name}</option>
                  )}
                </select>
              </div>
          }
        </div>
        <div className="form-inline">
          <label className="col-form-label width-100 justify-content-end font-weight-bold">
            {strings.member.appeal_point}:
          </label>
          {
            pending.hasOwnProperty('appeal_point') ?
              <PendingItem
                pending={pending}
                user={user}
                category='appeal_point'
                onCancel={onCancel}
                onAccept={onAccept}
                onReject={category => showPopup(
                  strings.member.appeal_point,
                  pending?.appeal_point?.reason ?? '',
                  category
                )}>
                <div className="form-inline">
                  {context.getSettingList('appeal_point').map((it, idx) =>
                    <div key={`appeal-${idx}`} className='form-inline m-r-10'>
                      <input
                        type='checkbox' value={it.code} readOnly
                        id={`appeal-${idx}`}
                        checked={pending?.appeal_point?.content?.split(',').includes(`${it.code}`)}
                        onChange={() => {
                          const appeal_point = pending?.appeal_point?.content?.split(',');
                          if (appeal_point.includes(`${it.code}`)) {
                            onPendingChange({
                              target: {
                                id: 'appeal_point',
                                value: appeal_point.filter(item => item != `${it.code}`).join(',')
                              }
                            });
                          } else {
                            onPendingChange({
                              target: {
                                id: 'appeal_point',
                                value: appeal_point.concat(`${it.code}`).join(',')
                              }
                            });
                          }
                        }} />
                      <label htmlFor={`appeal-${idx}`}>&nbsp;{it.name}</label>
                    </div>
                  )}
                </div>
              </PendingItem>
              :
              <div className="col-md-8 form-inline">
                {context.getSettingList('appeal_point').map((it, idx) =>
                  <div key={`appeal-${idx}`} className='form-inline m-r-10'>
                    <input
                      type='checkbox' value={it.code} readOnly
                      id={`appeal-${idx}`}
                      checked={user?.appeal_point?.split(',').includes(`${it.code}`)}
                      onChange={() => {
                        const appeal_point = user?.appeal_point?.split(',');
                        if (appeal_point.includes(`${it.code}`)) {
                          onChange({
                            target: {
                              id: 'appeal_point',
                              value: appeal_point.filter(item => item != `${it.code}`).join(',')
                            }
                          });
                        } else {
                          onChange({
                            target: {
                              id: 'appeal_point',
                              value: appeal_point.concat(`${it.code}`).join(',')
                            }
                          });
                        }
                      }} />
                    <label htmlFor={`appeal-${idx}`}>&nbsp;{it.name}</label>
                  </div>
                )}
              </div>
          }
        </div>
      </div>
    </>
  );

}

export default MemberDetailMain;
