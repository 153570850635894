import { useContext, useEffect, useState } from "react";
import strings from "../../../lang/strings";
import { STATUS } from "../../../config/const";
import { PageSettings } from "../../../config/page-settings";
import RejectPopup from "../popup/reject-popup";

const MemberDetailAuth = ({ user, auth, onAccept, onReject, onDelete }) => {

  const context = useContext(PageSettings);

  const [data, setData] = useState([]);
  useEffect(() => {
    let list = [];
    for (const item of auth) {
      const images = item.image?.split('#').map(it => it.getFullUrl());
      const cancel_images_org = item.image_cancel_org?.split(',').map(it => Number(it)) ?? [];
      const cancel_images = item.image_cancel?.split(',').map(it => Number(it)) ?? [];
      const reasons = item.reason?.split('#') ?? [];

      list.push({
        id: item.id,
        type: item.type,                          // 인증타입(1-전문직, 2-직장, ...)
        status: item.status,                      // 인증상태(0-인증대기, 1-인증됨, 2-반려됨, 3-재심요청)
        images: images.map((it, idx) => {
          return {
            url: it, // image url
            isEdit: cancel_images_org.includes(idx + 1), // 수정된 이미지?
            status: cancel_images.includes(idx + 1) ? (item.reject_images.includes(idx + 1) ? STATUS.REJECT : STATUS.PENDING) : STATUS.NORMAL, // 이미지상태(0-승인대기, 1-승인됨, 2-반려됨)
            reason: cancel_images.includes(idx + 1) ? reasons[cancel_images.indexOf(idx + 1)] : '', //반려사유
          }
        })
      });
    }
    setData(list);
  }, [auth]);

  const [popup, setPopup] = useState({
    isOpen: false,
    title: '',
    text: '',
    type: '',
    index: 0,
    cancel: ''
  });

  const showPopup = (type, index, cancel) => {
    setPopup({
      isOpen: true,
      title: context.getSettingName(type, 'auth'),
      text: '',
      type,
      index,
      cancel
    });
  };

  const hidePopup = () => setPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });

  return (
    <>
      <RejectPopup
        {...popup}
        reasons={strings.reject_reason.signup}
        onSave={reason => {
          onReject(popup.type, popup.index, popup.cancel, reason);
          hidePopup();
        }}
        onClose={hidePopup} />
      <div className='p-10'>
        {data.map((it, idx) => (
          <div key={idx} className="form-inline align-items-start m-b-20">
            <div className='d-flex flex-column align-items-end'>
              <label className="col-form-label width-100 justify-content-end font-weight-bold">
                {context.getSettingName(it.type, 'auth')}:
              </label>
              <button type='button' className='btn btn-danger justify-content-end btn-sm p-t-2 p-b-2'
                      onClick={() => onDelete?.(it.id)}>
                {strings.common.delete}
              </button>
            </div>
            <div className='col-md-10'>
              {it.images.map((image, index) => (
                <div key={`image-${index}`} className='d-flex flex-row align-items-center m-b-10'>
                  <img
                    src={image.url.getThumbnailUrl()}
                    style={{ width: 'auto', height: 80, cursor: 'pointer' }}
                    onClick={() => context.showGallery(it.images.map(it => it.url), index)} />
                  {
                    image.isEdit &&
                    <div className='d-inline-flex justify-content-start flex-column m-l-10'>
                      {
                        image.reason && image.status != STATUS.NORMAL &&
                        <small className="f-s-12 text-danger-lighter m-b-5">{image.reason}</small>
                      }
                      <div className='m-l-5'>
                        {
                          image.status == STATUS.PENDING &&
                          (it.status == STATUS.PENDING || it.status == STATUS.REAPPLY) ?
                            <>
                              <button type='button' className='btn btn-info btn-sm p-t-2 p-b-2'
                                      onClick={() => onAccept(it.type, index + 1, STATUS.NO)}>
                                {strings.common.accept}
                              </button>
                              <button type='button' className='btn btn-danger btn-sm p-t-2 p-b-2 m-l-5'
                                      onClick={() => showPopup(it.type, index + 1, STATUS.NO)}>
                                {strings.common.reject}
                              </button>
                            </>
                            :
                            image.status == STATUS.NORMAL ?
                              <button type='button' className='btn btn-default btn-sm p-t-2 p-b-2'>
                                {strings.common.accepted}
                              </button>
                              :
                              <button type='button' className='btn btn-default btn-sm p-t-2 p-b-2'>
                                {strings.common.rejected}
                              </button>
                        }
                      </div>
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default MemberDetailAuth;
