import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const SignupStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/signup',
      {},
      response => setData(response)
    );
  }, []);

  return (
    <div>
      <div className="widget widget-stats bg-blue height-100">
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.formatString(strings.dashboard.signup_count, strings.dashboard.today)}</div>
            <div className="ml-auto">
              {data ? strings.formatString(
                strings.dashboard.signup_data,
                data.today_approved.currencyFormat(),
                data.today.currencyFormat(),
                data.today > 0 ? (data.today_approved / data.today * 100).toFixed(0) : 0
              ) : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.formatString(strings.dashboard.signup_count, strings.dashboard.this_week)}</div>
            <div className="ml-auto">
              {data ? strings.formatString(
                strings.dashboard.signup_data,
                data.this_week_approved.currencyFormat(),
                data.this_week.currencyFormat(),
                data.this_week > 0 ? (data.this_week_approved / data.this_week * 100).toFixed(0) : 0
              ) : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.formatString(strings.dashboard.signup_count, strings.dashboard.this_month)}</div>
            <div className="ml-auto">
              {data ? strings.formatString(
                strings.dashboard.signup_data,
                data.this_month_approved.currencyFormat(),
                data.this_month.currencyFormat(),
                data.this_month > 0 ? (data.this_month_approved / data.this_month * 100).toFixed(0) : 0
              ) : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.formatString(strings.dashboard.signup_count, strings.common.total)}</div>
            <div className="ml-auto">
              {data ? strings.formatString(
                strings.dashboard.signup_data,
                data.total_approved.currencyFormat(),
                data.total.currencyFormat(),
                data.total > 0 ? (data.total_approved / data.total * 100).toFixed(0) : 0
              ) : `-`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupStaFragment;
