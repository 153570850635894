import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import ImageSelect from "../../../components/control/image-select";
import SearchSelect from '../../../components/control/search-select';

const SignupMsgPopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);
  const [file, setFile] = useState(null);

  const getInfo = () => {
    if (id < 1) {
      return;
    }

    context.get(
      `setting/signup-msg/${id}`,
      {},
      res => {
        setInfo(res);
      }
    );
  }

  const onImageChange = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setInfo({
        ...info,
        image: reader.result
      });
      setFile(file);
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = () => {
    if (file != null) {
      setFile(null);
    }

    setInfo({
      ...info,
      image: ''
    });
  };

  const uploadImage = () => {
    if (file == null) {
      return '';
    }

    return new Promise(resolve => {
      let formData = new FormData();
      formData.append('file', file, file.name);

      context.post(
        'upload/image/signup-msg',
        formData,
        response => {
          resolve(response.image);
        }
      );
    });
  }

  const onSave = e => {
    e.preventDefault();

    if (!info?.location || !info?.gender) {
      context.showAlert(strings.signup_msg.input_require);
      return;
    }

    if (!info?.content && !info?.image && file == null) {
      context.showAlert(strings.signup_msg.input_require);
      return;
    }

    context.showConfirm(strings.modal.save_confirm, async res => {
      if (res !== true) {
        return;
      }

      let image = '';
      if (file != null) {
        image = await uploadImage();
      }

      context.post(
        'setting/signup-msg',
        {
          id: info?.id || 0,
          gender: info?.gender,
          location: info?.location,
          content: info?.content || '',
          image: image || info?.image,
          free_key: info?.free_key || 0,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          onClose(true);
        }
      );
    });
  }

  let areaList = [''];
  for (let area of context.getSettingList('resident')) {
    if (!areaList.includes(area.group_name)) {
      areaList.push(area.group_name);
    }
  }

  return (
    <Modal size={'lg'} isOpen={isOpen} toggle={onClose} onOpened={getInfo}>
      <form onSubmit={onSave}>
        <ModalHeader className="popup-nickname" toggle={onClose}>
          {strings.signup_msg.detail}
        </ModalHeader>
        <ModalBody>
          <div className='row'>
            <label className='col-xs-2 col-form-label'>{strings.common.gender} *</label>
            <div className="col-xs-9">
              <select className='form-control' id='gender' value={info?.gender || ''}
                onChange={(e) => {
                  setInfo({ ...info, gender: e.target.value })
                }}>
                {[{ value: '', name: '' }, ...strings.search_option.gender].map(it => <option key={it.value} value={it.value}>{it.name}</option>)}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xs-2 col-form-label'>{strings.common.location} *</label>
            <div className="col-xs-9">

              <select className='form-control width-full' id='location' value={info?.location || areaList[0]}
                onChange={(e) => {
                  setInfo({ ...info, location: e.target.value })
                }}>
                {areaList.map(it => <option key={it} value={it}>{it}</option>)}
              </select>
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.content}</label>
            <div className='col-xs-9'>
              <textarea
                className='form-control'
                rows={20}
                value={info?.content || ''}
                onChange={e => setInfo({ ...info, content: e.target.value })} />
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.image}</label>
            <div className='col-xs-9'>
              <ImageSelect
                width='20%'
                height='20%'
                image_url={info?.image?.getFullUrl()}
                onSelect={onImageChange}
                onDelete={onImageDelete} />
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.member.free_key}</label>
            <div className='col-xs-9'>
              <input
                className='form-control'
                type='number'
                value={info?.free_key || ''}
                onChange={e => setInfo({ ...info, free_key: e.target.value })} />
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.create_date}</label>
            <label className='col-xs-9 col-form-label'>{info?.create_date || '-'}</label>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" type='button' onClick={onClose}>{strings.modal.close}</button>
          <button className="btn btn-success" type='submit'>{strings.common.save}</button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SignupMsgPopup;
