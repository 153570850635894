import { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../../config/page-settings";
import { USER_STATUS } from "../../../config/const";
import strings from "../../../lang/strings";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";

const MeetingPopupChat = ({ id, onBlock, onPenalty }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.chat;
  const [date, setDate] = useState('-');

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  useEffect(() => getInfo(), [id]);

  const getInfo = () => {
    context.get(
      'meeting/detail/' + id,
      {},
      response => {
        setDate(response.meeting.chat_open_date ?? '-');
      }
    );
  };

  const onUser = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'meeting/chat/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <label className='form-label'>
          {strings.formatString(strings.meeting.chat_open_date, date)}
        </label>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='chatColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)}/>
          <SearchInput
            id='chatInput'
            action={() => refreshTable(true)}/>
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'chat_id', orderable: false,
            }, {
              targets: [columnNo++], data: 'nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(item.nickname, item.gender, () => onUser(item.id));
              }
            }, {
              targets: [columnNo++], data: 'alias', orderable: false,
            }, {
              targets: [columnNo++], data: 'content', orderable: false,
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-danger btn-xs m-2 text-nowrap'>${strings.common.delete}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onDelete(item.chat_id));
                $(td).append(`<button type='button' class='btn btn-outline-dark btn-xs text-nowrap'>${item.status == USER_STATUS.BLOCK ? strings.member.unblock : strings.member.block}</button>`)
                  .find('button:eq(1)')
                  .on('click', () => onBlock(item.id, item.status, () => refreshTable(false)));
                $(td).append(`<button type='button' class='btn btn-outline-dark btn-xs m-2 text-nowrap'>${strings.lounge.penalty}</button>`)
                  .find('button:eq(2)')
                  .on('click', () => onPenalty(item.id, item.nickname));
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'meeting/chat/list',
              data: (req) => {
                req.meeting = id;
                req.column = $('#chatColumn').val();
                req.keyword = $('#chatInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.lounge.random_nickname}</th>
            <th style={{ width: '40%' }}>{strings.common.content}</th>
            <th>{strings.common.create_date}</th>
            <th style={{ width: '25%' }}>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody/>
        </DataTable>
      </div>
    </div>
  );

}

export default MeetingPopupChat;
