import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import React, { useContext, useRef } from "react";
import { USER_STATUS } from "../../../config/const";

const MeetingPopupReport = ({ id, onBlock, onPenalty }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.member;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onUser = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='reportColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)}/>
          <SearchInput
            id='reportInput'
            action={() => refreshTable(true)}/>
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'report_id', orderable: false,
            }, {
              targets: [columnNo++], data: 'profile', orderable: false,
              createdCell: (td, value, item) => {
                $(td).image_lightbox(value, () => {
                  context.showGallery(value.split('#').map(it => it.getFullUrl()));
                }, item.single);
              }
            }, {
              targets: [columnNo++], data: 'nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(item.nickname, item.gender, () => onUser(item.id));
              }
            }, {
              targets: [columnNo++], data: 'type', orderable: false,
              render: value => context.getSettingName(value, 'blame', 'content')
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
              render: value => value.dateFormat()
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-dark btn-xs text-nowrap'>${item.status == USER_STATUS.BLOCK ? strings.member.unblock : strings.member.block}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onBlock(item.id, item.status, () => refreshTable(false)));
                $(td).append(`<button type='button' class='btn btn-outline-dark btn-xs m-2 text-nowrap'>${strings.lounge.penalty}</button>`)
                  .find('button:eq(1)')
                  .on('click', () => onPenalty(item.id, item.nickname));
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'meeting/blame/meeting_list',
              data: (req) => {
                req.meeting = id;
                req.column = $('#reportColumn').val();
                req.keyword = $('#reportInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.member.profile}</th>
            <th>{strings.common.nickname}</th>
            <th style={{ width: '40%' }}>{strings.lounge.blame_reason}</th>
            <th>{strings.common.create_date}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody/>
        </DataTable>
      </div>
    </div>
  );

}

export default MeetingPopupReport;
