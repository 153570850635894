import { useContext, useEffect, useState } from "react";

import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import Container from "../../components/control/container";

const Setting = () => {

  const context = useContext(PageSettings);

  const [list, setList] = useState([]);
  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    context.get(
      'setting/setting',
      {},
      response => {
        setList(response);
      }
    );
  }

  const saveSetting = (id, value) => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'setting/setting',
        { id, value },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          getList();
        }
      );
    });
  }

  return (
    <Container title={strings.menu.setting_setting}>
      <div className='col-xl-8 col-lg-10 pl-0 pr-0'>
        <table className="table table-bordered table-td-valign-middle table-th-valign-middle">
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.common.title}</th>
            <th>{strings.common.content}</th>
            <th style={{ width: '100px' }}>{strings.setting.value}</th>
            <th>{strings.common.update_date}</th>
            <th>{strings.setting.creator}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody>
          {list.map((it, idx) => (
            <tr key={idx}>
              <td>{it.id}</td>
              <td>{it.title}</td>
              <td>{it.content}</td>
              <td>
                <input
                  type='number' autoComplete='off' className='form-control'
                  value={it.value || ''}
                  onChange={e => setList(prev => prev.map(item => {
                    if (it.id == item.id) {
                      item.value = e.target.value;
                    }
                    return item;
                  }))}/>
              </td>
              <td>{it.update_date || ''}</td>
              <td>{it.admin || ''}</td>
              <td>
                <button className="btn btn-primary btn-sm m-l-5"
                        onClick={() => saveSetting(it.id, it.value)}>
                  {strings.common.save}
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </Container>
  );

}

export default Setting;
