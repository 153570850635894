import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import querystring from "query-string";

import strings from "../../lang/strings";
import { Panel, PanelBody } from "../../components/panel/panel";
import MemberStatusFragment from "./fragment/status-fragment";

const MemberStatus = ({ location, history }) => {

  const [tab, setTab] = useState(1);

  useEffect(() => {
    const searchQS = querystring.parse(location.search);
    setTab(searchQS.tab || 1);
  }, [location.search]);

  const toggleTab = tab => {
    const searchQS = { tab };
    history.push('?' + querystring.stringify(searchQS));
  }

  const tabs = [
    { tab: 1, title: strings.member.leave },
    { tab: 2, title: strings.member.block },
    { tab: 3, title: strings.member.sleep },
  ];

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {tabs.map(menu => (
          <NavItem key={menu.title}>
            <NavLink className={classnames({ active: tab == menu.tab })} onClick={() => toggleTab(menu.tab)}>
              <span className="d-none d-xs-inline">{menu.title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        <MemberStatusFragment tab={tab}/>
      </PanelBody>
    </Panel>
  );

}

export default withRouter(MemberStatus);
