import React, { useContext, useRef } from "react";
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import Container from "../../components/control/container";
import { USER_STATUS } from "../../config/const";

const LoungePenalty = () => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.comment;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onUser = id => {
    context.showUserPopup(id, () => refreshTable(false));
  }

  const onBlock = user => {
    const content = user.status == USER_STATUS.BLOCK ? strings.modal.unblock_confirm : strings.modal.block_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/block',
        {
          id: user.id,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.lounge_penalty}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)}/>
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)}/>
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'email', orderable: false,
              }, {
                targets: [columnNo++], data: 'profile', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).image_lightbox(value, () => {
                    context.showGallery(value.split('#').map(it => it.getFullUrl()));
                  }, item.single);
                }
              }, {
                targets: [columnNo++], data: 'nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(item.nickname, item.gender, () => onUser(item.id));
                }
              }, {
                targets: [columnNo++], data: 'job', orderable: false,
              }, {
                targets: [columnNo++], data: 'status', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).user_status(item);
                }
              }, {
                targets: [columnNo++], data: 'level', orderable: false,
                render: value => strings.member.level_list[value],
              }, {
                targets: [columnNo++], data: 'all_cnt', orderable: false,
                render: value => value.currencyFormat(),
              }, {
                targets: [columnNo++], data: 'day', orderable: false,
                render: value => value.currencyFormat() + strings.common.date
              }, {
                targets: [columnNo++], data: 'reason', orderable: false,
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
                render: value => value.dateFormat()
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-outline-dark btn-sm text-nowrap'>${item.status == USER_STATUS.BLOCK ? strings.member.unblock : strings.member.block}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onBlock(item));
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'lounge/penalty_list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.member.id}</th>
              <th>{strings.member.profile}</th>
              <th>{strings.common.nickname}</th>
              <th>{strings.member.job}</th>
              <th>{strings.member.status}</th>
              <th>{strings.member.type}</th>
              <th>{strings.lounge.all_cnt}</th>
              <th>{strings.lounge.penalty_day_cnt}</th>
              <th>{strings.lounge.penalty_reason}</th>
              <th>{strings.lounge.penalty_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody/>
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default LoungePenalty;
