import React from "react";
import strings from "../../lang/strings";
import Container from "../../components/control/container";
import MeetingListFragment from "./fragment/meeting-list-fragment";

const MeetingList = () => {
  return (
    <Container title={strings.menu.meeting_list}>
      <MeetingListFragment />
    </Container>
  );

}

export default MeetingList;
