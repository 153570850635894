import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { GENDER } from "../../../config/const";

const PartnerPopup = ({ isOpen, info, onClose}) => {

  const context = useContext(PageSettings);
  const [item, setItem] = useState(null);

  const getInfo = () => {
    if (!info?.id) {
      setItem(info);
      return;
    }

    context.get(
      `partner/detail/${info?.id}`,
      {},
      res => {
        setItem(res);
      }
    );
  }

  const onApply = () => {
    if (!item?.nickname) {
      context.addNotification(strings.modal.alert, strings.partner.input_all, 'warning');
      return;
    }

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'partner/save',
        {
          id: item?.id || 0,
          nickname: item?.nickname,
          user_id: item?.user_id,
          password: item?.password_raw
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          onClose?.();
        }
      );
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} onOpened={getInfo}>
      <ModalHeader className="popup-nickname" toggle={onClose}>
        {item?.id ? strings.partner.edit : strings.partner.add}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xs-12">
            <div className='row'>
              <label className='col-md-3 col-form-label'>{strings.member.name}</label>
              <div className="col-md-9">
                <input
                  type='text' autoComplete='off' className='form-control'
                  value={item?.nickname || ''}
                  onChange={e => setItem({ ...item, nickname: e.target.value })}/>
              </div>
            </div>
            <div className='row'>
              <label className='col-md-3 col-form-label'>ID</label>
              <div className="col-md-9">
                <input
                  type='text' autoComplete='off' className='form-control'
                  value={item?.user_id || ''}
                  onChange={e => setItem({ ...item, user_id: e.target.value })}/>
              </div>
            </div>
            <div className='row'>
              <label className='col-md-3 col-form-label'>PW</label>
              <div className="col-md-9">
                <input
                  type='text' autoComplete='off' className='form-control'
                  value={item?.password_raw || ''}
                  onChange={e => setItem({ ...item, password_raw: e.target.value })}/>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={onClose}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onApply}>{strings.common.save}</button>
      </ModalFooter>
    </Modal>
  );
};

export default PartnerPopup;
