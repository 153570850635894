import strings from "../../../lang/strings";
import { GENDER, PARTNER, STATUS } from "../../../config/const";
import { useContext } from "react";
import { PageSettings } from "../../../config/page-settings";
import ReactPlayer from "react-player";

const MemberDetailPopupMain = ({ user, additional, auth, referrer, onChange, onAuthDelete, onVideoDelete }) => {
  const context = useContext(PageSettings);

  let locations = [];
  context.getSettingList('resident').forEach(it => {
    if (it.children.length < 1) {
      locations.push(it.label);
    } else {
      it.children.forEach(child => {
        locations.push(it.label + ' ' + child.label);
      });
    }
  });

  const profiles = user?.profile?.split('#').map(it => it.getFullUrl());

  return (
    <>
      <div className="row">
        <div className="col-xs-6">
          <div className='row'>
            <label className='col-xl-4 col-form-label'>UID</label>
            <label className='col-xl-8 col-form-label'>{user?.id}</label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.market_agree}</label>
            <label className='col-xl-8 col-form-label'>
              <input type='checkbox' checked={user?.market == 1} readOnly />
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.id}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="email"
                value={user?.email || ''}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.common.nickname}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="nickname"
                value={user?.nickname || ''}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.name}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="name"
                value={user?.name || ''} readOnly />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.phone}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="phone"
                value={user?.phone || ''} readOnly />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.age}</label>
            <label className='col-xl-8 col-form-label'>{user?.birth?.ageFormat() || ''}</label>
            {/*<div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="birth"
                     value={user?.birth?.ageFormat() || ''} readOnly />
            </div>*/}
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.common.gender}</label>
            <div className="col-xl-8">
              <select id='gender' className='form-control'
                onChange={onChange}
                value={user?.gender || GENDER.MALE}>
                <option value={GENDER.MALE}>{strings.common.male}</option>
                <option value={GENDER.FEMALE}>{strings.common.female}</option>
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.job}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="job"
                value={user?.job || ''}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.location}</label>
            <div className="col-xl-8">
              <select className='form-control' id='location' value={user?.location || ''} onChange={onChange}>
                {locations.map(it => <option key={it} value={it}>{it}</option>)}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.family}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="family"
                value={user?.family || ''}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.single}</label>
            <div className="col-xl-8">
              <select id='single' className='form-control'
                onChange={onChange}
                value={user?.single || STATUS.NO}>
                {Object.keys(strings.member.single_list).filter(it => it != '').map(key => (
                  <option value={key} key={key}>{strings.member.single_list[key]}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.height}(cm)</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="height"
                value={user?.height || ''}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.instagram_id}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="instagram_id"
                value={user?.instagram_id || ''}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.youtube_id}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="youtube_id"
                value={user?.youtube_id || ''}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.sns_id}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="sns_id"
                value={user?.sns_id || ''}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.mbti}</label>
            <label className='col-xl-8 col-form-label'>
              {user?.mbti?.split(',').filter(it => it != '').map(it => context.getSettingName(it, 'mbti')).join(', ') || '-'}
            </label>
          </div>
          {user?.gender == GENDER.MALE &&
            <div className='row'>
              <label className='col-xl-4 col-form-label'>{strings.member.profile_public}</label>
              <div className="col-xl-8">
                <select id='profile_public' className='form-control'
                  onChange={onChange}
                  value={user?.profile_public || STATUS.NO}>
                  <option value={STATUS.NO}>{strings.lounge.private}</option>
                  <option value={STATUS.YES}>{strings.lounge.public}</option>
                </select>
              </div>
            </div>
          }
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.school}</label>
            <div className='col-xl-8'>
              <select className='form-control' id='school' value={user?.school ?? 0} onChange={onChange}>
                {context.getSettingList('education').map(it =>
                  <option key={`school-${it.code}`} value={it.code}>{it.name}</option>
                )}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.my_school}</label>
            <div className='col-xl-8'>
              <select className='form-control' id='my_school' value={user?.my_school ?? 0} onChange={onChange}>
                {context.getSettingList('my_school').map(it =>
                  <option key={`school-${it.code}`} value={it.code}>{it.name}</option>
                )}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.drink}</label>
            <div className="col-xl-8">
              <select className='form-control' id='drink' value={user?.drink ?? 0} onChange={onChange}>
                {context.getSettingList('alcohol').map(it =>
                  <option key={`drink-${it.code}`} value={it.code}>{it.name}</option>
                )}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.religion}</label>
            <div className='col-xl-8'>
              <select className='form-control' id='religion' value={user?.religion ?? 0} onChange={onChange}>
                {context.getSettingList('religion').map(it =>
                  <option key={`religion-${it.code}`} value={it.code}>{it.name}</option>
                )}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.smoke}</label>
            <div className='col-xl-8'>
              <select className='form-control' id='smoke' value={user?.smoke ?? STATUS.YES} onChange={onChange}>
                {Object.keys(strings.member.smoke_list).map(key =>
                  <option key={`smoke-${key}`} value={key}>{strings.member.smoke_list[key]}</option>
                )}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.marriage_plan}</label>
            <div className='col-xl-8'>
              <select className='form-control' id='marriage_plan' value={user?.marriage_plan ?? 0} onChange={onChange}>
                {context.getSettingList('marriage_plan').map(it =>
                  <option key={`marriage-${it.code}`} value={it.code}>{it.name}</option>
                )}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.body_type}</label>
            <div className='col-xl-8'>
              <select className='form-control' id='body_type' value={user?.body_type ?? 0} onChange={onChange}>
                {context.getSettingList('body_type', user?.gender == GENDER.MALE ? 'male' : 'female').map(it =>
                  <option key={`body-${it.code}`} value={it.code}>{it.name}</option>
                )}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.appeal_point}</label>
            <div className='col-xl-8 form-inline'>
              {context.getSettingList('appeal_point').map((it, idx) =>
                <div key={`appeal-${idx}`} className='form-inline m-r-10'>
                  <input type='checkbox' value={it.code} readOnly
                    id={`appeal-${idx}`}
                    checked={user?.appeal_point?.split(',').includes(`${it.code}`)}
                    onChange={() => {
                      const appeal_point = user?.appeal_point?.split(',');
                      if (appeal_point.includes(`${it.code}`)) {
                        onChange({
                          target: {
                            id: 'appeal_point',
                            value: appeal_point.filter(item => item != `${it.code}`).join(',')
                          }
                        });
                      } else {
                        onChange({
                          target: {
                            id: 'appeal_point',
                            value: appeal_point.concat(`${it.code}`).join(',')
                          }
                        });
                      }
                    }} />
                  <label htmlFor={`appeal-${idx}`}>&nbsp;{it.name}</label>
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.signup_date}</label>
            <label className='col-xl-8 col-form-label'>{user?.signup_date || ''}</label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.login_date}</label>
            <label className='col-xl-8 col-form-label'>{user?.login_date || ''}</label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.referrer}</label>
            <label className='col-xl-8 col-form-label'>
              {referrer != null ? `${referrer?.email} (${user?.referrer_code})` : '-'}
            </label>
          </div>
          <h5 className='m-t-10'><u>{strings.member.necessary_item}</u></h5>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_age}</label>
            <label className='col-xl-8 col-form-label'>
              {user?.iwant_age_from || ''}~{user?.iwant_age_to || ''}
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_height}</label>
            <label className='col-xl-8 col-form-label'>
              {
                context.getSettingList('height')
                  .filter(it => user?.iwant_height?.split(',').includes(`${it.code}`))
                  .map(it => it.name)
                  .join(', ') || '-'
              }
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_drink}</label>
            <label className='col-xl-8 col-form-label'>
              {
                context.getSettingList('other_alcohol')
                  .filter(it => user?.iwant_drink?.split(',').includes(`${it.code}`))
                  .map(it => it.name)
                  .join(', ') || '-'
              }
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_smoke}</label>
            <label className='col-xl-8 col-form-label'>
              {
                context.getSettingList('smoke')
                  .filter(it => user?.iwant_smoke?.split(',').includes(`${it.code}`))
                  .map(it => it.name)
                  .join(', ') || '-'
              }
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_single}</label>
            <label className='col-xl-8 col-form-label'>
              {strings.member.iwant_single_list[user?.iwant_single] || '-'}
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_religion}</label>
            <label className='col-xl-8 col-form-label'>
              {
                context.getSettingList('religion')
                  .filter(it => user?.iwant_religion?.split(',').includes(`${it.code}`))
                  .map(it => it.name)
                  .join(', ') || '-'
              }
            </label>
          </div>
          <h5 className='m-t-10'><u>{strings.member.favor_item}</u></h5>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_marriage}</label>
            <label className='col-xl-8 col-form-label'>
              {
                context.getSettingList('marriage_mileage')
                  .filter(it => user?.iwant_marriage?.split(',').includes(`${it.code}`))
                  .map(it => <p key={it.code} className='m-b-0'>{it?.name}</p>) || '-'
              }
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_school}</label>
            <label className='col-xl-8 col-form-label'>
              {context.getSettingList('other_school').find(it => it.code == user?.iwant_school)?.name ?? '-'}
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_salary}</label>
            <label className='col-xl-8 col-form-label'>
              {context.getSettingList('other_salary').find(it => it.code == user?.iwant_salary)?.name ?? '-'}
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_asset}</label>
            <label className='col-xl-8 col-form-label'>
              {context.getSettingList('other_asset').find(it => it.code == user?.iwant_asset)?.name ?? '-'}
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.iwant_mbti}</label>
            <label className='col-xl-8 col-form-label'>
              {user?.iwant_mbti?.split(',').map(it => context.getSettingName(it, 'mbti')).join(', ') || '-'}
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.ihate_auth}</label>
            <label className='col-xl-8 col-form-label'>
              {
                context.getSettingList('auth')
                  .filter(it => user?.ihate_auth?.split(',').includes(`${it.code}`))
                  .map(it => it.code == 0 ? strings.common.no_care : it.name)
                  .join(', ') || '-'
              }
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.best_favor1}</label>
            <label className='col-xl-8 col-form-label'>
              {context.getSettingList('best_favor').find(it => it.code == user?.best_favor1)?.name ?? '-'}
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.best_favor2}</label>
            <label className='col-xl-8 col-form-label'>
              {context.getSettingList('best_favor').find(it => it.code == user?.best_favor2)?.name ?? '-'}
            </label>
          </div>
        </div>
        <div className="col-xs-6">
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.admin_check}</label>
            <label className='col-xl-8 col-form-label'>
              <input type='checkbox' checked={user?.admin_check == 1} value={STATUS.NORMAL}
                onChange={() => {
                  onChange({
                    target: {
                      id: 'admin_check',
                      value: 1 - (user?.admin_check ?? 0)
                    }
                  })
                }} />
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.vip}</label>
            <div className="col-xl-8">
              <select id='vip' className='form-control'
                onChange={onChange}
                value={user?.vip || 0}>
                {strings.member.vip_list.map((it, idx) => (
                  <option key={`vip-${idx}`} value={idx}>{it}</option>
                ))}
              </select>
              {
                (user?.vip || 0) == 1 && user?.partner_type == PARTNER.CHARGE &&
                <button className="btn btn-primary m-t-5 m-b-5" onClick={() => context.showConciergePopup(user)}>
                  {strings.member.concierge_chat}
                </button>
              }
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.app_alarm_active}</label>
            <label className='col-xl-8 col-form-label'>
              {
                (user?.fcm_token || '') == '' ?
                  <i className='fa fa-times-circle' /> :
                  <i className='fa fa-check-circle text-success' />
              }
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.dashboard.active}</label>
            <label className='col-xl-8 col-form-label'>
              <input type='checkbox' checked={user?.active == 1} value={STATUS.NORMAL}
                onChange={() => {
                  onChange({
                    target: {
                      id: 'active',
                      value: 1 - (user?.active ?? 1)
                    }
                  })
                }} />
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.level_look}</label>
            {
              user?.gender == GENDER.FEMALE ?
                <div className="col-xl-8">
                  <select id='level_look' className='form-control'
                    onChange={onChange}
                    value={user?.level_look || 1}>
                    {[...Array(5).keys()].map(lvl => (
                      <option key={`look-${lvl}`} value={lvl + 1}>
                        {`Lv${lvl + 1}${(lvl + 1) <= 2 ? `(${strings.member.good_look_user})` : ''}`}
                      </option>
                    ))}
                  </select>
                </div>
                :
                <label className='col-xl-8 col-form-label'>n / a</label>
            }
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.level}</label>
            <div className="col-xl-8">
              <select id='level' className='form-control'
                onChange={onChange}
                value={user?.level || 'C'}>
                {Object.keys(strings.member.level_list).map(key => (
                  <option key={`user-level-${key}`} value={key}>{strings.member.level_list[key]}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.level_type}</label>
            <div className="col-xl-8">
              <select id='level_type' className='form-control'
                onChange={onChange}
                value={user?.level_type || 'D'}>
                {Object.keys(strings.member.level_type_list).map(key => (
                  <option key={`user-type-level-${key}`} value={key}>{strings.member.level_type_list[key]}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.photo_public}</label>
            <div className="col-xl-8">
              <select id='profile_public' className='form-control'
                onChange={onChange}
                value={user?.profile_public || 'Y'}>
                <option value={STATUS.YES}>{strings.lounge.public}</option>
                <option value={STATUS.NO}>{strings.lounge.private}</option>
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.p_level}</label>
            <div className="col-xl-8">
              <select id='p_level' className='form-control'
                onChange={onChange}
                value={user?.p_level || (user?.gender == GENDER.MALE ? 'B' : 'D')}>
                {(user?.gender == GENDER.MALE ? ['S', 'A', 'B'] : ['S', 'A', 'B', 'C', 'D']).map(lvl => (
                  <option key={`p-${lvl}`} value={lvl}>Lvl {lvl}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.key_total}</label>
            <label className='col-xl-8 col-form-label'>
              {strings.formatString(strings.common.key, user?.key?.currencyFormat() ?? '0')}
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.money_total}</label>
            <label className='col-xl-8 col-form-label'>
              {strings.formatString(strings.common.won, additional?.total_money?.currencyFormat() ?? '0')}
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-12 col-form-label'>{strings.member.memo}</label>
            <div className="col-xl-12">
              <textarea autoComplete='off' className='form-control m-b-2'
                id='memo'
                value={user?.memo || ''}
                rows={4}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-12 col-form-label'>{strings.member.appeal_extra}</label>
            <div className="col-xl-12">
              <textarea autoComplete='off' className='form-control m-b-2'
                id='appeal_extra'
                value={user?.appeal_extra || ''}
                rows={8}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-12 col-form-label'>{strings.member.ritzy_comment}</label>
            <div className="col-xl-12">
              <textarea autoComplete='off' className='form-control m-b-2'
                id='ritzy_comment'
                value={user?.ritzy_comment || ''}
                rows={6}
                onChange={onChange} />
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.match_blame_cnt}</label>
            <label className='col-xl-8 col-form-label'>
              {
                (additional?.match_blame_cnt?.currencyFormat() ?? '0') == '0' ? '-' :
                  strings.formatString(strings.common.count, additional?.match_blame_cnt?.currencyFormat())
              }
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.meeting_blame_cnt}</label>
            <label className='col-xl-8 col-form-label'>
              {
                (additional?.meeting_blame_cnt?.currencyFormat() ?? '0') == '0' ? '-' :
                  strings.formatString(strings.common.count, additional?.meeting_blame_cnt?.currencyFormat())
              }
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-4 col-form-label'>{strings.member.square_blame_cnt}</label>
            <label className='col-xl-8 col-form-label'>
              {
                (additional?.lounge_blame_cnt?.currencyFormat() ?? '0') == '0' ? '-' :
                  strings.formatString(strings.common.count, additional?.lounge_blame_cnt?.currencyFormat())
              }
            </label>
          </div>
          <div className='row m-t-10'>
            <label className='col-xl-4 col-form-label'>{strings.member.profile}</label>
            <div className='col-xl-8'>
              {profiles?.map((photo, idx) => (
                <img
                  key={idx}
                  src={photo.getThumbnailUrl()}
                  style={{ width: 'auto', height: 80, cursor: 'pointer', marginRight: 10, marginBottom: 10 }}
                  onClick={() => context.showGallery(profiles, idx)} />
              ))}
            </div>
          </div>
          <div className='row m-t-10'>
          <div className='d-flex flex-column align-items-start col-xs-4'>
              <label className='col-form-label'>{strings.member.video}</label>
              <button type='button' className='btn btn-danger justify-content-end btn-sm p-t-2 p-b-2'
                onClick={() => onVideoDelete?.()}>
                {strings.common.delete}
              </button>
            </div>
            <div className='col-xl-8 d-flex align-items-center'>
              {
                user?.video ?
                  <>
                    <ReactPlayer url={user?.video.getFullUrl()}
                      controls
                      width='80%'
                      height='auto' />
                    <a href={user?.video.getFullUrl()} target='_blank' className='btn btn-primary m-l-20'>
                      {strings.member.download_video}
                    </a>
                  </>
                  :
                  <span>{strings.member.no_info}</span>
              }
            </div>
          </div>
          <div className='row m-t-10'>
            <label className='col-xl-4 col-form-label'>{strings.member.mini_profile}</label>
            <div className='col-xl-8 d-flex align-items-center'>
              {
                user?.mini_profile ?
                  <img
                    src={user?.mini_profile.getFullUrl().getThumbnailUrl()}
                    style={{ width: 'auto', height: 80, cursor: 'pointer', marginRight: 10 }}
                    onClick={() => context.showGallery([user?.mini_profile.getFullUrl()])} />
                  :
                  <span>{strings.member.no_info}</span>
              }
            </div>
          </div>
          <h5 className='m-t-10'><u>{strings.member.auth_info}</u></h5>
          {auth.filter(it => it.status == STATUS.NORMAL).map((it, idx) => (
            <div className='row m-b-10' key={idx}>
              <div className='d-flex flex-column align-items-start col-xs-4'>
                <label>
                  {context.getSettingName(it.type, 'auth')}
                </label>
                <button type='button' className='btn btn-danger justify-content-end btn-sm p-t-2 p-b-2'
                  onClick={() => onAuthDelete?.(it.id)}>
                  {strings.common.delete}
                </button>
              </div>
              <div className='col-xl-8'>
                {it.image.split('#').map((image, index) => (
                  <img
                    key={`image-${index}`}
                    src={image.getFullUrl().getThumbnailUrl()}
                    style={{ width: 'auto', height: 80, cursor: 'pointer', marginRight: 10 }}
                    onClick={() => context.showGallery([image.getFullUrl()])} />
                ))}
              </div>
            </div>
          ))}
          <div className='row m-t-10'>
            <label className='col-xl-12'>{strings.member.about_me}</label>
            <label className='col-xl-12'>
              <textarea autoComplete='off' className='form-control m-b-2'
                id='about_me'
                value={user?.about_me || ''}
                rows={8}
                onChange={onChange} />
            </label>
          </div>
          <div className='row'>
            <label className='col-xl-12'>{strings.member.intro}</label>
            <label className='col-xl-12'>
              <textarea autoComplete='off' className='form-control m-b-2'
                id='intro'
                value={user?.intro || ''}
                rows={8}
                onChange={onChange} />
            </label>
          </div>
        </div>
      </div>
      <div className='row'>
        <h5 className='m-t-20'><u>Q&A Time</u></h5>
        <div className='row m-t-10'>
          <label className='col-xl-3'>{strings.member.qa_first_date}</label>
          <div className='col-xl-8'>
            <textarea autoComplete='off' className='form-control m-b-2'
              id='qa_first_date'
              value={user?.qa_first_date || ''}
              rows={8}
              onChange={onChange} />
          </div>
        </div>
        {/*<div className='row m-t-10'>
          <label className='col-xl-3'>{strings.member.qa_second_date}</label>
          <label className='col-xl-8'>
            <textarea autoComplete='off' className='form-control m-b-2'
                      id='qa_second_date'
                      value={user?.qa_second_date || ''}
                      rows={8}
                      onChange={onChange}/>
          </label>
        </div>*/}
        <div className='row m-t-10'>
          <label className='col-xl-3'>{strings.member.qa_favorite_style}</label>
          <div className='col-xl-8'>
            <textarea autoComplete='off' className='form-control m-b-2'
              id='qa_favorite_style'
              value={user?.qa_favorite_style || ''}
              rows={8}
              onChange={onChange} />
          </div>
        </div>
        <div className='row m-t-10'>
          <label className='col-xl-3'>{strings.member.qa_interest}</label>
          <div className='col-xl-8'>
            <textarea autoComplete='off' className='form-control m-b-2'
              id='qa_interest'
              value={user?.qa_interest || ''}
              rows={8}
              onChange={onChange} />
          </div>
        </div>
        <div className='row m-t-10'>
          <label className='col-xl-3'>{strings.member.qa_weekend}</label>
          <div className='col-xl-8'>
            <textarea autoComplete='off' className='form-control m-b-2'
              id='qa_weekend'
              value={user?.qa_weekend || ''}
              rows={8}
              onChange={onChange} />
          </div>
        </div>
        <div className='row m-t-10'>
          <label className='col-xl-3'>{strings.member.qa_location_distance}</label>
          <div className='col-xl-8'>
            <textarea autoComplete='off' className='form-control m-b-2'
              id='qa_location_distance'
              value={user?.qa_location_distance || ''}
              rows={8}
              onChange={onChange} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberDetailPopupMain;
