import React from 'react';

const SearchInput = React.memo(
  ({
     id = '',
     className = '',
     type = 'text',
     placeholder = '',
     action,
     onChange
   }) => {

    const onKeyDown = e => {
      if (e.keyCode === 13) {
        action?.();
      }
    }

    return (
      <div className='form-horizontal form-inline'>
        <input
          id={id}
          className={'form-control ' + className}
          type={type}
          defaultValue=''
          placeholder={placeholder}
          autoComplete='off'
          onKeyDown={onKeyDown}
          onChange={e => onChange?.(e.target.value)}
        />
      </div>
    );
  }
);

export default SearchInput;
