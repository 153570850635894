import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import querystring from "query-string";
import strings from "../../lang/strings";
import { Panel, PanelBody } from "../../components/panel/panel";
import { GENDER } from "../../config/const";
import MemberNormalFragment from "./fragment/normal-fragment";
import { PageSettings } from "../../config/page-settings";

const MemberNormal = ({ location, history, type }) => {

  const context = useContext(PageSettings);
  const [tabs, setTabs] = useState([
    { gender: '', title: strings.member.total_member, total: 0 },
    { gender: GENDER.FEMALE, title: strings.member.female_member, total: 0 },
    { gender: GENDER.MALE, title: strings.member.male_member, total: 0 },
  ]);
  const [gender, setGender] = useState('');

  useEffect(() => {
    context.get(
      'member/count',
      { type },
      response => {
        const { total, male, female } = response;
        setTabs(prev => prev.map(it => {
          it.total = it.gender == '' ? total : (it.gender == GENDER.MALE ? male : female);
          return it;
        }));
      }
    );
  }, [type]);

  useEffect(() => {
    const searchQS = querystring.parse(location.search);
    setGender(searchQS.gender || '');
  }, [location.search]);

  const toggleTab = gender => {
    const searchQS = { gender };
    history.push('?' + querystring.stringify(searchQS));
  }

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {tabs.map(menu => (
          <NavItem key={menu.title}>
            <NavLink className={classnames({ active: gender === menu.gender })} onClick={() => toggleTab(menu.gender)}>
              <span className="d-none d-xs-inline">{menu.title} ({menu.total.currencyFormat()})</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        <MemberNormalFragment gender={gender} type={type} />
      </PanelBody>
    </Panel>
  );
}

export default withRouter(MemberNormal);
