import React, { useContext, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import MatchDescPopup from "./match-desc-popup";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import { GENDER, STATUS } from "../../../config/const";
import LocationTable from "../../../components/control/location-table";

const MatchPopup = ({ isOpen, toggleModal, user }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.member;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const locationRef = useRef();

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    user: 0
  });
  const hidePopup = () => setPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });
  const showPopup = user_id => setPopup(prev => {
    return {
      ...prev,
      isOpen: true,
      user: user_id
    };
  });

  const onMatch = desc => {
    hidePopup();
    context.post(
      'match/match',
      {
        user1: user.id,
        user2: popup.user,
        desc
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.applied);
        refreshTable(false);
      }
    );
  }
  
  const onAddReservation = desc => {
    hidePopup();
    context.post(
      'match/add-reservation',
      {
        user1: user.id,
        user2: popup.user,
        desc
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.applied);
        refreshTable(false);
      }
    );
  }


  const onToggleActive = user_id => {
    context.post(
      'member/active',
      { id: user_id },
      () => refreshTable(false)
    );
  }

  const getItem = (value, item, gender = '') => {
    const colorClass = value == 1 ? 'text-success' : 'text-danger';
    const iconName = value == 1 ? 'fa-check' : 'fa-times';

    let name = '';
    if (gender == '') {
      // gender 가 빈문짜열인 경우는 최선호항목이며 item 은 특정값(BEST_FAVOR 의 코드값)으로 들어 온다.
      name = context.getSettingList('best_favor').find(it => it.code == item)?.short ?? '';
    } else {
      // gender 가 male/female 이라는 문짜열이라면 필수항목이며 item 은 해당 유저(row)의 정보값이다.
      const nameList = strings.match.match_necessity;
      const tmpList = [];
      for (let key in nameList) {
        if (item[`${gender}_${key}`] == 0) {
          tmpList.push(nameList[key]);
        }
      }
      if (tmpList.length > 0) {
        name = tmpList.join(', ');
      }
    }

    return `<i class="fa ${iconName} ${colorClass}"></i><br/><span class="${colorClass} f-s-10">${name}</span>`;
  }

  return (
    <>
      <Modal className='modal-match' isOpen={isOpen} toggle={toggleModal} onOpened={() => refreshTable(true)}
             size={'xl'}>
        <ModalHeader className="popup-title" toggle={toggleModal}>
          {user?.gender == GENDER.FEMALE ? strings.menu.match_match : strings.menu.match_curator}&nbsp;
          (
          <span className={user?.gender === GENDER.MALE ? 'male' : 'female'} style={{ cursor: 'pointer' }}
                onClick={() => onCell(user?.id)}>
            {user?.nickname}
          </span>&nbsp;/&nbsp;
          {user?.gender?.genderStr()}&nbsp;/&nbsp;
          {user?.birth.ageFormat()}&nbsp;/&nbsp;
          {user?.location}&nbsp;/&nbsp;
          {user?.job}&nbsp;/&nbsp;
          {strings.member.auth_info}({context.getSettingList('auth').filter(it => user?.user_auth?.split(',').includes(`${it.code}`)).map(it => it.name).join(',')})
          )
        </ModalHeader>
        <ModalBody>
          <div className="row border-bottom pb-3">
            <div className='col-md-6'>
              <label>{strings.member.necessary_item}:</label><br />
              <table className='table table-bordered'>
                <tbody>
                <tr>
                  <th className='text-left' style={{ width: '30%' }}>{strings.member.iwant_age}</th>
                  <td className='text-left'>
                    {user?.iwant_age_from || ''}~{user?.iwant_age_to || ''}
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.iwant_height}</th>
                  <td className='text-left'>
                    {
                      context.getSettingList('height')
                        .filter(it => user?.iwant_height?.split(',').includes(`${it.code}`))
                        .map(it => it.name)
                        .join(', ') || '-'
                    }
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.iwant_drink}</th>
                  <td className='text-left'>
                    {
                      context.getSettingList('other_alcohol')
                        .filter(it => user?.iwant_drink?.split(',').includes(`${it.code}`))
                        .map(it => it.name)
                        .join(', ') || '-'
                    }
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.iwant_smoke}</th>
                  <td className='text-left'>
                    {
                      context.getSettingList('smoke')
                        .filter(it => user?.iwant_smoke?.split(',').includes(`${it.code}`))
                        .map(it => it.name)
                        .join(', ') || '-'
                    }
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.iwant_single}</th>
                  <td className='text-left'>
                    {strings.member.iwant_single_list[user?.iwant_single] || '-'}
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.iwant_religion}</th>
                  <td className='text-left'>
                    {
                      context.getSettingList('religion')
                        .filter(it => user?.iwant_religion?.split(',').includes(`${it.code}`))
                        .map(it => it.name)
                        .join(', ') || '-'
                    }
                  </td>
                </tr>
                </tbody>
              </table>
              <table className='table table-bordered m-t-10'>
                <tbody>
                <tr>
                  <th className='text-left' style={{ width: '30%' }}>{strings.member.card_total}</th>
                  <td className='text-left'>
                    {
                      ((user?.match_total_cnt ?? 0) == 0) ? '-' :
                        strings.formatString(strings.member.count, user?.match_total_cnt?.currencyFormat())
                    }
                  </td>
                </tr>
                <tr>
                  <th className='text-left' style={{ width: '30%' }}>{strings.member.card_refuse}</th>
                  <td className='text-left'>
                    {
                      ((user?.match_refuse_cnt ?? 0) == 0) ? '-' :
                        strings.formatString(strings.member.count, user?.match_refuse_cnt?.currencyFormat())
                    }
                  </td>
                </tr>
                <tr>
                  <th className='text-left' style={{ width: '30%' }}>{strings.member.card_complete}</th>
                  <td className='text-left'>
                    {
                      ((user?.match_complete_cnt ?? 0) == 0) ? '-' :
                        strings.formatString(strings.member.count, user?.match_complete_cnt?.currencyFormat())
                    }
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className='col-md-6'>
              <label>{strings.member.favor_item}:</label><br />
              <table className='table table-bordered'>
                <tbody>
                <tr>
                  <th className='text-left' style={{ width: '30%' }}>{strings.member.iwant_marriage}</th>
                  <td className='text-left'>
                    {
                      context.getSettingList('marriage_mileage')
                        .filter(it => user?.iwant_marriage?.split(',').includes(`${it.code}`))
                        .map(it => <p key={it.code} className='m-b-0'>{it?.name}</p>) || '-'
                    }
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.iwant_school}</th>
                  <td className='text-left'>
                    {context.getSettingList('other_school').find(it => it.code == user?.iwant_school)?.name ?? '-'}
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.iwant_salary}</th>
                  <td className='text-left'>
                    {context.getSettingList('other_salary').find(it => it.code == user?.iwant_salary)?.name ?? '-'}
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.iwant_asset}</th>
                  <td className='text-left'>
                    {context.getSettingList('other_asset').find(it => it.code == user?.iwant_asset)?.name ?? '-'}
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.iwant_mbti}</th>
                  <td className='text-left'>
                    {user?.iwant_mbti?.split(',').map(it => context.getSettingName(it, 'mbti')).join(', ') || '-'}
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.ihate_auth}</th>
                  <td className='text-left'>
                    {
                      context.getSettingList('auth')
                        .filter(it => user?.ihate_auth?.split(',').includes(`${it.code}`))
                        .map(it => it.code == 0 ? strings.common.no_care : it.name)
                        .join(', ') || '-'
                    }
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.best_favor1}</th>
                  <td className='text-left'>
                    {context.getSettingList('best_favor').find(it => it.code == user?.best_favor1)?.name ?? '-'}
                  </td>
                </tr>
                <tr>
                  <th className='text-left'>{strings.member.best_favor2}</th>
                  <td className='text-left'>
                    {context.getSettingList('best_favor').find(it => it.code == user?.best_favor2)?.name ?? '-'}
                  </td>
                </tr>
                <tr>
                  <th className='text-left text-primary' style={{ width: '30%' }}>{strings.member.level_type}</th>
                  <td className='text-left text-primary'>
                    {strings.member.level_type_list[user?.level_type]}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8 mt-3'>
              <div className='form-horizontal form-inline'>
                <SearchSelect
                  id='memberColumn'
                  value={searchOptions[0].value}
                  data={searchOptions}
                  onChange={() => refreshTable(true)} />
                <SearchInput
                  id='memberInput'
                  action={() => refreshTable(true)} />
                {/*<span className='width-80 text-right'>{strings.member.birth}:&nbsp;&nbsp;</span>
                <SearchInput
                  id='ageFrom'
                  type='number'
                  className='width-100'
                  placeholder={1980}
                  action={() => refreshTable(true)}
                />
                <span className=''>&nbsp;&nbsp;~&nbsp;&nbsp;</span>
                <SearchInput
                  id='ageTo'
                  type='number'
                  className='width-100'
                  placeholder={1990}
                  value={''}
                  action={() => refreshTable(true)}
                />*/}
                <button type='button' className='btn btn-success btn-sm ml-md-2'
                        onClick={() => refreshTable(true)}>{strings.common.search}</button>
                <label className='m-l-15'>
                  <input type='checkbox' id='activePopupUser' onChange={() => refreshTable(true)} />
                  &nbsp;{strings.match.active_user}
                </label>
              </div>
              <div className='form-horizontal form-inline m-t-10'>
                <LocationTable ref={locationRef} />
              </div>
            </div>
            <div className='col-md-12 mt-3'>
              <DataTable
                ref={tableRef}
                options={{
                  columnDefs: [{
                    targets: [columnNo++], data: 'id', orderable: false,
                  }, {
                    targets: [columnNo++], data: 'email', orderable: true,
                  }, {
                    targets: [columnNo++], data: 'profile', orderable: false,
                    createdCell: (td, value, item) => {
                      if (!value) {
                        $(td).image_lightbox(item.admin_profile, () => {
                          context.showGallery([item.admin_profile.getFullUrl()]);
                        }, item.single);
                      } else {
                        $(td).image_lightbox(value, () => {
                          context.showGallery(value.split('#').map(it => it.getFullUrl()));
                        }, item.single);
                      }
                    }
                  }, {
                    targets: [columnNo++], data: 'nickname', orderable: true,
                    createdCell: (td, value, item) => {
                      $(td).nickname_label(item.nickname, item.gender, () => onCell(item.id));
                    }
                  }, {
                    targets: [columnNo++], data: 'birth', orderable: true,
                    render: value => value.ageFormat()
                  }, {
                    targets: [columnNo++], data: 'active', orderable: true,
                    createdCell: (td, value, item) => {
                      $(td).html('<input type="checkbox"' + (value == STATUS.NORMAL ? ' checked ' : '') + ' />')
                        .find('input')
                        .on('change', () => onToggleActive(item.id));
                    }
                  }, {
                    targets: [columnNo++], data: 'level_type', orderable: true,
                    render: value => strings.member.level_type_list[value]
                  }, {
                    targets: [columnNo++], data: 'profile_public', orderable: true,
                    render: value => value == STATUS.YES ? strings.lounge.public : strings.lounge.private
                  }, {
                    targets: [columnNo++], data: 'location', orderable: false,
                  }/*, {
                    targets: [columnNo++], data: 'character', orderable: false,
                    render: value => value.split(',').sort().map(it => context.getSettingName(it, 'personality')).join(', ')
                  }*/, {
                    targets: [columnNo++], data: 'job', orderable: false,
                  }/*, {
                    targets: [columnNo++], data: 'height', orderable: false,
                  }*/, {
                    targets: [columnNo++], data: 'male_necessity', orderable: false,
                    createdCell: (td, value, item) => {
                      $(td).html(getItem(value, item, 'male'));
                    },
                  }, {
                    targets: [columnNo++], data: 'male_favor1', orderable: false,
                    createdCell: (td, value, item) => {
                      $(td).html(getItem(value, item.best_favor1));
                    },
                  }, {
                    targets: [columnNo++], data: 'male_favor2', orderable: false,
                    createdCell: (td, value, item) => {
                      $(td).html(getItem(value, item.best_favor2));
                    },
                  }, {
                    targets: [columnNo++], data: 'female_necessity', orderable: false,
                    createdCell: (td, value, item) => {
                      $(td).html(getItem(value, item, 'female'));
                    },
                  }, {
                    targets: [columnNo++], data: 'female_favor1', orderable: false,
                    createdCell: (td, value) => {
                      $(td).html(getItem(value, user?.best_favor1));
                    },
                  }, {
                    targets: [columnNo++], data: 'female_favor2', orderable: false,
                    createdCell: (td, value) => {
                      $(td).html(getItem(value, user?.best_favor2));
                    },
                  }, {
                    targets: [columnNo++], data: 'card_all', orderable: true,
                    render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
                  }, {
                    targets: [columnNo++], data: 'card_review', orderable: true,
                    render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
                  }, {
                    targets: [columnNo++], data: 'card_final_confirm', orderable: true,
                    render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
                  }, {
                    targets: [columnNo++], data: 'card_on_going', orderable: true,
                    render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
                  }, {
                    targets: [columnNo++], data: 'id', orderable: false,
                    createdCell: (td, value, item) => {
                      $(td).html(`
                          <button type='button' class='btn btn-success btn-sm m-2'>
                            ${user.gender == GENDER.FEMALE ? strings.match.match : strings.match.suggest}
                          </button>
                        `)
                        .find('button:eq(0)')
                        .on('click', () => showPopup(item.id));
                    }
                  }],
                  order: [[1, 'DESC']],
                  ajax: {
                    url: 'match/user/list',
                    data: (req) => {
                      req.status = 1;
                      req.user = user.id;
                      req.active_user = $('#activePopupUser').is(':checked') ? 1 : 0;
                      req.gender = user.gender;
                      req.age_from = $('#ageFrom').val();
                      req.age_to = $('#ageTo').val();
                      req.column = $('#memberColumn').val();
                      req.keyword = $('#memberInput').val();
                      req.user_type = JSON.stringify(locationRef.current?.getSelected());
                    }
                  },
                }}>
                <thead>
                <tr>
                  <th>#</th>
                  <th>{strings.member.id}</th>
                  <th>{strings.member.profile}</th>
                  <th>{strings.common.nickname}</th>
                  <th>{strings.member.age}</th>
                  <th>{strings.match.active_user}</th>
                  <th>{strings.member.level_type}</th>
                  <th>{strings.member.photo_public}</th>
                  <th>{strings.member.location}</th>
                  {/*<th style={{ width: '30%' }}>{strings.member.character}</th>*/}
                  <th>{strings.member.job}</th>
                  {/*<th>{strings.member.height}</th>*/}
                  <th>{strings.match.male_necessity.nl2br()}</th>
                  <th>{strings.match.male_favor1.nl2br()}</th>
                  <th>{strings.match.male_favor2.nl2br()}</th>
                  <th>{strings.match.female_necessity.nl2br()}</th>
                  <th>{strings.match.female_favor1.nl2br()}</th>
                  <th>{strings.match.female_favor2.nl2br()}</th>
                  <th>{strings.member.card_all}</th>
                  <th>{strings.member.card_review}</th>
                  <th>{strings.member.card_final_confirm}</th>
                  <th>{strings.member.card_on_going}</th>
                  <th>{strings.common.manage}</th>
                </tr>
                </thead>
                <tbody />
              </DataTable>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        </ModalFooter>
      </Modal>
      <MatchDescPopup
        {...popup}
        gender={user?.gender ?? GENDER.FEMALE}
        onClose={hidePopup}
        onSave={onMatch}
        doReservation={onAddReservation}
      />
    </>
  );
};

export default MatchPopup;
