import { useContext, useRef } from "react";
import $ from "jquery";

import strings from "../../../lang/strings";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import { PageSettings } from "../../../config/page-settings";
import { STATUS } from "../../../config/const";
import { inject } from "mobx-react";

const MemberDetailPopupFamiliar = ({ user, rootStore }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.comment;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onUser = user_id => {
    // context.showUserPopup(user_id, () => refreshTable(false));
  }

  const onDelete = id => {
    if (!rootStore.isSuper) {
      return;
    }

    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'member/recommend/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='recommendType'
            value={0}
            data={[
              { value: 0, name: strings.common.total },
              { value: 1, name: strings.member.recommend_receive },
              { value: 2, name: strings.member.recommend_send },
            ]}
            onChange={() => refreshTable(true)}/>
          <SearchSelect
            id='recommendColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)}/>
          <SearchInput
            id='recommendInput'
            action={() => refreshTable(true)}/>
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'user_profile', orderable: false,
              createdCell: (td, value, item) => {
                $(td).image_lightbox(value, () => {
                  context.showGallery(value.split('#').map(it => it.getFullUrl()));
                }, item.user_single);
              }
            }, {
              targets: [columnNo++], data: 'user_nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(
                  item.user_nickname,
                  item.user_gender,
                  () => onUser(item.user_id, () => refreshTable(false))
                );
              }
            }, {
              targets: [columnNo++], data: null, orderable: false,
              createdCell: (td, value, item) => {
                if (item.writer == user.id) {
                  $(td).html(`<span class="text-success">${strings.member.recommend_send}</span>`);
                } else {
                  $(td).html(`<span class="text-indigo">${strings.member.recommend_receive}</span>`);
                }
              }
            }, {
              targets: [columnNo++], data: 'content', orderable: false,
              render: value => value.$nl2br()
            }, {
              targets: [columnNo++], data: 'display', orderable: false,
              render: value => value == STATUS.YES ? strings.member.recommend_display : '-'
            }, {
              targets: [columnNo++], data: 'status', orderable: false,
              render: value => value == STATUS.PENDING ?
                `<span class="text-danger">${strings.member.recommend_pending}</span>` :
                `<span class="text-info">${strings.member.recommend_write}</span>`
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
              render: value => value.dateFormat()
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value) => {
                $(td).html('');
                if (!rootStore.isSuper) {
                  return;
                }
                $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm text-nowrap'>${strings.common.delete}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onDelete(value));
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'member/recommend_log',
              data: (req) => {
                req.user = user.id;
                req.type = $('#recommendType').val();
                req.column = $('#recommendColumn').val();
                req.keyword = $('#recommendInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.member.profile}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.member.recommend_type}</th>
            <th style={{ width: '30%' }}>{strings.common.content}</th>
            <th>{strings.member.display_status}</th>
            <th>{strings.common.status}</th>
            <th>{strings.common.create_date}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody/>
        </DataTable>
      </div>
    </div>
  );
};

export default inject('rootStore')(MemberDetailPopupFamiliar);
