import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { GENDER } from "../../../config/const";

const AliasPopup = ({ isOpen, info, onClose, onSave }) => {

  const context = useContext(PageSettings);
  const [item, setItem] = useState(null);

  const getInfo = () => {
    if (!info?.id) {
      setItem(info);
      return;
    }

    context.get(
      `setting/alias/${info?.id}`,
      {},
      res => {
        setItem(res);
      }
    );
  }

  const onApply = () => {
    if (!item?.name) {
      context.addNotification(
        strings.modal.alert,
        strings.formatString(strings.alias.empty_name, title),
        'warning'
      );
      return;
    }

    context.post(
      'setting/alias',
      {
        id: item?.id || 0,
        name: item?.name,
        type: item?.type,
        gender: item?.gender
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        onSave?.();
        setItem(info);
      }
    );
  }

  let title = item?.type === 1 ? strings.alias.adjective : strings.alias.dessert;
  if (item?.gender) {
    title += ` (${item?.gender === GENDER.MALE ? strings.common.male : strings.common.female})`;
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} onOpened={getInfo}>
      <ModalHeader className="popup-nickname" toggle={onClose}>
        {title} {item?.id ? strings.common.edit : strings.common.add}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xs-12">
            <div className='row'>
              <label className='col-md-3 col-form-label'>{title} *</label>
              <div className="col-md-9">
                <input
                  type='text' autoComplete='off' className='form-control'
                  value={item?.name || ''}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      onApply();
                    }
                  }}
                  onChange={e => setItem({ ...item, name: e.target.value })}/>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={onClose}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onApply}>{strings.common.save}</button>
      </ModalFooter>
    </Modal>
  );
};

export default AliasPopup;
