import { useContext, useEffect, useState } from 'react';

import Container from '../../components/control/container';
import { PageSettings } from '../../config/page-settings';
import strings from '../../lang/strings';

const IapSta = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState({});
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    context.get(
      'iap/sta',
      {},
      response => {
        setData(response);
      }
    )
  }

  return (
    <Container title={strings.menu.iap_sta}>
      <div style={{ height: "150px" }}>
        <p className="p-0 m-0">{strings.iap.sta_01}: {data.normal_today?.currencyFormat() ?? '-'} {strings.iap.won}</p>
        <p className="p-0 m-0">{strings.iap.sta_02}: {data.normal_yesterday?.currencyFormat() ?? '-'} {strings.iap.won}</p>
        <p className="p-0 m-0">{strings.iap.sta_03}: {data.normal_this_week?.currencyFormat() ?? '-'} {strings.iap.won}</p>
        <p className="p-0 m-0">{strings.iap.sta_04}: {data.normal_this_month?.currencyFormat() ?? '-'} {strings.iap.won}</p>
        <p className="p-0 m-0">{strings.iap.sta_05}: {data.refund_today?.currencyFormat() ?? '-'} {strings.iap.won}</p>
        <p className="p-0 m-0">{strings.iap.sta_06}: {data.refund_yesterday?.currencyFormat() ?? '-'} {strings.iap.won}</p>
        <p className="p-0 m-0">{strings.iap.sta_07}: {data.refund_this_week?.currencyFormat() ?? '-'} {strings.iap.won}</p>
        <p className="p-0 m-0">{strings.iap.sta_08}: {data.refund_this_month?.currencyFormat() ?? '-'} {strings.iap.won}</p>
      </div>
    </Container>
  );

}

export default IapSta;
