import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';

const AuStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/au',
      {},
      response => setData(response)
    );
  }, []);

  return (
    <div>
      <div className="widget widget-stats bg-black-lighter height-100">
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>DAU, MAU</div>
            <div className="ml-auto"> </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>DAU</div>
            <div className="ml-auto">{data?.dau.currencyFormat() ?? "-"}</div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>MAU</div>
            <div className="ml-auto">{data?.mau.currencyFormat() ?? "-"}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuStaFragment;
