import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const IapStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/iap',
      {},
      response => setData(response)
    );
  }, []);

  return (
    <div>
      <div className="widget widget-stats bg-red height-100">
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.today} {strings.dashboard.sales}</div>
            <div className="ml-auto">{(data?.today.currencyFormat() + strings.iap.won) ?? '-'}</div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_week} {strings.dashboard.sales}</div>
            <div className="ml-auto">{(data?.this_week.currencyFormat() + strings.iap.won) ?? '-'}</div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_month} {strings.dashboard.sales}</div>
            <div className="ml-auto">{(data?.this_month.currencyFormat() + strings.iap.won) ?? '-'}</div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div className=''>{strings.common.total} {strings.dashboard.sales}</div>
            <div className="ml-auto">{(data?.total.currencyFormat() + strings.iap.won) ?? '-'}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IapStaFragment;
