import React, { useContext, useRef, useState } from "react";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import SearchSelect from "../../../components/control/search-select";
import { STATUS } from "../../../config/const";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import PenaltyPopup, { PenaltyType } from "./penalty-popup";

const LoungePopupComment = ({ id }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.comment;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onUser = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'lounge/comment/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  const [penalty, setPenaltyPopup] = useState({
    isOpen: false,
    type: PenaltyType.Lounge,
    user: null
  });
  const hidePenaltyPopup = () => setPenaltyPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });
  const onPenalty = item => {
    setPenaltyPopup(prev => {
      return {
        ...prev,
        isOpen: true,
        user: { id: item.user_id, nickname: item.user_nickname }
      }
    });
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='commentColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)}/>
          <SearchInput
            id='commentInput'
            action={() => refreshTable(true)}/>
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'user_nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(item.user_nickname, item.user_gender, () => onUser(item.user_id));
              }
            }, {
              targets: [columnNo++], data: 'content', orderable: false,
            }, {
              targets: [columnNo++], data: 'blame_cnt', orderable: false,
              render: value => value.currencyFormat()
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
              render: value => value.dateFormat()
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                if (item.status == STATUS.NORMAL) {
                  $(td)
                    .html(`<button type='button' class='btn btn-outline-danger btn-sm p-t-1 p-b-1 text-nowrap'>${strings.common.delete}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDelete(item.id))
                    .end()
                    .append(`<button type='button' class='btn btn-outline-dark btn-sm p-t-1 p-b-1 m-2 text-nowrap'>${strings.lounge.penalty}</button>`)
                    .find('button:eq(1)')
                    .on('click', () => onPenalty(item));
                } else {
                  $(td).html(`<label class="text-danger control-label">${strings.common.deleted}</label>`);
                }
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'lounge/comment/list',
              data: (req) => {
                req.lounge = id;
                req.column = $('#commentColumn').val();
                req.keyword = $('#commentInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.common.nickname}</th>
            <th style={{ width: '30%' }}>{strings.common.content}</th>
            <th>{strings.lounge.blame_cnt}</th>
            <th>{strings.common.create_date}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody/>
        </DataTable>
      </div>
      <PenaltyPopup {...penalty} onClose={() => {
        hidePenaltyPopup();
      }} />
    </div>
  );
};

export default LoungePopupComment;
