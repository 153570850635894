import $ from "jquery";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import React, { useContext, useEffect, useRef, useState } from "react";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import MatchDetailPopup from "../popup/detail-popup";
import { CURATOR_STATUS, GENDER, PARTNER } from "../../../config/const";
import MatchDescPopup from "../popup/match-desc-popup";
import { inject } from "mobx-react";

export const MATCH_TYPE = {
  전체: 'all',
  큐레이터: 'curator',
  시도: 'try',
  진행중: 'going',
  여성수락: 'female_approve',
  남성수락: 'male_approve',
  최종수락: 'final_approve',
  만남일결정: 'date_approve',
  만남일당일: 'date_today',
  후기단계: 'review_step',
  후기완료: 'review_finish',
  캔슬내역: 'cancel',
  채팅내역_3일간: 'chat',
};

const MatchListFragment = ({ id = 0, type = MATCH_TYPE.전체, rootStore }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [unreadCardsCnt, setUnreadCardsCnt] = useState(0);
  const searchOptions = strings.search_option.member;
  const matchStatus = strings.search_option.match;

  const matchStatusRef = useRef(matchStatus[0].value);
  const searchColumnRef = useRef(searchOptions[0].value);
  const searchInputRef = useRef('');

  useEffect(() => {
    if (type == MATCH_TYPE.전체) {
      getUnreadCardsCnt()
    }
  }, []);

  const getUnreadCardsCnt = () => {
    context.get(
      'match/unread-cards-cnt',
      {},
      response => {
        setUnreadCardsCnt(response);
      }
    );
  }

  const cleanUnreadCards = () => {
    context.showConfirm("사용되지 않은 카드들이 모두 삭제됩니다.", res => {
      if (res !== true) {
        return;
      }
      context.post(
        'match/clean-unread-cards',
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          getUnreadCardsCnt()
          refreshTable(false);
        }
      )
    });
  }

  const onUser = user_id => {
    const showUserPopup = () => context.showUserPopup(user_id, () => refreshTable(false));

    if (rootStore.isSuper) {
      showUserPopup();
      return;
    }

    // 해당 유저가 파트너의 전담/공유회원인지를 체크
    context.get(
      'member/partner_type',
      {
        user: user_id
      },
      response => {
        if (response != PARTNER.NONE) {
          showUserPopup();
        }
      },
      false
    );
  }

  const [popup, togglePopup] = useState({
    isOpen: false,
    id: 0
  });
  const showDetailPopup = id => togglePopup({ isOpen: true, id });
  const hideDetailPopup = () => togglePopup({ isOpen: false, id: 0 });

  const [matchPopup, setMatchPopup] = useState({
    isOpen: false,
    match: 0
  });
  const hideMatchPopup = () => setMatchPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });
  const showMatchPopup = id => setMatchPopup(prev => {
    return {
      ...prev,
      isOpen: true,
      match: id
    };
  });

  const deleteMatch = id => {
    context.showConfirm(strings.match.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `match/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  const denyCurator = id => {
    context.showConfirm(strings.match.deny_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        `match/curator/deny`,
        { id },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  }

  const onMatch = desc => {
    hideMatchPopup();
    context.post(
      'match/curator/match',
      {
        id: matchPopup.match,
        desc
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.applied);
        refreshTable(false);
      }
    );
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            {
              type == MATCH_TYPE.전체 &&
              <SearchSelect
                id='matchStatus'
                value={matchStatus[0].value}
                data={matchStatus}
                onChange={v => {
                  matchStatusRef.current = v;
                  refreshTable(true);
                }} />
            }
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={v => {
                searchColumnRef.current = v;
                refreshTable(true);
              }} />
            <SearchInput
              id='searchInput'
              onChange={v => searchInputRef.current = v}
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
              onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        {type == MATCH_TYPE.전체 &&
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10, alignItems: "center" }}>
            낭비된 카드 : {unreadCardsCnt} 장
            <button type='button' className='btn btn-danger btn-sm'
              style={{ zIndex: 100, marginLeft: 20 }}
              onClick={() => {
                cleanUnreadCards();
              }}>전체삭제</button>
          </div>
        }
        <div className='col-md-12'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'male_profile', orderable: false,
                createdCell: (td, value, item) => {
                  if (!value) {
                    $(td).html('');
                  } else {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    }, item.male_single);
                  }
                }
              }, {
                targets: [columnNo++], data: 'male_nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(
                    item.male_nickname,
                    item.male_gender,
                    () => onUser(item.male_id)
                  );
                }
              }, {
                targets: [columnNo++], data: 'male_birth', orderable: false,
                render: value => value.ageFormat()
              }, {
                targets: [columnNo++], data: 'male_job', orderable: false,
              }, {
                targets: [columnNo++], data: 'female_profile', orderable: false,
                createdCell: (td, value, item) => {
                  if (!value) {
                    $(td).html('');
                  } else {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    }, item.female_single);
                  }
                }
              }, {
                targets: [columnNo++], data: 'female_nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(
                    item.female_nickname,
                    item.female_gender,
                    () => onUser(item.female_id)
                  );
                }
              }, {
                targets: [columnNo++], data: 'female_birth', orderable: false,
                render: value => value.ageFormat()
              }, {
                targets: [columnNo++], data: 'female_job', orderable: false,
              }, {
                targets: [columnNo++], data: 'match_status_date', orderable: false,
                render: value => value.dateFormat()
              }, {
                targets: [columnNo++], data: 'curator_status', orderable: false, visible: type == MATCH_TYPE.큐레이터,
                createdCell: (td, value) => {
                  switch (value) {
                    case CURATOR_STATUS.ADMIN_DENY:
                      $(td).html(`<label class="text-danger mb-0">${strings.match.admin_deny}</label>`);
                      break;
                    case CURATOR_STATUS.MALE_PASS:
                      $(td).html(`<label class="text-gray mb-0">${strings.match.match_path.pass}</label>`);
                      break;
                    case CURATOR_STATUS.PENDING:
                      $(td).html(`<label class="text-dark mb-0">${strings.match.not_check}</label>`);
                      break;
                    case CURATOR_STATUS.MALE_WANT_SEE:
                      $(td).html(`<label class="text-primary mb-0">${strings.match.want_see}</label>`);
                      break;
                    case CURATOR_STATUS.FROM_CURATOR:
                      $(td).html(`<label class="text-primary mb-0">-</label>`);
                      break;
                    default:
                  }
                }
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  let btnIndex = 0;
                  $(td).html(``);
                  if (type == MATCH_TYPE.큐레이터) {
                    if (item.curator_status == CURATOR_STATUS.MALE_WANT_SEE) {
                      $(td).append(`<button type='button' class='btn btn-gray btn-sm m-2'>${strings.match.admin_deny}</button>`)
                        .find(`button:eq(${btnIndex++})`)
                        .on('click', () => denyCurator(value));
                      $(td).append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.match.match}</button>`)
                        .find(`button:eq(${btnIndex++})`)
                        .on('click', () => showMatchPopup(value));
                    }
                  }
                  $(td).append(`<button type='button' class='btn btn-success btn-sm m-2'>${strings.support.view}</button>`)
                    .find(`button:eq(${btnIndex++})`)
                    .on('click', () => showDetailPopup(value));
                  if (rootStore.isSuper && (type == MATCH_TYPE.전체 || type == MATCH_TYPE.큐레이터)) {
                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button>`)
                      .find(`button:eq(${btnIndex++})`)
                      .on('click', () => deleteMatch(value));
                  }
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'match/list',
                data: (req) => {
                  req.user = id;
                  req.type = type;
                  req.match_status = matchStatusRef.current; // $('#matchStatus').val();
                  req.column = searchColumnRef.current; // $('#searchColumn').val();
                  req.keyword = searchInputRef.current; // $('#searchInput').val();
                }
              },
            }}>
            <thead>
              <tr>
                <th rowSpan={2}>#</th>
                <th colSpan={4}>{strings.member.male_member}</th>
                <th colSpan={4}>{strings.member.female_member}</th>
                <th rowSpan={2}>{strings.common.create_date}</th>
                <th rowSpan={2} style={{ display: type == MATCH_TYPE.큐레이터 ? 'table-cell' : 'none' }}>
                  {strings.match.male_check}
                </th>
                <th rowSpan={2}>{strings.common.manage}</th>
              </tr>
              <tr>
                <th>{strings.member.profile}</th>
                <th>{strings.common.nickname}</th>
                <th>{strings.member.age}</th>
                <th>{strings.member.job}</th>
                <th>{strings.member.profile}</th>
                <th>{strings.common.nickname}</th>
                <th>{strings.member.age}</th>
                <th>{strings.member.job}</th>
              </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <MatchDetailPopup
        {...popup}
        onClose={hideDetailPopup} />
      <MatchDescPopup
        {...matchPopup}
        gender={GENDER.FEMALE}
        onClose={hideMatchPopup}
        onSave={onMatch}
      />
    </>
  );
}

export default inject('rootStore')(MatchListFragment);
