import $ from 'jquery';
import { useContext, useEffect, useRef } from 'react';

import DataTable from '../../../components/control/data-table';
import SearchInput from '../../../components/control/search-input';
import SearchSelect from '../../../components/control/search-select';
import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const FreeKeyLogFragment = () => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const searchOptions = strings.search_option.member;

  useEffect(() => {
    $('#searchColumn').val(searchOptions[0].value);
    $('#searchInput').val('');

    refreshTable(true);
  }, []);

  const onUser = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='searchColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)} />
          <SearchInput
            id='searchInput'
            action={() => refreshTable(true)} />
          <button type='button' className='btn btn-success btn-sm ml-md-2'
            onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'key_id', orderable: false,
            }, {
              targets: [columnNo++], data: 'email', orderable: false,
            }, {
              targets: [columnNo++], data: 'profile', orderable: false,
              createdCell: (td, value, item) => {
                if (!value) {
                  $(td).image_lightbox(item.admin_profile, () => {
                    context.showGallery([item.admin_profile.getFullUrl()]);
                  }, item.single);
                } else {
                  $(td).image_lightbox(value, () => {
                    context.showGallery(value.split('#').map(it => it.getFullUrl()));
                  }, item.single);
                }
              }
            }, {
              targets: [columnNo++], data: 'nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(item.nickname, item.gender, () => onUser(item.id));
              }
            }, {
              targets: [columnNo++], data: 'status', orderable: false,
              createdCell: (td, value, item) => {
                $(td).user_status(item);
              }
            }, {
              targets: [columnNo++], data: 'birth', orderable: false,
              render: value => value.ageFormat()
            }, {
              targets: [columnNo++], data: 'phone', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(item.phone + '<br/>' + item.name);
              }
            }, {
              targets: [columnNo++], data: 'location', orderable: false,
            }, {
              targets: [columnNo++], data: 'job', orderable: false,
            }, {
              targets: [columnNo++], data: 'key', orderable: false,
              render: value => value.currencyFormat()
            }, {
              targets: [columnNo++], data: 'free_key', orderable: false,
              render: value => value.currencyFormat()
            }, {
              targets: [columnNo++], data: 'key_content', orderable: false,
            }, {
              targets: [columnNo++], data: 'key_date', orderable: false,
              render: value => value.dateFormat()
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'iap/free-key-log',
              data: (req) => {
                req.column = $('#searchColumn').val();
                req.keyword = $('#searchInput').val();
              }
            },
          }}>
          <thead>
            <tr>
              <th>#</th>
              <th>{strings.member.id}</th>
              <th>{strings.member.profile}</th>
              <th>{strings.common.nickname}</th>
              <th>{strings.common.status}</th>
              <th>{strings.member.age}</th>
              <th>{strings.member.phone}</th>
              <th>{strings.member.location}</th>
              <th>{strings.member.job}</th>
              <th>{strings.member.key}</th>
              <th>{strings.iap.free_key}</th>
              <th>{strings.iap.free_key_reason}</th>
              <th>{strings.iap.free_key_date}</th>
            </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );
}

export default FreeKeyLogFragment;
