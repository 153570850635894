import React, { useContext, useEffect } from "react";
import { PageSettings } from "../../config/page-settings";
import $ from 'jquery';
import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import strings from "../../lang/strings";

const LocationTable = React.forwardRef((props, ref) => {
  const {
    clsName = 'table table-bordered',
  } = props;

  const context = useContext(PageSettings);

  let areaList = [];
  for (let area of context.getSettingList('resident')) {
    if (!areaList.includes(area.group_name)) {
      areaList.push(area.group_name);
    }
  }

  const toggleAll = e => {
    $("#tblLocation input[type=checkbox]:not('.all')").prop('checked', e.target.checked);
  }

  const toggleRow = (row, checked) => {
    $(`#tblLocation tbody tr:eq(${row}) input[type=checkbox]:not(".level")`).prop('checked', checked);
  }

  const toggleColumn = (col, checked) => {
    $(`input[type=checkbox]:eq(${col + 1})`, '#tblLocation tbody tr').prop('checked', checked);
  }

  const toggleCell = (row, col) => {
    $('#tblLocation input[type=checkbox].all').prop(
      'checked',
      $('#tblLocation tbody input[type=checkbox].cell:not(:checked)').length < 1
    );
    $(`#tblLocation tbody tr:eq(${row}) input[type=checkbox].level`).prop(
      'checked',
      $(`#tblLocation tbody tr:eq(${row}) input[type=checkbox]:not('.level'):not(:checked)`).length < 1
    );
    $(`#tblLocation thead tr th:eq(${col + 1}) input[type=checkbox]`).prop(
      'checked',
      $(`td:eq(${col + 1}) input[type=checkbox]:not(:checked)`, '#tblLocation tbody tr').length < 1
    );
  }

  const getSelected = () => {
    let result = [];

    $('#tblLocation tbody input[type=checkbox].cell:checked').each((_, input) => {
      const value = $(input).val().split(' ');
      result.push({
        level_type: value[0],
        group_name: areaList[value[1]]
      });
    });

    return result;
  }

  useEffect(() => {
    ref.current = {
      getSelected: getSelected
    };
  }, [areaList]);

  return (
    <table id='tblLocation' className={clsName}>
      <thead>
      <tr>
        <th>
          <label className='control-label'>
            &nbsp;<input type='checkbox' className='all' onChange={toggleAll} />&nbsp;
          </label>
        </th>
        {areaList.map((area, col) => (
          <th key={col}>
            <label className='control-label'>
              {area}&nbsp;&nbsp;
              <input type='checkbox' className='area' onChange={e => toggleColumn(col, e.target.checked)} />
            </label>
          </th>
        ))}
      </tr>
      </thead>
      <tbody>
      {
        Object.keys(strings.member.level_type_list).map((key, row) => (
          <tr key={key}>
            <td>
              <label className='control-label'>
                {strings.member.level_type_list[key]}&nbsp;&nbsp;
                <input type='checkbox' className='level' onChange={e => toggleRow(row, e.target.checked)} />
              </label>
            </td>
            {
              areaList.map((area, col) => (
                <td key={col}>
                  <label className='control-label'>
                    &nbsp;<input type='checkbox' className='cell' value={`${key} ${col}`}
                                 onChange={() => toggleCell(row, col)} />&nbsp;
                  </label>
                </td>
              ))
            }
          </tr>
        ))
      }
      </tbody>
    </table>
  );
})

export default LocationTable;
