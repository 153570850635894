import { Component, createRef } from "react";
import DateTime from 'react-datetime';
import moment from "moment";
import $ from 'jquery';

import strings from "../../lang/strings";
import Container from "../../components/control/container";
import DataTable from "../../components/control/data-table";
import { FORMAT } from "../../config/const";

class StaHourly extends Component {

  constructor(props) {
    super(props);

    this.tableRef = createRef();
    this.state = {
      type: 0,
      date_from: '',
      date_to: ''
    }
  }

  refreshTable = refresh => {
    this.tableRef?.current.draw(refresh);
  }

  changeViewMode = (type) => {
    this.setState({
      type: type
    }, () => {
      this.refreshTable(true);
    });
  }

  render() {
    const self = this;
    let columnNo = 2;
    let columnDefs = [...Array(24).keys()].map((item, index) => {
      return {
        targets: [columnNo++], data: 'h' + index, orderable: false,
        render: function (value) {
          return Number(value) > 0 ? value.currencyFormat() : '<span style="color: grey">-</span>';
        }
      }
    });

    return (
      <Container title={strings.menu.sta_hourly}>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form-horizontal form-inline'>
              <div className="btn-group">
                <button
                  className={'btn btn-sm ' + (this.state.type === 0 ? 'btn-success' : 'btn-white')}
                  onClick={() => this.changeViewMode(0)}>{strings.sta.daily}</button>
                <button
                  className={'btn btn-sm ' + (this.state.type === 1 ? 'btn-success' : 'btn-white')}
                  onClick={() => this.changeViewMode(1)}>{strings.sta.monthly}</button>
              </div>
              <DateTime
                dateFormat={FORMAT.DATE}
                timeFormat={false}
                inputProps={{ placeholder: strings.sta.select_start_time }}
                defaultValue={this.state.date_from}
                closeOnSelect={true}
                className="ml-md-2"
                onChange={(e) => {
                  if (typeof e === 'string') {
                    this.setState({ date_from: e });
                  } else {
                    this.setState({ date_from: e.format(FORMAT.DATE) });
                  }
                }}/>
              <DateTime
                dateFormat={FORMAT.DATE}
                timeFormat={false}
                inputProps={{ placeholder: strings.sta.select_end_time }}
                defaultValue={this.state.date_to}
                closeOnSelect={true}
                className="ml-md-1"
                onChange={(e) => {
                  if (typeof e === 'string') {
                    this.setState({ date_to: e });
                  } else {
                    this.setState({ date_to: e.format(FORMAT.DATE) });
                  }
                }}/>
              <button
                type='button' className='btn btn-success btn-sm ml-md-2'
                onClick={() => this.refreshTable(true)}>
                {strings.common.search}
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-12 mt-3'>
            <DataTable
              ref={this.tableRef}
              options={{
                columnDefs: [{
                  targets: [0], data: 'date', orderable: false,
                  render: (value) => {
                    if (self.state.type === 1) {
                      return value;
                    } else {
                      const week_idx = moment(value).weekday();
                      const week_color = week_idx == 0 ? 'red' : week_idx == 6 ? 'blue' : '';
                      return value + ` <span style="color: ${week_color}">(${strings.sta.week_list[week_idx]})</span>`;
                    }
                  }
                }, {
                  targets: [1], data: null, orderable: false,
                  createdCell: (td, value, item) => {
                    $(td).css('background-color', '#DAE3F380');

                    let total_count = 0;
                    [...Array(24).keys()].forEach((it, idx) => {
                      total_count += Number(item['h' + idx]);
                    })
                    total_count = Math.round(total_count / 24 * 100) / 100;
                    $(td).html(total_count.currencyFormat());
                  }
                }].concat(columnDefs),
                order: [[1, 'DESC']],
                ajax: {
                  url: 'sta/login',
                  data: (req) => {
                    req.date_from = self.state.date_from;
                    req.date_to = self.state.date_to;
                    req.is_monthly = self.state.type;
                  }
                },
              }}>
              <thead>
              <tr>
                <th width="10%">{strings.sta.date}</th>
                <th>{strings.sta.hour_avg}</th>
                {
                  [...Array(24).keys()].map((item, index) => (
                    <th key={index}>{strings.formatString(strings.sta.hour_format, index)}</th>
                  ))
                }
              </tr>
              </thead>
              <tbody/>
            </DataTable>
          </div>
        </div>
      </Container>
    );
  }
}

export default StaHourly;
