import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { STATUS } from "../../../config/const";
import ImageSelect from "../../../components/control/image-select";

const NoticePopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);
  const [file, setFile] = useState(null);

  const getInfo = () => {
    if (id < 1) {
      setInfo(null);
      return;
    }

    context.get(
      `setting/notice/${id}`,
      {},
      res => {
        setInfo(res);
      }
    );
  }

  const onImageChange = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setInfo({
        ...info,
        image: reader.result
      });
      setFile(file);
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = () => {
    if (file != null) {
      setFile(null);
    }

    setInfo({
      ...info,
      image: ''
    });
  };

  const uploadImage = () => {
    if (file == null) {
      return '';
    }

    return new Promise(resolve => {
      let formData = new FormData();
      formData.append('file', file, file.name);

      context.post(
        'upload/image/notice',
        formData,
        response => {
          resolve(response.image);
        }
      );
    });
  }

  const onSave = e => {
    e.preventDefault();

    if (!info?.content && !info?.image && file == null) {
      context.showAlert(strings.notice.input_require);
      return;
    }

    context.showConfirm(strings.modal.save_confirm, async res => {
      if (res !== true) {
        return;
      }

      let image = '';
      if (file != null) {
        image = await uploadImage();
      }

      context.post(
        'setting/notice',
        {
          id: info?.id || 0,
          title: info?.title,
          content: info?.content || '',
          image,
          status: info?.status ?? STATUS.NORMAL,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          onClose(true);
        }
      );
    });
  }

  return (
    <Modal size={'lg'} isOpen={isOpen} toggle={onClose} onOpened={getInfo}>
      <form onSubmit={onSave}>
        <ModalHeader className="popup-nickname" toggle={onClose}>
          {strings.notice.detail}
        </ModalHeader>
        <ModalBody>
          <div className='row'>
            <label className='col-xs-2 col-form-label'>{strings.common.title} *</label>
            <div className="col-xs-9">
              <input type='text' autoComplete='off' className='form-control'
                     value={info?.title || ''} required
                     onChange={e => setInfo({ ...info, title: e.target.value })} />
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.content}</label>
            <div className='col-xs-9'>
              <textarea
                className='form-control'
                rows={20}
                value={info?.content || ''}
                onChange={e => setInfo({ ...info, content: e.target.value })} />
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.image}</label>
            <div className='col-xs-9'>
              <ImageSelect
                width='20%'
                height='20%'
                image_url={info?.image?.getFullUrl()}
                onSelect={onImageChange}
                onDelete={onImageDelete} />
            </div>
          </div>
          <div className='row form-group mt-2 align-items-center'>
            <label className='col-xs-2 col-form-label'>{strings.common.show}/{strings.common.hide}</label>
            <div className='col-xs-9 form-inline'>
              <label className='control-label'
                     onClick={() => setInfo({ ...info, status: STATUS.NORMAL })}>
                <input className='' type='radio' name='status'
                       checked={(info?.status ?? STATUS.NORMAL) == STATUS.NORMAL}
                       readOnly
                       value={STATUS.NORMAL} />&nbsp;{strings.common.show}
              </label>
              <label className='control-label m-l-10'
                     onClick={() => setInfo({ ...info, status: STATUS.PENDING })}>
                <input className='' type='radio' name='status'
                       checked={info?.status == STATUS.PENDING}
                       readOnly
                       value={STATUS.PENDING} />&nbsp;{strings.common.hide}
              </label>
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.create_date}</label>
            <label className='col-xs-9 col-form-label'>{info?.create_date || '-'}</label>
          </div>
          {/*<div className='row'>
            <label className='col-md-2 col-form-label'>{strings.image} ({strings.optinoal})</label>
            <div className="col-xs-20">
              <div className='row mb-1 mt-1'>
                {
                  this.state.image_list_urls.map((image_url, idx) => (
                    <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3' key={idx}>
                      <ImageSelect
                        height="70%"
                        image_url={image_url}
                        onSelect={(e) => this.onImageChange(e, idx)}
                        onDelete={() => {
                          this.state.image_list_urls.splice(idx, 1);
                          this.image_list_files.splice(idx, 1);
                          this.setState({ image_list_urls: this.state.image_list_urls });
                        }}/>
                    </div>
                  ))
                }

                <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3'>
                  <ImageSelect
                    height="70%"
                    onSelect={(e) => this.onImageChange(e, this.state.image_list_urls.length)}
                    onDelete={() => {
                    }}/>
                </div>
              </div>
            </div>
          </div>*/
          }
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" type='button' onClick={onClose}>{strings.modal.close}</button>
          <button className="btn btn-success" type='submit'>{strings.common.save}</button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default NoticePopup;
