import React, { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../../config/page-settings";
import { STATUS, USER_STATUS } from "../../../config/const";
import strings from "../../../lang/strings";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";

const MeetingPopupReportUser = ({ id, onBlock, onPenalty }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.comment;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onUser = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  const onDone = id => {
    context.post(
      'meeting/blame/member_done',
      { id },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.applied);
        refreshTable(false);
      }
    )
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='reportUserColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)}/>
          <SearchInput
            id='reportUserInput'
            action={() => refreshTable(true)}/>
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'user_id', orderable: false,
            }, {
              targets: [columnNo++], data: 'user_nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(item.user_nickname, item.user_gender, () => onUser(item.user_id));
              }
            }, {
              targets: [columnNo++], data: 'user_id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-dark btn-xs text-nowrap'>${item.user_status == USER_STATUS.BLOCK ? strings.member.unblock : strings.member.block}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onBlock(item.user_id, item.user_status, () => refreshTable(false)));
                $(td).append(`<button type='button' class='btn btn-outline-dark btn-xs m-2 text-nowrap'>${strings.lounge.penalty}</button>`)
                  .find('button:eq(1)')
                  .on('click', () => onPenalty(item.user_id, item.user_nickname));
              }
            }, {
              targets: [columnNo++], data: 'target_id', orderable: false,
            }, {
              targets: [columnNo++], data: 'target_nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(item.target_nickname, item.target_gender, () => onUser(item.target_id));
              }
            }, {
              targets: [columnNo++], data: 'target_id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-dark btn-xs text-nowrap'>${item.target_status == USER_STATUS.BLOCK ? strings.member.unblock : strings.member.block}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onBlock(item.target_id, item.target_status, () => refreshTable(false)));
                $(td).append(`<button type='button' class='btn btn-outline-dark btn-xs m-2 text-nowrap'>${strings.lounge.penalty}</button>`)
                  .find('button:eq(1)')
                  .on('click', () => onPenalty(item.target_id, item.target_nickname));
              }
            }, {
              targets: [columnNo++], data: 'type', orderable: false,
              render: value => context.getSettingName(value, 'blame', 'chat')
            }, {
              targets: [columnNo++], data: 'content', orderable: false,
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
              render: value => value.dateFormat()
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                if (item.status == STATUS.NORMAL) {
                  $(td).html(`<label class='text-gray'>${strings.lounge.checked}</label>`);
                } else {
                  $(td).html(`<button type='button' class='btn btn-info btn-sm text-nowrap'>${strings.lounge.check}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDone(item.id));
                }
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'meeting/blame/member_list_in_chat',
              data: (req) => {
                req.meeting = id;
                req.column = $('#reportUserColumn').val();
                req.keyword = $('#reportUserInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th rowSpan={2}>#</th>
            <th colSpan={3}>{strings.match.reporter}</th>
            <th colSpan={3}>{strings.match.counter_reporter}</th>
            <th rowSpan={2}>{strings.match.report_type}</th>
            <th rowSpan={2} style={{ width: '30%' }}>{strings.match.report_content}</th>
            <th rowSpan={2}>{strings.match.report_date}</th>
            <th rowSpan={2}>{strings.common.manage}</th>
          </tr>
          <tr>
            <th>{strings.member.id}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.common.manage}</th>
            <th>{strings.member.id}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody/>
        </DataTable>
      </div>
    </div>
  );

}

export default MeetingPopupReportUser;
