import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { inject } from 'mobx-react';
import strings from "../../../lang/strings";

class DropdownProfile extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onLogout = () => {
    this.props.rootStore.signOut();
    this.props.history.replace('/login');
  };

  onChangePwd = () => {
    this.props.history.push('/manager/change_pwd');
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
        <DropdownToggle tag="a">
          {/*<img src="../assets/img/user/user-13.jpg" alt=""/>*/}
          <span className="d-inline">{this.props.rootStore.nickname}</span> <b className="caret"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
          <DropdownItem onClick={this.onChangePwd}>{strings.menu.change_password}</DropdownItem>
          <DropdownItem onClick={this.onLogout}>{strings.menu.log_out}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default withRouter(inject('rootStore')(DropdownProfile));
