import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PageSettings } from '../../config/page-settings';
import SidebarNav from './sidebar-nav.jsx';

const Sidebar = () => {
  return (
    <PageSettings.Consumer>
      {({ toggleMobileSidebar }) => (
        <React.Fragment>
          <div id="sidebar" className={'sidebar'}>
            <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
              <SidebarNav />
            </PerfectScrollbar>
          </div>
          <div className="sidebar-bg" />
          <div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar} />
        </React.Fragment>
      )}
    </PageSettings.Consumer>
  );
}

export default Sidebar;
