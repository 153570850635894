import React, { useContext, useRef, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import Container from "../../components/control/container";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import PartnerPopup from "./popup/partner-popup";

const PartnerList = () => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.partner;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    info: null
  });
  const showPopup = info => setPopup(prev => {
    return { ...prev, isOpen: true, info };
  });
  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'partner/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  return (
    <Container title={strings.partner.list}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
            <button type='button' className='btn btn-danger btn-sm ml-md-2'
                    onClick={() => showPopup(null)}>{strings.common.add}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'nickname', orderable: false,
              }, {
                targets: [columnNo++], data: 'user_id', orderable: false,
              }, {
                targets: [columnNo++], data: 'password_raw', orderable: false,
                render: value => value ?? '-'
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
                render: value => value ?? '-'
              }, {
                targets: [columnNo++], data: 'edit_date', orderable: false,
                render: value => value ?? '-'
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html('')
                    .append(`<button type='button' class='btn btn-primary btn-sm m-2'>${strings.common.edit}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => showPopup(item))
                    .end()
                    .append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button>`)
                    .find('button:eq(1)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'partner/list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.member.name}</th>
              <th>ID</th>
              <th>{strings.login.password}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.member.change_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <PartnerPopup
        {...popup}
        onClose={() => {
          hidePopup();
          refreshTable(false);
        }}
      />
    </Container>
  );

}

export default PartnerList;
