import { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";

const FreeKeyPopup = ({ isOpen, id, nickname, onSave, onClose }) => {

  const context = useContext(PageSettings);
  const [key, setKey] = useState('');
  const [reason, setReason] = useState('');

  const toggleModal = result => {
    onClose?.(result);
    setKey('');
    setReason('');
  }

  const onApply = () => {
    if (key == '') {
      context.addNotification(strings.modal.alert, strings.member.input_free_key, 'warning');
      return;
    }
    if (reason == '') {
      context.addNotification(strings.modal.alert, strings.member.input_free_key_reason, 'warning');
      return;
    }

    context.post(
      'member/key/add',
      {
        id,
        key,
        reason
      },
      () => {
        context.addNotification(strings.modal.alert, strings.member.key_success);
        onSave?.();
        setKey('');
        setReason('');
      }
    )
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.formatString(strings.member.user_free_key, nickname)}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xl-12">
            <input type='text' autoComplete='off' className='form-control m-t-10'
                   placeholder={strings.member.input_free_key}
                   value={key}
                   onChange={e => setKey(e.target.value)}/>
            <input type='text' autoComplete='off' className='form-control m-t-10'
                   placeholder={strings.member.input_free_key_reason}
                   value={reason}
                   onChange={e => setReason(e.target.value)}/>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onApply}>{strings.modal.apply}</button>
      </ModalFooter>
    </Modal>
  );
};

export default FreeKeyPopup;
