import React, { useRef } from "react";
import DataTable from "../../../components/control/data-table";
import strings from "../../../lang/strings";
import { STATUS } from "../../../config/const";

const MemberDetailPopupSleepLog = ({ user }) => {

  let columnNo = 0;
  const tableRef = useRef();

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='m-b-0'>
          <span className='m-r-10'>{strings.member.now}</span>
          {user.sleep == STATUS.NORMAL ?
            <h5 className='d-inline text-grey'>{strings.member.sleeping}</h5>
            :
            <h5 className='d-inline text-danger'>{strings.member.active}</h5>
          }
        </div>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'from', orderable: false,
            }, {
              targets: [columnNo++], data: 'to', orderable: false,
              render: value => value ? value : '-'
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'member/sleep_log',
              data: (req) => {
                req.id = user.id;
                req.from = '';
                req.to = '';
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>from</th>
            <th>to</th>
          </tr>
          </thead>
          <tbody/>
        </DataTable>
      </div>
    </div>
  );
};

export default MemberDetailPopupSleepLog;
