import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import strings from "../../../lang/strings";
import { useContext, useEffect, useState } from "react";
import { PageSettings } from "../../../config/page-settings";
import { GENDER } from "../../../config/const";
import moment from "moment";
import classnames from "classnames";

const MatchChatPopup = ({ isOpen, id, male, female, onClose }) => {

  const context = useContext(PageSettings);
  const toggleModal = result => onClose?.(result);

  const [list, setList] = useState([]);
  const getList = () => {
    context.get(
      'match/chat/list',
      { id },
      response => setList(response)
    );
  };

  const renderCell = (item, index) => {
    const user = item.member == male?.id ? male : female;
    return (
      <div
        key={index}
        className={classnames('d-flex align-items-start m-b-10 match-chat-cell', {
          'flex-row': user?.gender == GENDER.MALE,
          'flex-row-reverse': user?.gender == GENDER.FEMALE
        })}>
        <img src={user?.profile?.split('#')[0].getFullUrl().getThumbnailUrl()}/>
        <div className='d-flex flex-column chat-body'>
          <div className="chat-content">
            {item.content.split('\n').map((line, i) => <p className='m-b-0' key={`line-${i}`}>{line}</p>)}
          </div>
          <span className="chat-time">{moment(item.create_date).format('YY.MM.DD hh:mm')}</span>
        </div>
      </div>
    );
  }

  return (
    <Modal isOpen={isOpen} size={'md'} toggle={toggleModal} onOpened={getList}>
      <ModalHeader className="popup-title" toggle={toggleModal}>{strings.match.match_step.chatting}</ModalHeader>
      <ModalBody>
        <div className='row'>
          <div className='col-xl-12'>
            {list.map((it, idx) => renderCell(it, idx))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
      </ModalFooter>
    </Modal>
  );
};

export default MatchChatPopup;
