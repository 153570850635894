import React from "react";
import strings from "../../lang/strings";
import Container from "../../components/control/container";
import { withRouter } from "react-router-dom";
import MatchListFragment, { MATCH_TYPE } from "./fragment/match-list-fragment";

const MatchList = ({ type }) => {
  let title = strings.menu.match_list;
  switch (type) {
    case MATCH_TYPE.시도:
      title = strings.menu.match_try_list;
      break;
    case MATCH_TYPE.진행중:
      title = strings.menu.match_going_list;
      break;
    case MATCH_TYPE.여성수락:
      title = strings.menu.match_female_approve;
      break;
    case MATCH_TYPE.남성수락:
      title = strings.menu.match_male_approve;
      break;
    case MATCH_TYPE.최종수락:
      title = strings.menu.match_final_approve;
      break;
    case MATCH_TYPE.만남일결정:
      title = strings.menu.match_date_approve;
      break;
    case MATCH_TYPE.만남일당일:
      title = strings.menu.match_date_today;
      break;
    case MATCH_TYPE.후기단계:
      title = strings.menu.match_review_step;
      break;
    case MATCH_TYPE.후기완료:
      title = strings.menu.match_review_finish;
      break;
    case MATCH_TYPE.캔슬내역:
      title = strings.menu.match_cancel;
      break;
    case MATCH_TYPE.채팅내역_3일간:
      title = strings.menu.match_chat;
      break;
    case MATCH_TYPE.큐레이터:
      title = strings.menu.match_curator_list;
      break;
    default:
  }

  return (
    <Container title={title}>
      <MatchListFragment type={type} />
    </Container>
  );

}

export default withRouter(MatchList);
