import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

import Container from "../../components/control/container";
import strings from "../../lang/strings";
import MemberDetailMain from "./fragment/detail-main-fragment";
import MemberDetailAuth from "./fragment/detail-auth-fragment";
import MemberDetailExtra from "./fragment/detail-extra-fragment";
import MemberDetailProfile from "./fragment/detail-profile-fragment";
import { PageSettings } from "../../config/page-settings";
import { API_URL, PARTNER, PENDING_STATUS, STATUS } from "../../config/const";
import MemberDetailPartner from "./fragment/detail-partner-fragment";
import { inject } from "mobx-react";


const MemberDetail = ({ id, history, rootStore }) => {

  const context = useContext(PageSettings);

  const [user, setUser] = useState({});             // 사용자정보(승인된 정보)
  const [pending, setPending] = useState({});       // 심사대기정보
  const [auth, setAuth] = useState([]);             // 인증정보
  const [recommend, setRecommend] = useState([]);   // 지인정보
  const [partner, setPartner] = useState([]);       // 파트너정보
  const [adminProfileList, setAdminProfileList] = useState([]); // 관리자지정프로필리스트

  const [tabs, setTabs] = useState([
    { tab: 1, title: strings.member.main_info, badge: 0 },
    { tab: 2, title: strings.member.auth_info, badge: 0 },
    { tab: 3, title: strings.member.extra_info, badge: 0 },
    { tab: 4, title: strings.member.profile_info, badge: 0 },
    { tab: 5, title: strings.member.partner, badge: 0 },
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0].tab);

  useEffect(() => {
    // 페이지로드시 회원정보를 블러 온다.
    getUserInfo();
  }, [id]);

  useEffect(() => {
    // 승인/반려처리되지 않은 정보갯수를 설정
    setTabs(prev => {
      return prev.map(it => {
        switch (it.tab) {
          case 1:
            it.badge = ['nickname', 'job', 'location', 'school', 'family', 'single', 'drink', 'religion', 'smoke', 'marriage_plan', 'height', 'body_type', 'appeal_point']
              .filter(it => [STATUS.PENDING, STATUS.REAPPLY].includes(pending[it]?.status ?? -1)).length;
            break;
          case 2:
            it.badge = auth.filter(it => [STATUS.PENDING, STATUS.REAPPLY].includes(it.status)).length;
            break;
          case 3:
            it.badge = ['about_me', 'appeal_extra', 'intro', 'instagram_id', 'youtube_id', 'sns_id']
              .filter(it => pending[it]?.content != '' && [STATUS.PENDING, STATUS.REAPPLY].includes(pending[it]?.status ?? -1)).length;
            break;
          case 4:
            it.badge = ['video', 'profile']
              .filter(it => pending[it]?.content != '' && [STATUS.PENDING, STATUS.REAPPLY].includes(pending[it]?.status ?? -1)).length;
            break;
          default:
        }
        return it;
      })
    })
  }, [pending, auth]);

  const getUserInfo = () => {
    context.get(
      '/member/detail/' + id,
      {},
      response => {
        setUser(response.user);   // 회원정보(t_member 테이블의 정보)
        setRecommend(response.recommend); // 추천인(지인인증)정보
        setAdminProfileList(response.admin_profile); // 관리자지정프로필리스트
        setPartner(response.partner); // 파트너리스트(전담, 공유포함)

        // 심사/재심사대기중인 정보
        // 프로필이미지의 경우 수정된 이미지 여벌복사(image_cancel_org), 반려된 이미지리스트(reject_images)를 별도로 추가
        let pending_info = response.pending;
        if (pending_info.profile) {
          pending_info.profile.image_cancel_org = pending_info.profile.image_cancel;
          pending_info.profile.reject_images = [];
        }
        setPending(pending_info);

        // 인증정보
        // 우의 프로필이미지와 마찬가지로 수정된 이미지 여벌복사, 반려된 이미지리스트 필드를 별도로 추가
        setAuth(response.auth.map(it => {
          it.image_cancel_org = it.image_cancel;
          it.reject_images = [];
          return it;
        }));
      }
    );
  }

  // 승인
  const onPendingAccept = (category, index = 0) => {
    if (category != 'profile') {
      setPending(prev => {
        return {
          ...prev,
          [category]: { ...prev[category], status: STATUS.NORMAL }
        }
      });
    } else {
      // 프로필이미지에 대한 승인
      const profile = pending?.profile;
      const images = profile.image_cancel ? profile.image_cancel.split(',').map(it => Number(it)) : [];
      const reasons = profile.reason ? profile.reason.split('#') : [];
      const pos = images.indexOf(index);

      profile.image_cancel = images.filter(it => it != index).join(',');
      profile.reason = reasons.filter((it, idx) => idx != pos).join('#');

      if (profile.image_cancel == '') {
        profile.status = STATUS.NORMAL;
      } else if (profile.image_cancel.split(',').equal(profile.reject_images)) {
        profile.status = STATUS.REJECT;
      }

      setPending(prev => {
        return {
          ...prev,
          profile: profile
        }
      });
    }
  }

  // 반려
  const onPendingReject = (category, index = 0, reason = '') => {
    if (category != 'profile') {
      setPending(prev => {
        return {
          ...prev,
          [category]: { ...prev[category], status: STATUS.REJECT, reason: reason }
        }
      });
    } else {
      // 프로필이미지에 대한 반려
      const profile = pending?.profile;
      const images = profile?.image_cancel ? profile?.image_cancel.split(',').map(it => Number(it)) : [];
      let reasons = profile?.reason ? profile?.reason.split('#') : [];
      const pos = images.indexOf(index);

      while (reasons.length < (pos + 1)) {
        reasons.push('');
      }
      reasons[pos] = reason;

      profile.reason = reasons.join('#');
      profile.reject_images.push(index);

      if (profile.image_cancel == '') {
        profile.status = STATUS.NORMAL;
      } else if (profile.image_cancel.split(',').equal(profile.reject_images)) {
        profile.status = STATUS.REJECT;
      }

      setPending(prev => {
        return {
          ...prev,
          profile: profile
        }
      });
    }
  }

  // 승인/반려취소
  const onPendingCancel = category => {
    setPending(prev => {
      return {
        ...prev,
        [category]: { ...prev[category], status: STATUS.PENDING }
      }
    });
  }

  // 관리자지정프로필
  const onAdminProfile = profile => {
    setUser(prev => {
      return {
        ...prev,
        admin_profile: profile.replace(API_URL + '/', '')
      }
    });
  }

  const onPropChange = e => setUser(prev => {
    return {
      ...prev,
      [e.target.id]: e.target.value
    }
  });

  const onPendingChange = e => setPending(prev => {
    return {
      ...prev,
      [e.target.id]: { ...prev[e.target.id], content: e.target.value }
    }
  });

  // 인증정보 승인/승인취소
  const onAuthAccept = (type, index, cancel) => {
    setAuth(prev => {
      return prev.map(it => {
        if (it.type != type) {
          return it;
        }

        if (cancel == STATUS.YES) {
          // 승인취소(현재는 이 부분이 동작하지 않음, 승인취소기능을 작업해야 한다면 이 부분 재검토필요!!!)
          it.image_cancel = it.image_cancel ? it.image_cancel.split(',').concat([index]).join(',') : `${index}`;
        } else {
          // 승인
          const images = it.image_cancel ? it.image_cancel.split(',').map(it => Number(it)) : [];
          const reasons = it.reason ? it.reason.split('#') : [];
          const pos = images.indexOf(index);

          it.image_cancel = images.filter(it => it != index).join(',');
          it.reason = reasons.filter((it, idx) => idx != pos).join('#');
        }

        if (it.image_cancel == '') {
          it.status = STATUS.NORMAL;
        } else if (it.image_cancel.split(',').equal(it.reject_images)) {
          it.status = STATUS.REJECT;
        }

        return it;
      });
    });
  }

  // 인증정보 반려/반려취소
  const onAuthReject = (type, index, cancel, reason = '') => {
    setAuth(prev => {
      return prev.map(it => {
        if (it.type != type) {
          return it;
        }

        if (cancel == STATUS.YES) {
          // 반려취소(현재는 이 부분이 동작하지 않음, 반려취소기능을 작업해야 한다면 이 부분 재검토필요!!!)
          it.reason = it.reason?.split('#').filter((it, idx) => idx != it.image_cancel?.split(',').indexOf(`${index + 1}`)).join('#');
          it.image_cancel = it.image_cancel?.split(',').filter(it => it != (index + 1)).join(',');
        } else {
          // 반려
          const images = it.image_cancel ? it.image_cancel.split(',').map(it => Number(it)) : [];
          let reasons = it.reason ? it.reason.split('#') : [];
          const pos = images.indexOf(index);

          while (reasons.length < (pos + 1)) {
            reasons.push('');
          }
          reasons[pos] = reason;

          it.reason = reasons.join('#');
          it.reject_images.push(index);
        }

        if (it.image_cancel == '') {
          it.status = STATUS.NORMAL;
        } else if (it.image_cancel.split(',').equal(it.reject_images)) {
          it.status = STATUS.REJECT;
        }

        return it;
      });
    });
  }

  // 인증강제삭제
  const onAuthDelete = (id) => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'member/auth/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          setAuth(prev => prev.filter(it => it.id != id));
        }
      );
    });
  }

  // 파트너지정
  const onPartnerChange = (id, type) => {
    const changeChargePartner = () => {
      if (partner.some(it => it.partner == id && it.type == PARTNER.CHARGE)) {
        setPartner(prev => prev.filter(it => !(it.partner == id && it.type == PARTNER.CHARGE)));
      } else {
        setPartner(prev => prev.filter(it => !(it.partner == id && it.type == PARTNER.SHARE) && it.type != PARTNER.CHARGE).concat({
          partner: id,
          type
        }));
      }
    };

    if (type == PARTNER.CHARGE) { // 전담파트너
      // 전담파트너는 슈퍼어드민만이 할수 있다.
      if (!rootStore.isSuper) {
        return;
      }

      // 이미 다른 전담파트너가 지정되여 있거나 전담파트너에서 해제하는것이라면
      // 이 회원이 진행중인 카드가 없는지 체크
      if (partner.some(it => it.type == PARTNER.CHARGE)) {
        context.get(
          'member/check_partner',
          {
            user: user?.id ?? 0,
          },
          response => {
            if (response.code > 0) {
              context.showConfirm(strings.partner.not_change_partner, res => {
                if (res !== true) {
                  return;
                }
                changeChargePartner();
              });
            } else {
              changeChargePartner();
            }
          },
          false
        );
      } else {
        changeChargePartner();
      }
    } else { // 공유파트너
      // 만일 전담파트너로 이미 지정된 파트너이면 리턴
      if (partner.some(it => it.partner == id && it.type == PARTNER.CHARGE)) {
        return;
      }

      if (partner.some(it => it.partner == id)) {
        setPartner(prev => prev.filter(it => it.partner != id));
      } else {
        setPartner(prev => prev.concat({
          partner: id,
          type
        }));
      }
    }
  }

  // 심사적용
  const onSave = () => {
    if (tabs.filter(it => it.badge > 0).length > 0) {
      context.addNotification(strings.modal.alert, strings.member.pending_info_left);
      return;
    }
    if (user?.admin_profile == null || user?.admin_profile == '') {
      context.addNotification(strings.modal.alert, strings.member.select_admin_profile);
      return;
    }

    context.showConfirm(strings.member.apply_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/pending/save',
        {
          user, pending, auth, partner,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          history.goBack();
        }
      );
    });
  }

  // 심사거절
  const onReject = () => {
    context.showConfirm(strings.member.reject_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/pending/reject',
        {
          user, pending, auth, partner,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.member.reject_success);
          history.goBack();
        }
      );
    });
  }

  return (
    <Container title={strings.menu.member_profile_pending}>
      <div className="vertical-box with-grid inbox bg-light user-pending-box">
        <div className="vertical-box-column width-200">
          <div className="vertical-box">
            <div className="vertical-box-row d-lg-table-row">
              <div className="vertical-box-cell">
                <div className="vertical-box-inner-cell">
                  <div data-scrollbar="true" data-height="100%">
                    <div className="wrapper p-0">
                      <ul className="nav nav-inbox">
                        {tabs.map(menu => (
                          <li key={menu.tab} className={classnames({ active: activeTab == menu.tab })}>
                            <a onClick={() => setActiveTab(menu.tab)} style={{ cursor: 'pointer' }}>
                              {menu.title} {menu.badge > 0 && <span className="badge pull-right">{menu.badge}</span>}
                            </a>
                          </li>
                        ))}
                        {
                          !(user?.status == STATUS.PENDING && user?.pending_status == PENDING_STATUS.REJECT) &&
                          // 심사반려된 회원이 아닌 경우만 심사적용/거절 버튼을 현시함
                          <>
                            <li><a>&nbsp;</a></li>
                            <li>
                              <a className='text-info-darker' onClick={onSave}
                                 style={{ cursor: 'pointer', fontSize: 18 }}>
                                {user?.status == STATUS.NORMAL ? strings.member.apply2 : strings.member.apply}
                              </a>
                            </li>
                            {
                              user?.status == STATUS.PENDING &&
                              // 최초회원인 경우만 심사 거절버튼 현시함
                              <li>
                                <a className='text-danger-darker' onClick={onReject}
                                   style={{ cursor: 'pointer', fontSize: 14 }}>
                                  {strings.member.reject}
                                </a>
                              </li>
                            }
                          </>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vertical-box-column">
          <div className="vertical-box">
            <div className="vertical-box-row">
              <div className="vertical-box-cell">
                <div className="vertical-box-inner-cell bg-white" style={{ overflow: 'auto' }}>
                  {activeTab == tabs[0].tab && <MemberDetailMain user={user}
                                                                 pending={pending}
                                                                 onChange={onPropChange}
                                                                 onPendingChange={onPendingChange}
                                                                 onAccept={onPendingAccept}
                                                                 onReject={onPendingReject}
                                                                 onCancel={onPendingCancel} />}
                  {activeTab == tabs[1].tab && <MemberDetailAuth user={user}
                                                                 auth={auth}
                                                                 recommend={recommend}
                                                                 onAccept={onAuthAccept}
                                                                 onReject={onAuthReject}
                                                                 onDelete={onAuthDelete} />}
                  {activeTab == tabs[2].tab && <MemberDetailExtra user={user}
                                                                  pending={pending}
                                                                  onChange={onPropChange}
                                                                  onPendingChange={onPendingChange}
                                                                  onAccept={onPendingAccept}
                                                                  onReject={onPendingReject}
                                                                  onCancel={onPendingCancel} />}
                  {activeTab == tabs[3].tab && <MemberDetailProfile user={user}
                                                                    pending={pending}
                                                                    profiles={adminProfileList}
                                                                    onProfile={onAdminProfile}
                                                                    onAccept={onPendingAccept}
                                                                    onReject={onPendingReject}
                                                                    onCancel={onPendingCancel} />}
                  {activeTab == tabs[4].tab && <MemberDetailPartner partner={partner}
                                                                    onPartner={onPartnerChange} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default withRouter(inject('rootStore')(MemberDetail));
