import { useCallback, useContext, useEffect, useState } from "react";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { GENDER } from "../../../config/const";

const MemberDetailPopupInvite = ({ user }) => {

  const context = useContext(PageSettings);

  // const [invite, setInvite] = useState(0);
  // const [success, setSuccess] = useState(0);

  const [members, setMembers] = useState([]);

  useEffect(() => {
    context.get(
      'member/invite_log',
      { id: user.id },
      response => {
        // setInvite(response.invite_cnt);
        // setSuccess(response.success_cnt);
        setMembers(response.members);
      },
      false
    );
  }, []);

  const getTotalCount = useCallback(() => {
    const getCount = array => {
      if (!Array.isArray(array)) {
        return 0;
      }

      let count = array.length;
      for (const item of array) {
        count += getCount(item.children);
      }
      return count;
    }
    return getCount(members);
  }, [members]);

  const renderTree = (list, parent = []) => {
    if (!Array.isArray(list) || list.length < 1) {
      return <></>;
    }

    return list.map(item => (
      <div key={`child-${item.id}`}>
        <label>
          {
            parent.map(parent => (
              <label key={`parent-${item.id}-${parent.id}`}>
                <span className={parent.gender === GENDER.MALE ? 'male' : 'female'} style={{ opacity: .4 }}>
                  {parent.nickname}
                </span>
                &nbsp;<i className='fa fa-arrow-right' style={{ fontSize: '.65rem' }} />&nbsp;
              </label>
            ))
          }
          <span className={item.gender === GENDER.MALE ? 'male' : 'female'}>{item.nickname}</span>
          {strings.formatString(strings.member.invite_info, user?.nickname, item.depth)}
        </label>
        {renderTree(item.children, parent.concat(item))}
      </div>
    ));
  }

  return (
    <div className='row'>
      {/*<div className='col-xs-12'>
        <label>{strings.member.invite_count}:</label>
        <h4>{invite.currencyFormat()}</h4>
        <label>{strings.member.invite_success_count}:</label>
        <h4>{success.currencyFormat()}</h4>
        <label>{strings.member.invite_ratio}:</label>
        <h4>{Number(success / (invite == 0 ? 1 : invite)).toFixed(2) * 100}%</h4>
      </div>*/}
      <div className='col-xs-12'>
        <label>
          {strings.member.invite_success_count}: <h4 className='d-inline-block'>{getTotalCount().currencyFormat()}</h4>
        </label>
        <div className='invite-member-tree'>
          {renderTree(members)}
        </div>
      </div>
    </div>
  );
};

export default MemberDetailPopupInvite;
