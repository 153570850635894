import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classnames from "classnames";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import MemberDetailPopupMain from "./detail-popup-main";
// import MemberDetailPopupProfile from "./detail-popup-profile";
// import MemberDetailPopupAuth from "./detail-popup-auth";
import MemberDetailPopupApp from "./detail-popup-app";
// import MemberDetailPopupFavor from "./detail-popup-favor";
// import MemberDetailPopupLevel from "./detail-popup-level";
import MemberDetailPopupFamiliar from "./detail-popup-familiar";
import MemberDetailPopupPayLog from "./detail-popup-pay-log";
import MemberDetailPopupKeyLog from "./detail-popup-key-log";
import MemberDetailPopupSleepLog from "./detail-popup-sleep-log";
import MemberDetailPopupInvite from "./detail-popup-invite";
import MemberDetailPopupBlock from "./detail-popup-block";
import MemberDetailPopupMatch from "./detail-popup-match";
import MemberDetailPopupMeeting from "./detail-popup-meeting";
import MemberDetailPopupPartner from "./detail-popup-partner";
import { PARTNER } from "../../../config/const";
import { inject } from "mobx-react";

const MemberDetailPopup = ({ isOpen, id, onClose, callback, rootStore }) => {

  const context = useContext(PageSettings);

  const [activeTab, setActiveTab] = useState(0);
  const [user, setUser] = useState({});             // 사용자정보(승인된 정보)
  const [additional, setAdditional] = useState({}); // 기타 사용자정보(누적결제금액 등...)
  const [referrer, setReferrer] = useState(null);   // 이 사용자를 초대한 회원정보
  const [pending, setPending] = useState({});       // 심사대기정보
  const [auth, setAuth] = useState([]);             // 인증정보
  const [adminProfileList, setAdminProfileList] = useState([]); // 관리자지정프로필리스트
  const [partner, setPartner] = useState([]);       // 파트너리스트
  const [saveButton, toggleSaveButton] = useState(false); // 적용버튼현시여부

  useEffect(() => {
    toggleSaveButton(user.partner_type == PARTNER.CHARGE);
  }, [user]);

  const getUserInfo = () => {
    context.get(
      '/member/detail/' + id,
      {},
      response => {
        setUser(response.user);   // 회원정보(t_member 테이블의 정보)
        setAdditional(response.additional);
        setAdminProfileList(response.admin_profile); // 관리자지정프로필리스트
        setPending(response.pending); // 심사/재심사대기중인 정보
        setAuth(response.auth); // 인증정보
        setReferrer(response.referrer); // 초대한 회원정보
        setPartner(response.partner); // 파트너 리스트
      }
    );
  };

  const toggleModal = result => {
    onClose?.(result);
  }

  const toggleTab = tab => {
    getUserInfo();
    setActiveTab(tab);
  }

  const onSave = () => {
    if (!saveButton) {
      return;
    }

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/save',
        {
          user, auth, partner
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          callback?.();
        }
      );
    });
  }

  const onUserChange = e => {
    setUser(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  // 인증강제삭제
  const onAuthDelete = (id) => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'member/auth/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          setAuth(prev => prev.filter(it => it.id != id));
        }
      );
    });
  }

  // video 강제삭제
  const onVideoDelete = (id) => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'member/deleteVideo/' + user.id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          setUser(prev => { 
            return {
              ...prev,
              video: null
            }
          });
        }
      );
    });
  }

  // 파트너지정
  const onPartnerChange = (id, type) => {
    const changeChargePartner = () => {
      if (partner.some(it => it.partner == id && it.type == PARTNER.CHARGE)) {
        setPartner(prev => prev.filter(it => !(it.partner == id && it.type == PARTNER.CHARGE)));
      } else {
        setPartner(prev => prev.filter(it => !(it.partner == id && it.type == PARTNER.SHARE) && it.type != PARTNER.CHARGE).concat({
          partner: id,
          type
        }));
      }
    };

    if (type == PARTNER.CHARGE) { // 전담파트너
      // 전담파트너는 슈퍼어드민만이 변경가능
      if (!rootStore.isSuper) {
        return;
      }

      // 이미 다른 전담파트너가 지정되여 있거나 전담파트너에서 해제하는것이라면
      // 이 회원이 진행중인 카드가 없는지 체크
      if (partner.some(it => it.type == PARTNER.CHARGE)) {
        context.get(
          'member/check_partner',
          {
            user: user?.id ?? 0,
          },
          response => {
            if (response.code > 0) {
              context.showConfirm(strings.partner.not_change_partner, res => {
                if (res !== true) {
                  return;
                }
                changeChargePartner();
              });
            } else {
              changeChargePartner();
            }
          },
          false
        );
      } else {
        changeChargePartner();
      }
    } else { // 공유파트너
      // 만일 전담파트너로 이미 지정된 파트너이면 리턴
      if (partner.some(it => it.partner == id && it.type == PARTNER.CHARGE)) {
        return;
      }

      if (partner.some(it => it.partner == id)) {
        setPartner(prev => prev.filter(it => it.partner != id));
      } else {
        setPartner(prev => prev.concat({
          partner: id,
          type
        }));
      }
    }
  }

  const tabs = [{
    title: strings.member.main_info,
    component: () => <MemberDetailPopupMain user={user} additional={additional} auth={auth} referrer={referrer}
                                            onChange={onUserChange} onAuthDelete={onAuthDelete} onVideoDelete={onVideoDelete} />
  }, /*{
    title: strings.member.profile_info,
    component: () => <MemberDetailPopupProfile user={user} adminProfiles={adminProfileList} onChange={onUserChange}/>
  }, {
    title: strings.member.favor_info,
    component: () => <MemberDetailPopupFavor user={user} onChange={onUserChange}/>
  }, {
    title: strings.member.level_info,
    component: () => <MemberDetailPopupLevel user={user} onChange={onUserChange}/>
  }, {
    title: strings.member.auth_info,
    component: () => <MemberDetailPopupAuth auth={auth}/>
  },*/ {
    title: strings.member.familiar_info,
    component: () => <MemberDetailPopupFamiliar user={user} />
  }, {
    title: strings.member.pay_log,
    component: () => <MemberDetailPopupPayLog user={user} />
  }, {
    title: strings.member.key_log,
    component: () => <MemberDetailPopupKeyLog user={user} onRefresh={getUserInfo} />
  }, {
    title: strings.member.sleep_log,
    component: () => <MemberDetailPopupSleepLog user={user} />
  }, {
    title: strings.member.invite_user,
    component: () => <MemberDetailPopupInvite user={user} />
  }, {
    title: strings.member.block_phone,
    component: () => <MemberDetailPopupBlock user={user} />
  }, {
    title: strings.member.match,
    component: () => <MemberDetailPopupMatch user={user} />
  }, {
    title: strings.member.meeting,
    component: () => <MemberDetailPopupMeeting user={user} />
  }, {
    title: strings.member.app_info,
    component: () => <MemberDetailPopupApp user={user} />
  }, {
    title: strings.member.partner,
    component: () => <MemberDetailPopupPartner partner={partner} onPartner={onPartnerChange} />
  }];

  return (
    <Modal isOpen={isOpen} size={'xl'} toggle={toggleModal} onOpened={() => {
      setActiveTab(0);
      getUserInfo();
    }}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.member.detail} ({user?.nickname}, {user?.gender?.genderStr()}, {user?.birth?.ageFormat()}, {user?.location})
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xl-2">
            {tabs.map((menu, idx) => (
              <button
                key={`menu-${idx}`} type="button" onClick={() => toggleTab(idx)}
                className={classnames(
                  'width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1',
                  { active: activeTab == idx }
                )}>
                {menu.title}
              </button>
            ))}
          </div>
          <div className="col-xl-10">
            {tabs.map((menu, idx) => (
              activeTab == idx &&
              <div key={`frg-${idx}`}
                   style={{ maxHeight: 'calc(100vh - 220px)', overflowX: 'hidden', overflowY: 'auto' }}>
                {menu.component()}
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        {
          saveButton &&
          <button className="btn btn-success" onClick={onSave}>{strings.modal.apply}</button>
        }
      </ModalFooter>
    </Modal>
  );
};

export default inject('rootStore')(MemberDetailPopup);
