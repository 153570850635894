import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

import Container from '../../../components/control/container';
import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const LoginChartFragment = () => {

  const context = useContext(PageSettings);
  const lineChartOptions = {
    animation: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: true,
        ticks: {
          stepSize: 10,
        }
      }
    }
  }

  const [date, setDate] = useState([]);
  const [male, setMale] = useState([]);
  const [female, setFemale] = useState([]);
  useEffect(() => {
    context.get(
      'dashboard/login_chart',
      {},
      response => {
        const date_list = [];
        const male_list = [];
        const female_list = [];

        response.forEach(entry => {
          date_list.push(moment(entry.date).format('M/D'));
          male_list.push(entry.unique_male_count);
          female_list.push(entry.unique_female_count);
        });

        setDate(date_list);
        setMale(male_list);
        setFemale(female_list);
      }
    );
  }, []);

  const [lineChartData, setLineChartData] = useState({ labels: [], datasets: [] });
  useEffect(() => {
    setLineChartData({
      labels: date,
      datasets: [{
        label: strings.common.male,
        data: male,
        fill: true,
        backgroundColor: 'rgba(0, 172, 172, 0.25)',
        borderColor: '#00acac',
        borderWidth: 2
      }, {
        label: strings.common.female,
        data: female,
        fill: true,
        backgroundColor: 'rgba(52, 143, 226, 0.25)',
        borderColor: '#348fe2',
        borderWidth: 2
      }]
    });
  }, [date, male, female]);

  return (
    <Container title={strings.dashboard.login_chart_title} rightButton={(
      <Link to="/sta/login" style={{ color: '#fff' }}>
        {strings.dashboard.just_go} <i className="fa fa-arrow-alt-circle-right"/>
      </Link>
    )}>
      <Bar type={'bar'} options={lineChartOptions} data={lineChartData}/>
    </Container>
  );
}

export default LoginChartFragment;
