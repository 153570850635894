import React from "react";
import { withRouter } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import strings from "../../lang/strings";
import { GENDER } from "../../config/const";
import { Panel, PanelBody } from "../../components/panel/panel";
import MatchFragment from "./fragment/match-fragment";

const MatchMatch = ({ gender }) => {

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        <NavItem>
          <NavLink className='active'>
            <span className="d-none d-xs-inline">
              {gender == GENDER.FEMALE ? strings.member.female_member : strings.member.male_member}
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <PanelBody>
        <MatchFragment gender={gender} />
      </PanelBody>
    </Panel>
  );

}

export default withRouter(MatchMatch);
