import React, { useRef } from "react";
import strings from "../../../lang/strings";
import DataTable from "../../../components/control/data-table";

const MemberDetailPopupPayLog = ({ user }) => {

  let columnNo = 0;
  const tableRef = useRef();

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='m-b-0'>
          <span className='m-r-10'>{strings.member.now} {strings.member.key}: </span>
          <h5 className='d-inline text-dark'>{user.key.currencyFormat()}</h5>
        </div>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'iap_create_date', orderable: false,
              render: value => value.dateFormat()
            }, {
              targets: [columnNo++], data: 'iap_path', orderable: false,
            }, {
              targets: [columnNo++], data: 'iap_price', orderable: false,
              render: value => value.currencyFormat()
            }, {
              targets: [columnNo++], data: 'iap_point', orderable: false,
              render: value => value.currencyFormat()
            }, {
              targets: [columnNo++], data: 'iap_status', orderable: false,
              render: value => value == 0 ? strings.iap.complete : `<span class="male">${strings.iap.refund}</span>`
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'iap/log',
              data: (req) => {
                req.user = user.id;
                req.column = '';
                req.keyword = '';
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.iap.date}</th>
            <th>{strings.iap.method}</th>
            <th>{strings.iap.money}</th>
            <th>{strings.iap.heart}</th>
            <th>{strings.iap.status}</th>
          </tr>
          </thead>
          <tbody/>
        </DataTable>
      </div>
    </div>
  );
};

export default MemberDetailPopupPayLog;
