import React, { useContext, useRef, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import Container from "../../components/control/container";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import { PARTNER, STATUS } from "../../config/const";
import MatchDetailPopup from "./popup/detail-popup";
import { inject } from "mobx-react";

const MatchBlame = ({ rootStore }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.member;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, togglePopup] = useState({
    isOpen: false,
    id: 0
  });
  const showDetailPopup = id => togglePopup({ isOpen: true, id });
  const hideDetailPopup = () => togglePopup({ isOpen: false, id: 0 });

  const onUser = user_id => {
    const showUserPopup = () => context.showUserPopup(user_id, () => refreshTable(false));

    if (rootStore.isSuper) {
      showUserPopup();
      return;
    }

    // 해당 유저가 파트너의 전담/공유회원인지를 체크
    context.get(
      'member/partner_type',
      {
        user: user_id
      },
      response => {
        if (response != PARTNER.NONE) {
          showUserPopup();
        }
      },
      false
    );
  }

  const onDone = id => {
    context.post(
      'meeting/blame/member_done',
      { id },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.applied);
        context.resetAlarmList();
        refreshTable(false);
      }
    )
  }

  const onRefund = (blame_id, user_id) => {
    context.showConfirm(strings.modal.refund_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'match/blame/refund/',
        {
          blame: blame_id,
          user: user_id
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          context.resetAlarmList();
          refreshTable(false);
        }
      );
    });
  }

  return (
    <Container title={strings.menu.match_blame}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'user_id', orderable: false,
              }, {
                targets: [columnNo++], data: 'user_profile', orderable: false,
                createdCell: (td, value, item) => {
                  if (!value) {
                    $(td).image_lightbox(item.user_admin_profile, () => {
                      context.showGallery([item.user_admin_profile.getFullUrl()]);
                    }, item.user_single);
                  } else {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    }, item.user_single);
                  }
                }
              }, {
                targets: [columnNo++], data: 'user_nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(item.user_nickname, item.user_gender, () => onUser(item.user_id));
                }
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html('-');
                  if (!rootStore.isSuper) {
                    return;
                  }
                  if (item.status != STATUS.NORMAL) {
                    $(td).html(`<button type='button' class='btn btn-indigo btn-sm text-nowrap'>${strings.iap.refund}</button>`)
                      .find('button:eq(0)')
                      .on('click', () => onRefund(item.id, item.user_id));
                  }
                }
              }, {
                targets: [columnNo++], data: 'target_id', orderable: false,
              }, {
                targets: [columnNo++], data: 'target_profile', orderable: false,
                createdCell: (td, value, item) => {
                  if (!value) {
                    $(td).image_lightbox(item.target_admin_profile, () => {
                      context.showGallery([item.target_admin_profile.getFullUrl()]);
                    }, item.target_single);
                  } else {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    }, item.target_single);
                  }
                }
              }, {
                targets: [columnNo++], data: 'target_nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(item.target_nickname, item.target_gender, () => onUser(item.target_id));
                }
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html('-');
                  if (!rootStore.isSuper) {
                    return;
                  }
                  if (item.status != STATUS.NORMAL) {
                    $(td).html(`<button type='button' class='btn btn-indigo btn-sm text-nowrap'>${strings.iap.refund}</button>`)
                      .find('button:eq(0)')
                      .on('click', () => onRefund(item.id, item.target_id));
                  }
                }
              }, {
                targets: [columnNo++], data: 'type', orderable: false,
                render: value => context.getSettingName(value, 'blame', 'chat')
              }, {
                targets: [columnNo++], data: 'content', orderable: false,
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
                render: value => value.dateFormat()
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html('');
                  if (item.status == STATUS.NORMAL) {
                    $(td).html(`<label class='text-gray'>${strings.lounge.checked}</label>`);
                  } else {
                    if (rootStore.isSuper) {
                      $(td).html(`<button type='button' class='btn btn-info btn-sm text-nowrap'>${strings.lounge.check}</button>`)
                        .find('button:eq(0)')
                        .on('click', () => onDone(item.id));
                    }
                  }
                  $(td)
                    .append(`<br/><button type='button' class='btn btn-outline-info btn-sm m-2 text-nowrap'>${strings.support.view}</button>`)
                    .find('button:last-child')
                    .on('click', () => showDetailPopup(item.link))
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'match/blame/list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th rowSpan={2}>#</th>
              <th colSpan={4}>{strings.match.reporter}</th>
              <th colSpan={4}>{strings.match.counter_reporter}</th>
              <th rowSpan={2}>{strings.match.report_type}</th>
              <th rowSpan={2} style={{ width: '30%' }}>{strings.match.report_content}</th>
              <th rowSpan={2}>{strings.match.report_date}</th>
              <th rowSpan={2}>{strings.common.manage}</th>
            </tr>
            <tr>
              <th>{strings.member.id}</th>
              <th>{strings.member.profile}</th>
              <th>{strings.common.nickname}</th>
              <th>{strings.common.manage}</th>
              <th>{strings.member.id}</th>
              <th>{strings.member.profile}</th>
              <th>{strings.common.nickname}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <MatchDetailPopup
        {...popup}
        onClose={hideDetailPopup} />
    </Container>
  );

}

export default inject('rootStore')(MatchBlame);
