import React, { useContext, useRef } from "react";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import { STATUS, USER_STATUS } from "../../../config/const";

const BlameCommentFragment = ({ onBlock, onUser, onPenalty }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.comment;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onDone = id => {
    context.post(
      'lounge/blame/comment_done',
      { id },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.applied);
        context.resetAlarmList();
        refreshTable(false);
      }
    )
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_lounge_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'lounge/comment/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='searchColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)}/>
          <SearchInput
            id='searchInput'
            action={() => refreshTable(true)}/>
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'user_profile', orderable: false,
              createdCell: (td, value, item) => {
                $(td).image_lightbox(value, () => {
                  context.showGallery(value.split('#').map(it => it.getFullUrl()));
                }, item.user_single);
              }
            }, {
              targets: [columnNo++], data: 'user_nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(
                  item.user_nickname,
                  item.user_gender,
                  () => onUser(item.user_id, () => refreshTable(false))
                );
              }
            }, {
              targets: [columnNo++], data: 'user_id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-dark btn-sm text-nowrap'>${item.user_status == USER_STATUS.BLOCK ? strings.member.unblock : strings.member.block}</button>`)
                  .find('button:eq(0)')
                  .on('click', function () {
                    onBlock(item.user_id, item.user_status, () => refreshTable(false));
                  });
              }
            }, {
              targets: [columnNo++], data: 'target_profile', orderable: false,
              createdCell: (td, value, item) => {
                $(td).image_lightbox(value, () => {
                  context.showGallery(value.split('#').map(it => it.getFullUrl()));
                }, item.target_single);
              }
            }, {
              targets: [columnNo++], data: 'target_nickname', orderable: false,
              createdCell: (td, value, item) => {
                $(td).nickname_label(
                  item.target_nickname,
                  item.target_gender,
                  () => onUser(item.target_id, () => refreshTable(false))
                );
              }
            }, {
              targets: [columnNo++], data: 'content', orderable: false,
            }, {
              targets: [columnNo++], data: 'blame_cnt', orderable: false,
              render: value => value.currencyFormat()
            }, {
              targets: [columnNo++], data: 'target_id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-dark btn-sm text-nowrap'>${item.target_status == USER_STATUS.BLOCK ? strings.member.unblock : strings.member.block}</button>`)
                  .find('button:eq(0)')
                  .on('click', function () {
                    onBlock(item.target_id, item.target_status, () => refreshTable(false));
                  })
                  .end()
                  .append(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.lounge.penalty}</button>`)
                  .find('button:eq(1)')
                  .on('click', () => onPenalty(item.target_id, item.target_nickname));
              }
            }, {
              targets: [columnNo++], data: 'type', orderable: false,
              render: value => context.getSettingName(value, 'blame', 'content')
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
              render: value => value.dateFormat()
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                if (item.status == STATUS.NORMAL) {
                  $(td).html(`<label class='text-gray'>${strings.lounge.checked}</label><br/>`);
                } else {
                  $(td).html(`<button type='button' class='btn btn-info btn-sm text-nowrap'>${strings.lounge.check}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDone(item.id));
                }
                $(td).append(`<button type='button' class='btn btn-danger btn-sm text-nowrap'>${strings.common.delete}</button>`)
                  .find('button:last-child')
                  .on('click', () => onDelete(item.comment_id));
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'lounge/blame/comment_list',
              data: (req) => {
                req.column = $('#searchColumn').val();
                req.keyword = $('#searchInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th rowSpan={2}>#</th>
            <th colSpan={3}>{strings.match.reporter}</th>
            <th colSpan={5}>{strings.lounge.blame_lounge}</th>
            <th rowSpan={2}>{strings.lounge.blame_reason}</th>
            <th rowSpan={2}>{strings.lounge.blame_date}</th>
            <th rowSpan={2}>{strings.common.manage}</th>
          </tr>
          <tr>
            <th>{strings.member.profile}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.common.manage}</th>
            <th>{strings.member.profile}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.lounge.comment}</th>
            <th>{strings.lounge.blame_cnt}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody/>
        </DataTable>
      </div>
    </div>
  );

}

export default BlameCommentFragment;
