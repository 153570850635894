import MatchListFragment from "../../match/fragment/match-list-fragment";

const MemberDetailPopupMatch = ({ user }) => {

  /*const [onCount, setOnCount] = useState(0);
  const [finishCount, setFinishCount] = useState(0);

  useEffect(() => {
    context.get(
      'member/match_info',
      {
        id: user.id
      },
      response => {
        setOnCount(response.on_count);
        setFinishCount(response.finish_count);
      },
      false
    )
  }, []);*/

  return (
    <MatchListFragment id={user?.id} />
  );
};

export default MemberDetailPopupMatch;
