import React from "react";
import strings from "../lang/strings";

import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/dashboard";
import MemberPending from "../pages/member/pending";
import MemberNormal from "../pages/member/normal";
import MemberDetail from "../pages/member/detail";
import MemberInvite from "../pages/member/invite";
import MemberConcierge from "../pages/member/concierge";
import MatchList from "../pages/match/list";
import MatchReservationList from "../pages/match/reservation-list";
import MatchMatch from "../pages/match/match";
import MatchBlame from "../pages/match/blame";
import MeetingList from "../pages/meeting/list";
import MeetingReview from "../pages/meeting/review";
import MeetingBlame from "../pages/meeting/blame";
import MeetingChat from "../pages/meeting/chat";
import MeetingPenalty from "../pages/meeting/penalty";
import LoungeList from "../pages/lounge/list";
import LoungePenalty from "../pages/lounge/penalty";
import LoungeBlame from "../pages/lounge/blame";
import LoungeComment from "../pages/lounge/comment";
import IapLog from "../pages/iap/log";
import IapSta from "../pages/iap/sta";
import StaLogin from "../pages/sta/login";
import StaSignup from "../pages/sta/signup";
import StaHourly from "../pages/sta/hourly";
import Setting from "../pages/setting/setting";
import Term from "../pages/setting/term";
import Alias from "../pages/setting/alias";
import Notice from "../pages/setting/notice";
import ChangePassword from "../pages/manager/change_password";
import MemberStatus from "../pages/member/status";
import { PENDING_TYPE } from "../pages/member/fragment/pending-fragment";
import { MEMBER_TYPE } from "../pages/member/fragment/normal-fragment";
import { MATCH_TYPE } from "../pages/match/fragment/match-list-fragment";
import AppVersion from "../pages/setting/version";
import { GENDER } from "./const";
import PartnerList from "../pages/partner/list";
import SignupMsg from "../pages/setting/signup-msg";

const Extra = [
  {
    path: '/login', title: strings.login.login, exact: true, private: false,
    component: () => <Login />
  },
  {
    path: '/manager/change_pwd', title: strings.menu.change_password, exact: true, private: true,
    component: () => <ChangePassword />
  },
  {
    path: '/member/detail/:id', title: strings.menu.member_profile_pending, exact: true, private: true,
    component: (props) => <MemberDetail id={props.match.params.id} />
  },
];
const Menu = [
  {
    path: '/dashboard', icon: 'fa fa-th', title: strings.menu.dashboard, exact: true, private: true,
    component: () => <Dashboard />
  },
  {
    path: '/member', icon: 'fa fa-user', title: strings.menu.member,
    children: [
      {
        path: `/member/pending/${PENDING_TYPE.최초}`,
        title: strings.menu.member_profile_pending_new,
        exact: true,
        private: true,
        partner: true,    // 이 필드값이 true 인 라우트는 파트너가입시 필터처리됨
        component: () => <MemberPending type={PENDING_TYPE.최초} />
      },
      {
        path: `/member/pending/${PENDING_TYPE.심사반려}`,
        title: strings.menu.member_profile_pending_await,
        exact: true,
        private: true,
        component: () => <MemberPending type={PENDING_TYPE.심사반려} />
      },
      {
        path: `/member/pending/${PENDING_TYPE.합격1차}`,
        title: strings.menu.member_profile_pending_favor,
        exact: true,
        private: true,
        component: () => <MemberPending type={PENDING_TYPE.합격1차} />
      },
      {
        path: `/member/pending/${PENDING_TYPE.신규회원}`,
        title: strings.menu.member_profile_pending_new_edit,
        exact: true,
        private: true,
        component: () => <MemberPending type={PENDING_TYPE.신규회원} />
      },
      {
        path: `/member/pending/${PENDING_TYPE.기존회원}`,
        title: strings.menu.member_profile_pending_edit,
        exact: true,
        private: true,
        component: () => <MemberPending type={PENDING_TYPE.기존회원} />
      },
      {
        path: `/member/${MEMBER_TYPE.정상활동}`, title: strings.menu.member_normal, exact: true, private: true,
        component: () => <MemberNormal type={MEMBER_TYPE.정상활동} />
      },
      {
        path: `/member/${MEMBER_TYPE.전체}`, title: strings.menu.member_all, exact: true, private: true,
        component: () => <MemberNormal type={MEMBER_TYPE.전체} />
      },
      {
        path: `/member/pending/${PENDING_TYPE.심사거절}`,
        title: strings.menu.member_profile_pending_reject,
        exact: true,
        private: true,
        partner: true,
        component: () => <MemberPending type={PENDING_TYPE.심사거절} />
      },
      {
        path: '/member/status', title: strings.menu.member_status, exact: true, private: true,
        component: () => <MemberStatus />
      },
      {
        path: '/member/invite', title: strings.menu.member_invite, exact: true, private: true,
        component: () => <MemberInvite />
      },
      /*{
        path: '/member/referrer', title: strings.menu.member_referrer, exact: true, private: true,
        component: () => <MemberReferrer/>
      }*/
    ]
  },
  {
    path: '/concierge', icon: 'fa fa-comments', title: strings.menu.member_concierge, exact: true, private: true,
    component: () => <MemberConcierge />
  },
  {
    path: '/match', icon: 'fa fa-coffee', title: strings.menu.match,
    children: [
      {
        path: '/match/reservation', title: strings.menu.match_reservation, exact: true, private: true,
        component: () => <MatchReservationList/>
      },
      {
        path: '/match/match_male', title: strings.menu.match_curator, exact: true, private: true,
        component: () => <MatchMatch gender={GENDER.MALE} />
      },
      {
        path: `/match/${MATCH_TYPE.큐레이터}`, title: strings.menu.match_curator_list, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.큐레이터} />
      },
      {
        path: '/match/match', title: strings.menu.match_match, exact: true, private: true,
        component: () => <MatchMatch gender={GENDER.FEMALE} />
      },
      {
        path: `/match/${MATCH_TYPE.전체}`, title: strings.menu.match_list, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.전체} />
      },
      {
        path: `/match/${MATCH_TYPE.시도}`, title: strings.menu.match_try_list, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.시도} />
      },
      {
        path: `/match/${MATCH_TYPE.여성수락}`, title: strings.menu.match_female_approve, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.여성수락} />
      },
      {
        path: `/match/${MATCH_TYPE.남성수락}`, title: strings.menu.match_male_approve, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.남성수락} />
      },
      {
        path: `/match/${MATCH_TYPE.최종수락}`, title: strings.menu.match_final_approve, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.최종수락} />
      },
      {
        path: `/match/${MATCH_TYPE.만남일결정}`, title: strings.menu.match_date_approve, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.만남일결정} />
      },
      {
        path: `/match/${MATCH_TYPE.만남일당일}`, title: strings.menu.match_date_today, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.만남일당일} />
      },
      {
        path: `/match/${MATCH_TYPE.후기단계}`, title: strings.menu.match_review_step, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.후기단계} />
      },
      {
        path: `/match/${MATCH_TYPE.후기완료}`, title: strings.menu.match_review_finish, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.후기완료} />
      },/*
      {
        path: `/match/${MATCH_TYPE.진행중}`, title: strings.menu.match_going_list, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.진행중}/>
      },
      {
        path: '/match/review', title: strings.menu.match_review, exact: true, private: true,
        component: () => <MatchReview/>
      },*/
      {
        path: '/match/blame', title: strings.menu.match_blame, exact: true, private: true,
        component: () => <MatchBlame />
      },
      {
        path: `/match/${MATCH_TYPE.캔슬내역}`, title: strings.menu.match_cancel, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.캔슬내역} />
      },
      {
        path: `/match/${MATCH_TYPE.채팅내역_3일간}`, title: strings.menu.match_chat, exact: true, private: true,
        component: () => <MatchList type={MATCH_TYPE.채팅내역_3일간} />
      },
      /*{
        path: '/match/location', title: strings.menu.match_location, exact: true, private: true,
        component: () => <MatchLocation/>
      }*/
    ]
  },
  {
    path: '/meeting', icon: 'fa fa-users', title: strings.menu.meeting, partner: true,
    children: [
      {
        path: '/meeting/list', title: strings.menu.meeting_list, exact: true, private: true,
        component: () => <MeetingList />
      },
      {
        path: '/meeting/chat', title: strings.menu.meeting_chat, exact: true, private: true,
        component: () => <MeetingChat />
      },
      {
        path: '/meeting/review', title: strings.menu.meeting_review, exact: true, private: true,
        component: () => <MeetingReview />
      },
      {
        path: '/meeting/blame', title: strings.menu.meeting_blame, exact: true, private: true,
        component: () => <MeetingBlame />
      },
      {
        path: '/meeting/penalty', title: strings.menu.meeting_penalty, exact: true, private: true,
        component: () => <MeetingPenalty />
      }
    ]
  },
  {
    path: '/lounge', icon: 'fa fa-image', title: strings.menu.lounge, partner: true,
    children: [
      {
        path: '/lounge/list', title: strings.menu.lounge_list, exact: true, private: true,
        component: () => <LoungeList />
      },
      {
        path: '/lounge/comment', title: strings.menu.lounge_comment_list, exact: true, private: true,
        component: () => <LoungeComment />
      },
      {
        path: '/lounge/blame', title: strings.menu.lounge_blame, exact: true, private: true,
        component: () => <LoungeBlame />
      },
      {
        path: '/lounge/penalty', title: strings.menu.lounge_penalty, exact: true, private: true,
        component: () => <LoungePenalty />
      }
    ]
  },
  {
    path: '/iap', icon: 'fa fa-dollar-sign', title: strings.menu.iap,
    children: [
      {
        path: '/iap/log', title: strings.menu.iap_log, exact: true, private: true,
        component: () => <IapLog />
      },
      {
        path: '/iap/sta', title: strings.menu.iap_sta, exact: true, private: true,
        component: () => <IapSta />
      },
      /*{
        path: '/iap/ranking', title: strings.menu.iap_ranking, exact: true, private: true,
        component: () => <IapRanking/>
      },*/
    ],
  },
  {
    path: '/sta', icon: 'fa fa-chart-line', title: strings.menu.sta, partner: true,
    children: [
      {
        path: '/sta/login', title: strings.menu.sta_login, exact: true, private: true,
        component: () => <StaLogin />
      },
      {
        path: '/sta/signup', title: strings.menu.sta_signup, exact: true, private: true,
        component: () => <StaSignup />
      },
      {
        path: '/sta/hourly', title: strings.menu.sta_hourly, exact: true, private: true,
        component: () => <StaHourly />
      },
      /*{
        path: '/sta/matching', title: strings.menu.sta_matching, exact: true, private: true,
        component: () => <StaMatching/>
      },*/
    ],
  },
  {
    path: '/setting', icon: 'fa fa-cog', title: strings.menu.setting, partner: true,
    children: [
      {
        path: '/setting/version', title: strings.menu.setting_version, exact: true, private: true,
        component: () => <AppVersion />
      },
      {
        path: '/setting/setting', title: strings.menu.setting_setting, exact: true, private: true,
        component: () => <Setting />
      },
      {
        path: '/setting/term', title: strings.menu.setting_term, exact: true, private: true,
        component: () => <Term />
      },
      {
        path: '/setting/alias', title: strings.menu.setting_alias, exact: true, private: true,
        component: () => <Alias />
      },
      {
        path: '/setting/notice', title: strings.menu.setting_notice, exact: false, private: true,
        component: () => <Notice />
      },
      {
        path: '/setting/signup-msg', title: strings.menu.setting_signup_msg, exact: false, private: true,
        component: () => <SignupMsg />
      },
      // {
      //   path: '/setting/support', title: strings.menu.setting_support, exact: false, private: true,
      //   component: () => <Support/>
      // },
    ],
  },
  {
    path: '/partner', icon: 'fa fa-user-plus', title: strings.menu.partner, exact: true, private: true, partner: true,
    component: () => <PartnerList />
  },
];

// 슈퍼어드민/파트너에 따른 라우팅리스트를 리턴
const getFilteredRouteList = (isSuper = false, allRoutes = true) => {
  const routes = allRoutes ? [...Extra, ...Menu] : Menu;
  let result = [];
  for (const route of routes) {
    if (!isSuper && route.partner) {
      continue;
    }

    if (isSuper) {
      result.push(route);
    } else {
      if (route.hasOwnProperty('children')) {
        result.push({
          ...route,
          children: route.children.filter(subRoute => !subRoute.partner)
        });
      } else {
        result.push(route);
      }
    }
  }
  return result;
}

export default getFilteredRouteList;
