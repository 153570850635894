import $ from "jquery";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { useContext, useRef, useState } from "react";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import PenaltyPopup, { PenaltyType } from "../../lounge/popup/penalty-popup";
import { STATUS } from "../../../config/const";
import MeetingPopup from "../popup/meeting-popup";

const MeetingListFragment = ({ id = 0 }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.meeting;

  const searchColumnRef = useRef(searchOptions[0].value);
  const searchInputRef = useRef('');

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    id: 0
  });
  const showPopup = id => setPopup(prev => {
    return { ...prev, isOpen: true, id };
  });
  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  const onUser = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'meeting/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  const onDBDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'meeting/db/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  const [penalty, setPenaltyPopup] = useState({
    isOpen: false,
    type: PenaltyType.Meeting,
    user: null
  });
  const hidePenaltyPopup = () => setPenaltyPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });
  const onPenalty = item => {
    setPenaltyPopup(prev => {
      return {
        ...prev,
        isOpen: true,
        user: { id: item.user_id, nickname: item.user_nickname }
      }
    });
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={v => {
                searchColumnRef.current = v;
                refreshTable(true);
              }} />
            <SearchInput
              id='searchInput'
              onChange={v => searchInputRef.current = v}
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'user_profile', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).image_lightbox(value, () => {
                    context.showGallery(value.split('#').map(it => it.getFullUrl()));
                  }, item.user_single);
                }
              }, {
                targets: [columnNo++], data: 'user_nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(item.user_nickname, item.user_gender, () => onUser(item.user_id));
                }
              }, {
                targets: [columnNo++], data: 'user_level', orderable: false,
                render: value => strings.member.level_list[value],
              }, {
                targets: [columnNo++], data: 'photo', orderable: false,
                createdCell: (td, value) => {
                  $(td).image_lightbox(value, () => {
                    context.showGallery(value.split('#').map(it => it.getFullUrl()));
                  });
                }
              }, {
                targets: [columnNo++], data: 'title', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => showPopup(item.id));
                }
              }, {
                targets: [columnNo++], data: 'photo_public', orderable: false,
                render: value => value == STATUS.YES ? strings.meeting.public : strings.meeting.private
              }, {
                targets: [columnNo++], data: 'blame_cnt', orderable: false,
                render: value => value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
                render: value => value.dateFormat()
              }, {
                targets: [columnNo++], data: 'status', orderable: false,
                createdCell: (td, value, item) => {
                  switch (value) {
                    case STATUS.NORMAL:
                      $(td).html(`<span style="color: blue">${strings.meeting.in_progress}</span>`);
                      break;
                    case STATUS.DELETED:
                      $(td).html(`<span style="color: red">${strings.common.deleted}</span>`);
                      break;
                    case STATUS.FINISH:
                      if (item.chat_open == STATUS.NORMAL) {
                        $(td).html(`<span style="color: gray">${strings.meeting.canceled}</span>`);
                      } else if (item.chat_open == STATUS.FINISH) {
                        $(td).html(`<span style="color: gray">${strings.common.completed}</span>`);
                      } else {
                        $(td).html(`<span style="color: blue">${strings.meeting.in_progress}</span>`);
                      }
                      break;
                  }
                }
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html('');
                  let ind = -1;
                  if (item.status == STATUS.NORMAL) {
                    $(td).append(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.common.delete}</button>`)
                      .find(`button:eq(${++ind})`)
                      .on('click', () => onDelete(item.id));
                  }
                  $(td).append(`<button type='button' class='btn btn-outline-dark btn-sm m-2 text-nowrap'>${strings.lounge.penalty}</button>`)
                    .find(`button:eq(${++ind})`)
                    .on('click', () => onPenalty(item));
                  $(td).append(`<br/><button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.member.db_delete}</button>`)
                    .find(`button:eq(${++ind})`)
                    .on('click', () => onDBDelete(item.id));
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'meeting/list',
                data: (req) => {
                  req.user = id;
                  req.column = searchColumnRef.current; // $('#searchColumn').val();
                  req.keyword = searchInputRef.current; // $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.member.profile}</th>
              <th>{strings.common.nickname}</th>
              <th>{strings.member.type}</th>
              <th>{strings.meeting.thumbnail}</th>
              <th style={{ width: '30%' }}>{strings.common.title}</th>
              <th>{strings.meeting.photo_public}</th>
              <th>{strings.lounge.blame_cnt}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.status}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <MeetingPopup {...popup} onClose={() => {
        hidePopup();
        refreshTable(false);
      }} />
      <PenaltyPopup {...penalty} onClose={() => {
        hidePenaltyPopup();
      }} />
    </>
  );
}

export default MeetingListFragment;
