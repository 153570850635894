import $ from "jquery";
import { inject } from "mobx-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import DateTime from 'react-datetime';
import { withRouter } from "react-router-dom";
import Container from "../../components/control/container";
import DataTable from "../../components/control/data-table";
import SearchInput from "../../components/control/search-input";
import SearchSelect from "../../components/control/search-select";
import { FORMAT, PARTNER } from "../../config/const";
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";

const MatchReservationList = ({ rootStore, history }) => {

  const context = useContext(PageSettings);

  const [matchCount, setMatchCount] = useState(0);
  const [sendDate, setSendDate] = useState('');
  const [reservedDate, setReservedDate] = useState('');
  const [maxDeltaAge, setMaxDeltaAge] = useState(0);
  const [minDeltaAge, setMinDeltaAge] = useState(0);
  const [levelDelta, setLevelDelta] = useState(0);
  const [reservationStatus, setReservationStatus] = useState(0);

  const [checkedIds, setCheckedIds] = useState([]);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const searchOptions = strings.search_option.member;
  const searchColumnRef = useRef(searchOptions[0].value);
  const searchInputRef = useRef('');

  useEffect(() => {
    console.log("checkedIds", checkedIds);
  }, [checkedIds])

  useEffect(() => {
    getReservationSetting()
  }, [])

  const getReservationSetting = () => {
    context.get(
      'match/reservation-setting',
      {},
      response => {
        setMatchCount(response.match_count);
        setReservedDate(response.send_date);
        setMaxDeltaAge(response.max_delta_age);
        setMinDeltaAge(response.min_delta_age);
        setReservationStatus(response.status);
        setLevelDelta(response.level_delta);
      },
      false
    );
  }

  const confirmReservation = () => {
    if (sendDate == '') {
      context.addNotification(strings.modal.alert, "발송일을 선택해주세요.");
      return;
    }

    context.showConfirm("예약 확인을 진행하시겠습니까?\n(예약된 시간내에 카드가 자동발송되며 발송이 실패한 카드들은 실패사유와 함께 목록에 여전히 보이니 이는 수동으로 조처해주시기 바랍니다.)", res => {
      if (res !== true) {
        return;
      }

      context.post(
        'match/confirm-reservation',
        {
          send_date: sendDate
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          getReservationSetting();
        }
      );
    });
  }

  const cancelReservation = () => {
    context.showConfirm("예약 취소를 진행하시겠습니까?", res => {
      if (res !== true) {
        return;
      }

      context.post(
        'match/cancel-reservation',
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          getReservationSetting();
        }
      );
    });
  }

  const sendImmediately = () => {
    context.showConfirm("바로발송을 진행하시겠습니까?\n (발송완료된 카드들은 예약목록에서 보이지 않습니다. 발송이 실패한 카드들은 실패사유와 함께 목록에 여전히 보이니 이는 수동으로 조처해주시기 바랍니다.)", res => {
      if (res !== true) {
        return;
      }
      context.post(
        'match/send-reservation-immediately',
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          getReservationSetting();
          refreshTable()
        }
      );
    });
  }

  const onUser = user_id => {
    const showUserPopup = () => context.showUserPopup(user_id, () => refreshTable(false));

    if (rootStore.isSuper) {
      showUserPopup();
      return;
    }

    // 해당 유저가 파트너의 전담/공유회원인지를 체크
    context.get(
      'member/partner_type',
      {
        user: user_id
      },
      response => {
        if (response !== PARTNER.NONE) {
          showUserPopup();
        }
      },
      false
    );
  }

  const deleteReservation = id => {
    context.showConfirm(strings.match.delete_reservation_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `match/reservation/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  const deleteAllReservation = () => {
    context.showConfirm("매칭 예약목록을 전부 삭제하시겠습니까?", res => {
      if (res !== true) {
        return;
      }

      context.post(
        `match/delete-all-reservation`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  const deleteSelectedReservation = () => {
    if (checkedIds.length == 0) {
      context.addNotification(strings.modal.alert, "선택된 예약목록이 없습니다.");
      return;
    }

    context.showConfirm("선택된 예약목록을 삭제하시겠습니까?", res => {
      if (res !== true) {
        return;
      }

      context.post(
        `match/delete-selected-reservation`,
        {
          ids: checkedIds
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  const generateReservationList = () => {
    context.showConfirm("매칭 예약목록을 생성하시겠습니까?", res => {
      if (res !== true) {
        return;
      }
      context.post(
        'match/generate-reservation-list',
        {
          match_count: matchCount,
          max_delta_age: maxDeltaAge,
          min_delta_age: minDeltaAge,
          level_delta: levelDelta
        },
        () => {
          context.addNotification(strings.modal.alert, "생성이 완료되었습니다.");
          refreshTable(false);
        }
      );
    });
  }

  return (
    <Container>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline' style={{ alignItems: "flex-start" }}>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={v => {
                searchColumnRef.current = v;
                refreshTable(true);
              }} />
            <SearchInput
              id='searchInput'
              onChange={v => searchInputRef.current = v}
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
              onClick={() => refreshTable(true)}>{strings.common.search}</button>
            <table className="ml-md-2">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    여성1명당 매칭수 :
                  </td>
                  <td>
                    <input className="form-control" type='number' id='matchCount' value={matchCount} onChange={(e) => {
                      setMatchCount(e.target.value);
                    }} />
                  </td>
                </tr>
                <tr>
                  <td>
                    최대치의 추가나이 :
                  </td>
                  <td>
                    <input className="form-control" type='number' id='maxAge' value={maxDeltaAge} onChange={(e) => {
                      setMaxDeltaAge(e.target.value);
                    }} />
                  </td>
                </tr>
                <tr>
                  <td>
                    최소치의 추가나이 :
                  </td>
                  <td>
                    <input className="form-control" type='number' id='minAge' value={minDeltaAge} onChange={(e) => {
                      setMinDeltaAge(e.target.value);
                    }} />
                  </td>
                </tr>
                <tr>
                  <td>
                    등급차이 :
                  </td>
                  <td>
                    <input className="form-control" type='number' id='minAge' value={levelDelta} onChange={(e) => {
                      setLevelDelta(e.target.value);
                    }} />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button type='button' className='btn btn-success btn-sm mt-1'
                      onClick={() => generateReservationList()}>{"리스트생성"}</button>
                  </td>
                </tr>
              </tbody>
            </table>

            <button type='button' className='btn btn-success btn-sm ml-md-2'
              onClick={() => {
                history.push('/match/match');
              }}>{"매칭추가"}</button>

            <div className="form-inline ml-md-3" style={{ alignItems: "flex-start" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <tr>
                  <td>
                    발송일 :
                  </td>
                  <td>
                    <DateTime
                      dateFormat={FORMAT.DATE}
                      timeFormat={true}
                      inputProps={{ placeholder: "선택" }}
                      defaultValue={sendDate}
                      closeOnSelect={true}
                      onChange={(e) => {
                        if (typeof e === 'string') {
                          setSendDate(e);
                        } else {
                          setSendDate(e.format(FORMAT.DATE_TIME));
                        }
                      }} />
                  </td>
                </tr>
                <div className="mt-1" style={{ color: reservationStatus == 0 ? "red" : "blue" }}>
                  <span>{reservationStatus == 0 ? "예약대기중" : "발송대기중:"}</span>
                  {reservationStatus > 0 &&
                    <span className="ml-md-2">
                      {reservedDate} 발송 예정
                    </span>
                  }
                </div>
              </div>

              <button type='button' className='btn btn-success btn-sm ml-md-2'
                onClick={() => {
                  confirmReservation();
                }}>{"예약확정"}</button>
            </div>

            <button type='button' className='btn btn-danger btn-sm ml-md-2'
              onClick={() => {
                cancelReservation();
              }}>{"예약취소"}</button>

            <button type='button' className='btn btn-primary btn-sm ml-md-2'
              onClick={() => {
                sendImmediately()
              }}>바로발송</button>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <button type='button' className='btn btn-danger btn-sm'
            style={{ zIndex: 100, }}
            onClick={() => {
              deleteAllReservation()
            }}>전체삭제</button>
          <button type='button' className='btn btn-danger btn-sm ml-md-2'
            style={{ zIndex: 100, }}
            onClick={() => {
              deleteSelectedReservation()
            }}>선택삭제</button>
        </div>
        <div className='col-md-12'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).prepend('<input type="checkbox" style="width: 15px; margin-right:2px"' + (checkedIds.includes(value) ? ' checked ' : '') + ' />')
                    .find('input')
                    .on('change', () => {
                      setCheckedIds(prevCheckedIds => {
                        if (prevCheckedIds.includes(value)) {
                          return prevCheckedIds.filter(it => it !== value);
                        } else {
                          return [...prevCheckedIds, value];
                        }
                      });
                    });
                }
              }, {
                targets: [columnNo++], data: 'male_profile', orderable: false,
                createdCell: (td, value, item) => {
                  if (!value) {
                    $(td).html('');
                  } else {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    }, item.male_single);
                  }
                }
              }, {
                targets: [columnNo++], data: 'male_nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(
                    item.male_nickname,
                    item.male_gender,
                    () => onUser(item.male_id)
                  );
                }
              }, {
                targets: [columnNo++], data: 'male_birth', orderable: false,
                render: value => value.ageFormat()
              }, {
                targets: [columnNo++], data: 'male_job', orderable: false,
              }, {
                targets: [columnNo++], data: 'female_profile', orderable: false,
                createdCell: (td, value, item) => {
                  if (!value) {
                    $(td).html('');
                  } else {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    }, item.female_single);
                  }
                }
              }, {
                targets: [columnNo++], data: 'female_nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(
                    item.female_nickname,
                    item.female_gender,
                    () => onUser(item.female_id)
                  );
                }
              }, {
                targets: [columnNo++], data: 'female_birth', orderable: false,
                render: value => value.ageFormat()
              }, {
                targets: [columnNo++], data: 'female_job', orderable: false,
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
                render: value => value
              }, {
                targets: [columnNo++], data: 'failed_reason', orderable: false,
                render: value => value
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  let btnIndex = 0;
                  $(td).html(``);
                  if (rootStore.isSuper) {
                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button>`)
                      .find(`button:eq(${btnIndex++})`)
                      .on('click', () => deleteReservation(value));
                  }
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'match/reservation-list',
                data: (req) => {
                  req.column = searchColumnRef.current; // $('#searchColumn').val();
                  req.keyword = searchInputRef.current; // $('#searchInput').val();
                }
              },
            }}>
            <thead>
              <tr>
                <th rowSpan={2}>선택 #</th>
                <th colSpan={4}>{strings.member.male_member}</th>
                <th colSpan={4}>{strings.member.female_member}</th>
                <th rowSpan={2}>{strings.common.create_date}</th>
                <th rowSpan={2}>{strings.common.failed_reason}</th>
                <th rowSpan={2}>{strings.common.manage}</th>
              </tr>
              <tr>
                <th>{strings.member.profile}</th>
                <th>{strings.common.nickname}</th>
                <th>{strings.member.age}</th>
                <th>{strings.member.job}</th>
                <th>{strings.member.profile}</th>
                <th>{strings.common.nickname}</th>
                <th>{strings.member.age}</th>
                <th>{strings.member.job}</th>
              </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container >
  );
}

export default inject('rootStore')(withRouter(MatchReservationList));
