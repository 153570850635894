import { useContext, useEffect, useState } from "react";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { PARTNER } from "../../../config/const";

const MemberDetailPartner = ({ partner, onPartner }) => {

  const context = useContext(PageSettings);

  const [partnerList, setPartnerList] = useState([]);
  useEffect(() => {
    getPartnerList();
  }, []);

  const getPartnerList = () => {
    context.get(
      'partner/list',
      {
        keyword: '',
        start: 0,
        length: 0
      },
      response => {
        setPartnerList(response);
      }
    );
  }

  return (
    <div className='p-10 col-md-4'>
      <table className='table table-bordered'>
        <thead>
        <tr>
          <th>#</th>
          <th>{strings.member.name}</th>
          <th>{strings.partner.charge_partner}</th>
          <th>{strings.partner.share_partner}</th>
        </tr>
        </thead>
        <tbody>
        {
          partnerList.map((p, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>{p.nickname}</td>
              <td>
                <input
                  type='checkbox'
                  checked={partner.some(it => it.partner == p.id && it.type == PARTNER.CHARGE)}
                  value={p.id}
                  onChange={() => onPartner?.(p.id, PARTNER.CHARGE)}
                />
              </td>
              <td>
                <input
                  type='checkbox'
                  checked={partner.some(it => it.partner == p.id && it.type == PARTNER.SHARE)}
                  value={p.id}
                  onChange={() => onPartner?.(p.id, PARTNER.SHARE)}
                />
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  );
}

export default MemberDetailPartner;
