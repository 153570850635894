import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const GenderStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/gender',
      {},
      response => setData(response)
    );
  }, []);

  return (
    <div>
      <div className="widget widget-stats bg-teal height-100">
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.signup_ratio}</div>
            <div className="ml-auto" />
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.signup_ratio_male}</div>
            <div className="ml-auto">
              {
                data ? strings.formatString(
                  strings.dashboard.signup_ratio_data,
                  data.male_count.currencyFormat(),
                  data.male_active_count.currencyFormat(),
                  data.male_normal_count.currencyFormat(),
                  data.male_sleep_count.currencyFormat()
                ) : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.signup_ratio_female}</div>
            <div className="ml-auto">
              {
                data ? strings.formatString(
                  strings.dashboard.signup_ratio_data,
                  data.female_count.currencyFormat(),
                  data.female_active_count.currencyFormat(),
                  data.female_normal_count.currencyFormat(),
                  data.female_sleep_count.currencyFormat()
                ) : `-`
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenderStaFragment;
