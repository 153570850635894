import { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";

export const PenaltyType = {
  Meeting: 1,
  Lounge: 2,
};

const PenaltyPopup = ({ isOpen, type, user, onClose }) => {

  const context = useContext(PageSettings);
  const [day, setDay] = useState(1);
  const [reason, setReason] = useState('');

  const toggleModal = result => {
    onClose?.(result);
    setDay(1);
    setReason('');
  }

  const onApply = () => {
    context.showConfirm(strings.lounge.penalty_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'lounge/penalty',
        {
          user: user.id,
          type,
          day,
          reason
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          toggleModal();
        }
      );
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        [{strings.formatString(strings.common.mr, user?.nickname ?? '')}]
        &nbsp;{type == PenaltyType.Lounge ? strings.lounge.square : strings.meeting.meeting}
        &nbsp;{strings.lounge.penalty}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xl-12">
            <select className='form-control' onChange={e => setDay(e.target.value)}>
              <option value="1">1{strings.common.date}</option>
              <option value="3">3{strings.common.date}</option>
              <option value="7">7{strings.common.date}</option>
              <option value="15">15{strings.common.date}</option>
              <option value="30">30{strings.common.date}</option>
            </select>
            <input type='text' autoComplete='off' className='form-control m-t-10'
                   placeholder={strings.lounge.penalty_placeholder}
                   value={reason}
                   onChange={e => setReason(e.target.value)}/>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onApply}>{strings.modal.apply}</button>
      </ModalFooter>
    </Modal>
  );
};

export default PenaltyPopup;
