import React, { useContext, useRef, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import Container from "../../components/control/container";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import { STATUS } from "../../config/const";
import LoungePopup from "./popup/lounge-popup";
import PenaltyPopup, { PenaltyType } from "./popup/penalty-popup";

const LoungeList = () => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.meeting;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    id: 0
  });
  const showPopup = id => setPopup(prev => {
    return { ...prev, isOpen: true, id };
  });
  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  const onUser = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'lounge/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  const toggleBest = (id, best) => {
    const content = best == STATUS.YES ? strings.lounge.best_confirm : strings.lounge.best_release_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'lounge/best',
        {
          id, best
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  }

  const [penalty, setPenaltyPopup] = useState({
    isOpen: false,
    type: PenaltyType.Lounge,
    user: null
  });
  const hidePenaltyPopup = () => setPenaltyPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });
  const onPenalty = item => {
    setPenaltyPopup(prev => {
      return {
        ...prev,
        isOpen: true,
        user: { id: item.user_id, nickname: item.user_nickname }
      }
    });
  };

  return (
    <Container title={strings.menu.lounge_list}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <label className='form-control-label m-r-5'>{strings.lounge.category}</label>
            <SearchSelect
              id='category'
              value={''}
              data={context.getSettingList('lounge_category').map(it => {
                return { value: it.code, name: it.name };
              })}
              containerStyle={{ marginRight: 15 }}
              onChange={() => refreshTable(true)}/>
            <label className='form-control-label m-r-5'>{strings.lounge.mini_public}</label>
            <SearchSelect
              id='public'
              value={''}
              data={[
                { value: '', name: strings.common.total },
                { value: STATUS.YES, name: strings.lounge.public },
                { value: STATUS.NO, name: strings.lounge.private },
              ]}
              containerStyle={{ marginRight: 15 }}
              onChange={() => refreshTable(true)}/>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)}/>
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)}/>
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'user_nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(item.user_nickname, item.user_gender, () => onUser(item.user_id));
                }
              }, {
                targets: [columnNo++], data: 'user_level', orderable: false,
                render: value => strings.member.level_list[value]
              }, {
                targets: [columnNo++], data: 'user_status', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).user_status(item, 'user_');
                }
              }, {
                targets: [columnNo++], data: 'photo', orderable: false,
                createdCell: (td, value) => {
                  if (!value) {
                    $(td).html('');
                  } else {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    });
                  }
                }
              }, {
                targets: [columnNo++], data: 'title', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .addClass('text-left')
                    .html(`(${context.getSettingName(item.category, 'lounge_category')})<br/>`)
                    .append(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => showPopup(item.id));
                }
              }, {
                targets: [columnNo++], data: 'mini_public', orderable: false,
                render: value => value == STATUS.YES ? strings.lounge.public : strings.lounge.private
              }, {
                targets: [columnNo++], data: 'best', orderable: false,
                createdCell: (td, value, item) => {
                  if (item.status == STATUS.DELETED) {
                    $(td).html('-');
                    return;
                  }

                  if (value == STATUS.YES) {
                    $(td)
                      .html(`<p class="text-danger m-b-0">${strings.lounge.best}</p>`)
                      .append(`[<a href="javascript:void(0);">${strings.lounge.best_release}</a>]`)
                      .find('a')
                      .bind('click', () => {
                        toggleBest(item.id, STATUS.NO);
                      });
                  } else {
                    $(td).html(`[<a href="javascript:void(0);">${strings.lounge.best_setting}</a>]`)
                      .find('a')
                      .bind('click', () => {
                        toggleBest(item.id, STATUS.YES);
                      });
                  }
                }
              }, {
                targets: [columnNo++], data: 'visit_cnt', orderable: false,
                render: value => value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'like_cnt', orderable: false,
                render: value => value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'cmt_cnt', orderable: false,
                render: value => value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'blame_cnt', orderable: false,
                render: value => value.currencyFormat()
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
                render: value => value.dateFormat()
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  if (item.status == STATUS.NORMAL) {
                    $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.common.delete}</button>`)
                      .find('button:eq(0)')
                      .on('click', () => onDelete(item.id))
                      .end()
                      .append(`<button type='button' class='btn btn-outline-dark btn-sm m-2 text-nowrap'>${strings.lounge.penalty}</button>`)
                      .find('button:eq(1)')
                      .on('click', () => onPenalty(item));
                  } else {
                    $(td).html(`<label class="text-grey">${strings.common.deleted}</label>`);
                  }
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'lounge/list',
                data: (req) => {
                  req.category = $('#category').val();
                  req.mini_public = $('#public').val();
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.common.nickname}</th>
              <th>{strings.member.type}</th>
              <th>{strings.common.status}</th>
              <th>{strings.common.image}</th>
              <th style={{ width: '30%' }}>{strings.common.title}</th>
              <th>{strings.lounge.mini_public}</th>
              <th>{strings.lounge.best}</th>
              <th>{strings.lounge.visit_cnt}</th>
              <th>{strings.lounge.like_cnt}</th>
              <th>{strings.lounge.cmt_cnt}</th>
              <th>{strings.lounge.blame_cnt}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody/>
          </DataTable>
        </div>
      </div>
      <LoungePopup {...popup} onClose={() => {
        hidePopup();
        refreshTable(false);
      }}/>
      <PenaltyPopup {...penalty} onClose={() => {
        hidePenaltyPopup();
      }} />
    </Container>
  );

}

export default LoungeList;
