import { action, observable } from 'mobx';
import jwt_decode from 'jwt-decode';

export const rootStore = observable({

  me: null,
  setting: null,

  signIn: ({ token }) => {
    const user_info = jwt_decode(token);
    rootStore.me = { ...user_info, token };
    sessionStorage.setItem('token', token);
  },

  signOut: () => {
    rootStore.me = null;
    sessionStorage.setItem('token', null);
  },

  saveSetting: setting => {
    rootStore.setting = setting;
    sessionStorage.setItem('setting', JSON.stringify(setting));
  },

  get getSetting() {
    if (rootStore.setting == null) {
      try {
        rootStore.setting = JSON.parse(sessionStorage.getItem('setting'));
      } catch (e) {
        console.log(e);
      }
    }
    return rootStore.setting;
  },

  get getMe() {
    if (rootStore.me == null) {
      try {
        const token = sessionStorage.getItem('token');
        if (token) {
          const user_info = jwt_decode(token);
          rootStore.me = { ...user_info, token };
        }
      } catch (e) {
        console.log(e);
      }
    }
    return rootStore.me;
  },

  get isSignedIn() {
    return rootStore.getMe !== null;
  },

  get token() {
    return rootStore.getMe?.token ?? '';
  },

  get nickname() {
    return rootStore.getMe?.user_name ?? '';
  },

  get isSuper() {
    return rootStore.getMe?.super > 0;
  },

}, {
  signIn: action,
  signOut: action,
  saveSetting: action
});
