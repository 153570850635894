import React, { useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import SidebarNavList from './sidebar-nav-list.jsx';
import getFilteredRouteList from '../../config/page-route';
import { inject, observer } from "mobx-react";

const SidebarNav = ({ rootStore }) => {

  const [active, setActive] = useState(-1);
  const [clicked, setClicked] = useState(-1);
  const [menus] = useState(getFilteredRouteList(rootStore.isSuper, false));

  const handleExpand = (e, i, match) => {
    e.preventDefault();

    if (clicked === -1 && match) {
      setActive(-1);
      setClicked(1);
    } else {
      setActive(prev => prev === i ? -1 : i);
      setClicked(1);
    }
  }

  return (
    <ul className="nav p-t-10">
      {/*<li className="nav-header">&nbsp;</li>*/}
      {menus.map((menu, i) => (
        <Route path={menu.path} exact={menu.exact} key={i} children={({ match }) => (
          <SidebarNavList
            data={menu}
            key={i}
            expand={(e) => handleExpand(e, i, match)}
            active={i === active}
            clicked={clicked}
          />
        )} />
      ))}
    </ul>
  );
}

export default withRouter(inject('rootStore')(observer(SidebarNav)));
