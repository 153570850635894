import { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";

const RejectPopup = ({ isOpen, title, reasons, text, onSave, onClose }) => {

  const context = useContext(PageSettings);
  const [reason, setReason] = useState('');

  const toggleModal = result => {
    onClose?.(result);
    setReason('');
  }

  const onApply = () => {
    if (reason == '') {
      context.addNotification(strings.modal.alert, strings.modal.input_reason, 'warning');
      return;
    }

    onSave?.(reason);
    setReason('');
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} onOpened={() => setReason(text)}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {title} {strings.common.reject}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xl-12">
            <select className='form-control' onChange={e => setReason(e.target.value)}>
              <option value="">{strings.member.select_reason}</option>
              {reasons.map((it, idx) => <option key={idx}>{it}</option>)}
            </select>
            <input type='text' autoComplete='off' className='form-control m-t-10'
                   placeholder={strings.member.direct_input}
                   value={reason}
                   onChange={e => setReason(e.target.value)}/>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onApply}>{strings.modal.apply}</button>
      </ModalFooter>
    </Modal>
  );
};

export default RejectPopup;
