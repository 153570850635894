import React, { useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';
import { inject, observer } from "mobx-react";

import strings from "../../lang/strings";
import getFilteredRouteList from '../../config/page-route';

const Content = ({ history, rootStore }) => {

  const [routes] = useState(getFilteredRouteList(true));

  const setTitle = path => {
    let pageTitle = strings.admin;

    const route = routes.find(it => it.path === path || it.children?.some(c => c.path === path));
    if (route) {
      if (route.path === path) {
        pageTitle = route.title;
      } else {
        pageTitle = route.children?.find(it => it.path === path)?.title;
      }
    }

    document.title = pageTitle;
  };

  const getRoute = (route, index) =>
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      component={
        !route.private || rootStore.isSignedIn ?
          route.partner && !rootStore.isSuper ? () => <Redirect to='/dashboard' /> : route.component
          :
          () => <Redirect to='/login' />
      }
    />;

  useEffect(() => {
    history.listen(() => {
      setTitle(history.location.pathname);
    });

    setTitle(history.location.pathname);
  });

  return (
    <div className={'content'}>
      <Switch>
        {routes.map((route, index) => {
          if (route.hasOwnProperty('children')) {
            return route.children.map((subRoute, subIndex) =>
              getRoute(subRoute, subIndex));
          } else {
            return getRoute(route, index);
          }
        })}
        <Route component={() => <Redirect to='/dashboard' />} />
      </Switch>
    </div>
  )
}

export default withRouter(inject('rootStore')(observer(Content)));
