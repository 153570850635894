import React, { useContext, useRef, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import Container from "../../components/control/container";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import $ from "jquery";
import { STATUS } from "../../config/const";
import ReviewPopup from "./popup/review-popup";
import MeetingPopup from "./popup/meeting-popup";

const MeetingReview = () => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.meeting;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onUser = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  const [meetingPopup, setMeetingPopup] = useState({
    isOpen: false,
    id: 0
  });
  const hideMeetingPopup = () => setMeetingPopup(prev => {
    return {
      ...prev,
      isOpen: false
    }
  });
  const onMeetingDetail = id => {
    setMeetingPopup(prev => {
      return {
        ...prev,
        isOpen: true,
        id
      };
    });
  }

  const [reviewPopup, setReviewPopup] = useState({
    isOpen: false,
    id: 0
  });
  const hideReviewPopup = () => setReviewPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });
  const onReviewDetail = review_id => {
    setReviewPopup(prev => {
      return {
        ...prev,
        isOpen: true,
        id: review_id
      };
    });
  }

  return (
    <Container title={strings.menu.meeting_review}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)}/>
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)}/>
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'email', orderable: false,
              }, {
                targets: [columnNo++], data: 'profile', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).image_lightbox(value, () => {
                    context.showGallery(value.split('#').map(it => it.getFullUrl()));
                  }, item.single);
                }
              }, {
                targets: [columnNo++], data: 'nickname', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(item.nickname, item.gender, () => onUser(item.id));
                }
              }, {
                targets: [columnNo++], data: 'status', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).user_status(item);
                }
              }, {
                targets: [columnNo++], data: 'title', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<a href="javascript:;">${value}</a>`)
                    .find('a:eq(0)')
                    .on('click', () => onMeetingDetail(item.meeting_id));
                }
              }, {
                targets: [columnNo++], data: 'success', orderable: false,
                render: value => value == STATUS.YES ?
                  `<span class="text-info">${strings.meeting.meet_success}</span>`
                  :
                  `<span class="text-danger">${strings.meeting.meet_fail}</span>`
              }, {
                targets: [columnNo++], data: 'content', orderable: false,
                render: value => value.$nl2br()
              }, {
                targets: [columnNo++], data: 'review_id', orderable: false,
                createdCell: (td, value) => {
                  $(td).html(`<button type='button' class='btn btn-outline-info btn-sm m-2 text-nowrap'>${strings.meeting.review_detail}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onReviewDetail(value));
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'meeting/review_list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.member.id}</th>
              <th>{strings.member.profile}</th>
              <th>{strings.common.nickname}</th>
              <th>{strings.member.status}</th>
              <th style={{width: '25%'}}>{strings.common.title}</th>
              <th>{strings.meeting.is_meet}</th>
              <th style={{width: '25%'}}>{strings.meeting.problem}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody/>
          </DataTable>
        </div>
      </div>
      <MeetingPopup {...meetingPopup} onClose={() => {
        hideMeetingPopup();
        refreshTable(false);
      }}/>
      <ReviewPopup {...reviewPopup} onClose={() => {
        hideReviewPopup();
      }}/>
    </Container>
  );

}

export default MeetingReview;
