import { useState } from "react";
import strings from "../../../lang/strings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classnames from "classnames";
import LoungePopupInfo from "./lounge-popup-info";
import LoungePopupComment from "./lounge-popup-comment";
import LoungePopupReport from "./lounge-popup-report";

const LoungePopup = ({ isOpen, id, onClose }) => {

  const [activeTab, setActiveTab] = useState(0);

  const toggleModal = result => {
    onClose?.(result);
  }

  const toggleTab = tab => {
    setActiveTab(tab);
  }

  const tabs = [
    { title: strings.lounge.lounge_info, component: () => <LoungePopupInfo id={id}/> },
    { title: strings.lounge.comment_info, component: () => <LoungePopupComment id={id}/> },
    { title: strings.lounge.report_info, component: () => <LoungePopupReport id={id}/> }
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} onOpened={() => setActiveTab(0)} size={'lg'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.lounge.detail}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xl-3">
            {tabs.map((menu, idx) => (
              <button
                key={`menu-${idx}`} type="button" onClick={() => toggleTab(idx)}
                className={classnames(
                  'width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1',
                  { active: activeTab == idx }
                )}>
                {menu.title}
              </button>
            ))}
          </div>
          <div className="col-xl-9">
            {tabs.map((menu, idx) => (
              activeTab == idx &&
              <div key={`frg-${idx}`}
                   style={{ maxHeight: 'calc(100vh - 200px)', overflowX: 'hidden', overflowY: 'auto' }}>
                {menu.component()}
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
      </ModalFooter>
    </Modal>
  );
}

export default LoungePopup;
