import { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import PerfectScrollbar from "react-perfect-scrollbar";
import { STATUS } from "../../../config/const";
import moment from "moment";
import ImageSelect from "../../../components/control/image-select";


const ConciergePopup = ({ isOpen, user, onClose }) => {

  const context = useContext(PageSettings);
  const scrollRef = useRef();
  const timer = useRef(0);
  const imageRef = useRef();

  const listCountRef = useRef(0);
  const [list, setList] = useState([]);
  const [text, setText] = useState('');
  useEffect(() => {
    if (listCountRef.current != list.length && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      listCountRef.current = list.length;
    }
  }, [list]);

  useEffect(() => {
    moment.locale('ko');

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    }
  }, []);

  const toggleModal = result => {
    onClose?.(result);
    if (timer.current) {
      clearInterval(timer.current);
    }
  }

  const onSend = (image = '') => {
    if (text == '' && image == '') {
      return;
    }
    console.log('>>>> image: ', image);
    context.post(
      'member/concierge/send',
      {
        id: user?.id,
        text,
        image
      },
      () => {
        setText('');
        getMessageList();
      }
    );
  }

  const onOpen = () => {
    timer.current = setInterval(() => getMessageList(), 4000);
    getMessageList();
  }

  const onImageSelect = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let formData = new FormData();
    formData.append('file', file, file.name);

    context.post(
      'upload/images/concierge',
      formData,
      response => {
        onSend(response.image);
      }
    );
  };

  const getMessageList = () => {
    context.get(
      'member/concierge/chat_list',
      {
        id: user?.id
      },
      response => setList(response),
      false
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} onOpened={onOpen} size={'md'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.menu.member_concierge} ({user?.nickname}, {user?.gender?.genderStr()}, {user?.birth?.ageFormat()}, {user?.location})
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className='col-md-12 p-r-0 p-l-0'>
            <PerfectScrollbar containerRef={ref => scrollRef.current = ref} className="chats"
                              style={{ height: '400px' }} options={{ suppressScrollX: true }}>
              {
                list.map((it, idx) => (
                  <div key={idx} className={it.is_send == STATUS.YES ? 'left' : 'right'}>
                    <div className='d-flex flex-row align-items-start'>
                      {
                        it.is_send == STATUS.YES &&
                        <img src={user?.profile?.split('#')[0].getFullUrl().getThumbnailUrl()}
                             style={{ width: 40, height: 40, borderRadius: 20, marginRight: 8 }} />
                      }
                      <div className="message">
                        {
                          it.content != '' &&
                          it.content.split('\n').map((line, i) => <p className='m-b-0' key={`line-${i}`}>{line}</p>)
                        }
                        {
                          it.image && it.image != '' &&
                          <img
                            src={it.image.getFullUrl().getThumbnailUrl()}
                            style={{ width: 'auto', height: 80, cursor: 'pointer' }}
                            onClick={() => context.showGallery([it.image.getFullUrl()], 0)} />
                        }
                      </div>
                    </div>
                    <span className="date-time">{moment(it.create_date).fromNow()}</span>
                  </div>
                ))
              }
            </PerfectScrollbar>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='width-full'>
          <div className="input-group">
            <span className="input-group-prepend">
              <button className="btn btn-default btn-sm width-50" type="button" onClick={() => imageRef?.current.click()}>
                <i className='fa fa-image' />
              </button>
              <input type='file' accept='image/*' className='hide'
                     onChange={onImageSelect}
                     ref={imageRef}/>
            </span>
            <textarea
              rows="2"
              className="form-control"
              value={text}
              maxLength={1500}
              placeholder={strings.member.msg_placeholder}
              onChange={e => setText(e.target.value)} />
            <span className="input-group-append">
              <button className="btn btn-primary btn-sm width-50" type="button" onClick={() => onSend()}>
                {strings.member.send}
              </button>
            </span>
            <button className="btn btn-default btn-sm width-50" onClick={getMessageList}>
              <i className='fa fa-sync' />
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConciergePopup;
