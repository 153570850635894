import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import strings from "../../../lang/strings";
import { MATCH_LOG_STATUS, MATCH_STATUS } from "../../../config/const";
import { PageSettings } from "../../../config/page-settings";
import MatchChatPopup from "./chat-popup";

const MatchDetailPopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);
  const toggleModal = result => onClose?.(result);

  const [info, setInfo] = useState({});
  const [popup, togglePopup] = useState(false);
  useEffect(() => {
    if (id < 1) {
      return;
    }

    context.get(
      'match/detail/' + id,
      {},
      response => setInfo(response)
    );
  }, [id]);

  // 일정선제안자
  const schedule_user = info.log?.find(it => it.status == MATCH_STATUS.SUGGEST_SCHEDULE)?.member ?? 0;

  const getStepName = item => {
    switch (item.status) {
      case MATCH_STATUS.PENDING:
        return strings.match.match_step.pending;
      case MATCH_STATUS.FEMALE_WANT_SEE:
      case MATCH_STATUS.FEMALE_PASS:
        return strings.match.match_step.female_card;
      case MATCH_STATUS.MALE_WANT_SEE:
      case MATCH_STATUS.MALE_PASS:
        return strings.match.match_step.male_card;
      case MATCH_STATUS.CANCELED:
        return strings.match.match_step[item.member == info.male.id ? 'male_card' : 'female_card'];
      case MATCH_STATUS.SEND_FAVOR_INFO:
      case MATCH_STATUS.FAVOR_INFO_NO_REPLY:
        return strings.match.match_step.send_info;
      case MATCH_STATUS.SUGGEST_SCHEDULE:
      case MATCH_STATUS.OK_SCHEDULE:
      case MATCH_STATUS.SCHEDULE_ACCEPT_NO_REPLY:
      case MATCH_STATUS.SCHEDULE_NOT_SELECTED:
        return schedule_user == item.member ?
          strings.match.match_step.schedule :
          strings.match.match_step.counter_schedule;
      case MATCH_STATUS.SCHEDULE_NO_REPLY:
        return strings.match.match_step.schedule;
      case MATCH_STATUS.SUGGEST_LOCATION:
      case MATCH_STATUS.LOCATION_NO_REPLY:
        return strings.match.match_step.location;
      case MATCH_STATUS.CHAT_OPEN:
        return strings.match.match_step.chat_open;
      case MATCH_LOG_STATUS.CHAT_ROOM_PAUSE:
        return strings.match.match_step.chat_pause;
      case MATCH_STATUS.CHAT_ROOM_LEAVE:
        return strings.match.match_step.leave;
      case MATCH_STATUS.CHAT_SCHEDULE_CANCEL:
        return strings.match.match_step.leave_schedule;
      case MATCH_STATUS.USER_BLAME:
        return strings.match.match_step.blame;
      case MATCH_LOG_STATUS.SCHEDULE_IN_CHAT:
        const schedule_cnt = info.log?.filter(it => it.id < item.id && it.status == MATCH_LOG_STATUS.SCHEDULE_IN_CHAT)?.length;
        return strings.formatString(strings.match.match_step.change_schedule, schedule_cnt + 1);
      case MATCH_LOG_STATUS.VOICE_CALL:
        const call_cnt = info.log?.filter(it => it.id < item.id && it.status == MATCH_LOG_STATUS.VOICE_CALL)?.length;
        return strings.formatString(strings.match.match_step.voice_call, call_cnt + 1);
      case MATCH_STATUS.REVIEW_REQUIRE:
        return item.member == info.male.id ? strings.match.match_step.review_male : strings.match.match_step.review_female;
      case MATCH_LOG_STATUS.REACTIVATE:
        return strings.match.match_step.reactivate;
      case MATCH_LOG_STATUS.REACTIVATE_OK:
        return strings.match.match_step.reactivate_accept;
      case MATCH_LOG_STATUS.SCHEDULE_REACTIVATE:
        const schedule_cnt1 = info.log?.filter(it => it.id < item.id && it.status == MATCH_LOG_STATUS.SCHEDULE_REACTIVATE)?.length;
        return strings.formatString(strings.match.match_step.reactivate_schedule, schedule_cnt1 + 1);
      case MATCH_LOG_STATUS.CONTRACT_SEND:
        const contract_send_cnt = info.log?.filter(it => it.id < item.id && it.status == MATCH_LOG_STATUS.CONTRACT_SEND)?.length;
        return strings.formatString(strings.match.match_step.send_contract, contract_send_cnt + 1);
      case MATCH_LOG_STATUS.CONTRACT_ACCEPT:
        const contract_accept_cnt = info.log?.filter(it => it.id < item.id && it.status == MATCH_LOG_STATUS.CONTRACT_ACCEPT)?.length;
        return strings.formatString(strings.match.match_step.accept_contract, contract_accept_cnt + 1);
      case MATCH_LOG_STATUS.DIRECT_SEND:
        return strings.match.match_step.send_direct;
      case MATCH_LOG_STATUS.DIRECT_ACCEPT:
        return strings.match.match_step.accept_direct;
      case MATCH_STATUS.FINAL_CONFIRM:
      case MATCH_STATUS.FINAL_CONFIRM_CANCEL:
      case MATCH_STATUS.CONFIRM_NO_REPLY:
        return strings.match.match_step.final_confirm;
      case MATCH_STATUS.CHAT_3_DAYS_OVER:
        return strings.match.match_step.chat_3_days_over;
      case MATCH_LOG_STATUS.CURATOR_NEW:
        return strings.match.match_step.new_curator;
      case MATCH_LOG_STATUS.CURATOR_MALE_PASS:
      case MATCH_LOG_STATUS.CURATOR_MALE_WANT:
        return strings.match.match_step.male_card;
      case MATCH_LOG_STATUS.CURATOR_ADMIN_DENY:
        return strings.match.match_step.admin_deny;
      default:
        return '';
    }
  }

  const getPathName = item => {
    switch (item.status) {
      case MATCH_STATUS.FEMALE_WANT_SEE:
      case MATCH_STATUS.MALE_WANT_SEE:
        return strings.match.match_path.want_see;
      case MATCH_STATUS.FEMALE_PASS:
      case MATCH_STATUS.MALE_PASS:
        return strings.match.match_path.pass;
      case MATCH_STATUS.FINAL_CONFIRM:
        return strings.match.match_path.confirm;
      case MATCH_STATUS.FINAL_CONFIRM_CANCEL:
        return strings.match.match_path.confirm_cancel;
      case MATCH_STATUS.SEND_FAVOR_INFO:
        return strings.match.match_path.send_info;
      case MATCH_STATUS.SUGGEST_SCHEDULE:
        const prev_schedule_count = info.log?.filter(it => it.id < item.id && it.status == MATCH_STATUS.SUGGEST_SCHEDULE)?.length;
        return prev_schedule_count < 1 ?
          strings.match.match_path.send_schedule :
          strings.formatString(strings.match.match_path.counter_schedule, prev_schedule_count);
      case MATCH_STATUS.OK_SCHEDULE:
        return strings.match.match_path.choose_schedule;
      case MATCH_STATUS.SCHEDULE_NOT_SELECTED:
        return strings.match.match_path.no_schedule;
      case MATCH_STATUS.SUGGEST_LOCATION:
        return strings.match.match_path.location;
      case MATCH_STATUS.CANCELED:
      case MATCH_STATUS.CONFIRM_NO_REPLY:
      case MATCH_STATUS.FAVOR_INFO_NO_REPLY:
      case MATCH_STATUS.SCHEDULE_NO_REPLY:
      case MATCH_STATUS.SCHEDULE_ACCEPT_NO_REPLY:
      case MATCH_STATUS.LOCATION_NO_REPLY:
        return strings.match.match_path.no_reply;
      case MATCH_LOG_STATUS.CURATOR_MALE_PASS:
        return strings.match.match_path.pass;
      case MATCH_LOG_STATUS.CURATOR_MALE_WANT:
        return strings.match.match_path.want_see;
      default:
        return '';
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} size={'xl'} toggle={toggleModal}>
        <ModalHeader className="popup-title" toggle={toggleModal}>{strings.match.detail}</ModalHeader>
        <ModalBody>
          <div className="row justify-content-end">
            <div className="col-xl-4">
              <table className='table table-profile'>
                <thead>
                <tr>
                  <th/>
                  <th>{strings.common.male}</th>
                  <th>{strings.common.female}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>{strings.member.id}</th>
                  <th>{info.male?.id}</th>
                  <th>{info.female?.id}</th>
                </tr>
                <tr>
                  <th>{strings.member.email}</th>
                  <td>{info.male?.email}</td>
                  <td>{info.female?.email}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-12'>
              <table className='table table-bordered'>
                <thead>
                <tr>
                  <th>#</th>
                  <th style={{ width: '18%' }}>{strings.match.step}</th>
                  <th style={{ width: '15%' }}>{strings.match.path}</th>
                  <th style={{ width: '12%' }}>{strings.match.time}</th>
                  <th style={{ width: '6%' }}>{strings.match.user}</th>
                  <th style={{ width: '6%' }}>{strings.match.key}</th>
                  <th>{strings.match.content}</th>
                  <th>{strings.match.refund}</th>
                </tr>
                </thead>
                <tbody>
                {
                  info.log?.length > 0 ?
                    info.log?.map((it, idx) =>
                      <tr key={idx}>
                        <th>{idx + 1}</th>
                        <td>{getStepName(it)}</td>
                        <td>{getPathName(it)}</td>
                        <td>{it.create_date}</td>
                        <td>{it.member ?? ''}</td>
                        <td>{it.key ?? '-'}</td>
                        <td>
                          {
                            it.status == MATCH_STATUS.CHAT_OPEN ?
                              <button className='btn btn-sm btn-success' onClick={() => togglePopup(true)}>
                                {strings.match.match_step.chatting}
                              </button>
                              :
                              it.content.nl2br()
                          }
                        </td>
                        <td>{it.refund}</td>
                      </tr>
                    )
                    :
                    <tr>
                      <td colSpan={8}>{strings.match.no_log}</td>
                    </tr>
                }
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        </ModalFooter>
      </Modal>
      <MatchChatPopup
        isOpen={popup}
        id={info.id}
        male={info.male}
        female={info.female}
        onClose={() => togglePopup(false)}/>
    </>
  );
};

export default MatchDetailPopup;
