import MeetingListFragment from "../../meeting/fragment/meeting-list-fragment";
import { inject } from "mobx-react";

const MemberDetailPopupMeeting = ({ user, rootStore }) => {

  /*const context = useContext(PageSettings);

  const [count, setCount] = useState(0);
  useEffect(() => {
    context.get(
      'member/meeting_info',
      {
        id: user.id
      },
      response => {
        setCount(response.count);
      },
      false
    )
  }, []);*/

  if (!rootStore.isSuper) {
    return null;
  }

  return (
    <MeetingListFragment id={user?.id} />
  );
};

export default inject('rootStore')(MemberDetailPopupMeeting);
