import { useContext, useEffect, useState } from "react";

import strings from "../../lang/strings";
import { PageSettings } from "../../config/page-settings";
import Container from "../../components/control/container";

const AppVersion = () => {

  const context = useContext(PageSettings);

  const [list, setList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    context.get(
      'setting/version',
      {},
      response => {
        setList(response);
      }
    );
  }

  const saveVersion = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'setting/version',
        { list },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          getList();
        }
      );
    });
  }

  return (
    <Container title={strings.menu.setting_version}>
      <div className='col-xl-8 col-lg-10 pl-0 pr-0'>
        <button type="button" className="btn btn-success btn-sm pull-right m-b-10" onClick={saveVersion}>
          {strings.common.save}
        </button>
        <table className="table table-bordered table-td-valign-middle table-th-valign-middle">
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.setting.platform}</th>
            <th style={{ width: '10%' }}>{strings.setting.version_name}</th>
            <th style={{ width: '10%' }}>{strings.setting.version_code}</th>
            <th style={{ width: '10%' }}>{strings.setting.min_version}</th>
            <th style={{ width: '50%' }}>{strings.setting.app_link}</th>
            <th>{strings.setting.in_review}</th>
          </tr>
          </thead>
          <tbody>
          {list.map((it, idx) => (
            <tr key={idx}>
              <td>{it.id}</td>
              <td>{it.platform == 'google' ? 'Android' : 'iOS'}</td>
              <td>
                <input
                  type='text' autoComplete='off' className='form-control'
                  value={it.version_name || ''}
                  onChange={e => setList(prev => prev.map(item => {
                    if (it.id == item.id) {
                      item.version_name = e.target.value;
                    }
                    return item;
                  }))} />
              </td>
              <td>
                <input
                  type='text' autoComplete='off' className='form-control'
                  value={it.version_code || ''}
                  onChange={e => setList(prev => prev.map(item => {
                    if (it.id == item.id) {
                      item.version_code = e.target.value;
                    }
                    return item;
                  }))} />
              </td>
              <td>
                <input
                  type='text' autoComplete='off' className='form-control'
                  value={it.min_version || ''}
                  onChange={e => setList(prev => prev.map(item => {
                    if (it.id == item.id) {
                      item.min_version = e.target.value;
                    }
                    return item;
                  }))} />
              </td>
              <td>
                <input
                  type='text' autoComplete='off' className='form-control'
                  value={it.app_link || ''}
                  onChange={e => setList(prev => prev.map(item => {
                    if (it.id == item.id) {
                      item.app_link = e.target.value;
                    }
                    return item;
                  }))} />
              </td>
              <td>
                <select
                  className='form-control'
                  value={it.in_review}
                  onChange={e => setList(prev => prev.map(item => {
                    if (it.id == item.id) {
                      item.in_review = e.target.value;
                    }
                    return item;
                  }))}>
                  <option value='Y'>{strings.modal.ok}</option>
                  <option value='N'>{strings.modal.no}</option>
                </select>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </Container>
  );

}

export default AppVersion;
