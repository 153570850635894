import strings from "../../../lang/strings";

const MemberDetailPopupApp = ({ user }) => {
  return (
    <div>
      <div className="row">
        <label>{strings.member.installed_version} : {user?.version_name}({user?.version_code})</label>
      </div>
      <div className="row">
        <label>{strings.member.last_update_time} ({user?.login_date})</label>
      </div>
      <div className="row">
        <label>{strings.member.signup_os} : {user?.signup_os == 1 ? 'android' : 'ios'}</label>
      </div>
      <div className="row">
        <label>{strings.member.login_os} : {user?.login_os == 1 ? 'android' : 'ios'}</label>
      </div>
    </div>
  );
};

export default MemberDetailPopupApp;
