import moment from "moment";
import $ from "jquery";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import React, { useContext, useEffect, useRef, useState } from "react";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import { GENDER, PARTNER, STATUS, USER_STATUS } from "../../../config/const";
import MatchPopup from "../popup/match-popup";

const MatchFragment = ({ gender }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const searchOptions = strings.search_option.member;
  const genderRef = useRef(gender);

  useEffect(() => {
    $('#searchColumn').val(searchOptions[0].value);
    $('#searchInput').val('');

    genderRef.current = gender;

    refreshTable(true);
  }, [gender]);

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    user: null
  });
  const onMatch = user => {
    if (user.status != USER_STATUS.NORMAL) {
      context.addNotification(strings.modal.alert, strings.match.invalid_user);
      return;
    }
    if (user.sleep == STATUS.NORMAL) {
      context.addNotification(strings.modal.alert, strings.match.sleep_user);
      return;
    }
    if (moment(user.direct_date).isValid()) {
      context.addNotification(strings.modal.alert, strings.match.direct_user);
      return;
    }

    // 매칭팝업현시
    context.get(
      '/member/detail/' + user.id,
      {},
      response => {
        setPopup(prev => {
          return {
            ...prev,
            isOpen: true,
            user: { ...response.user, ...response.match_info }
          }
        });
      }
    );
  }

  const onToggleActive = user_id => {
    context.post(
      'member/active',
      { id: user_id },
      () => refreshTable(false)
    );
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='searchColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)} />
          <SearchInput
            id='searchInput'
            action={() => refreshTable(true)} />
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
          <label className='m-l-15'>
            <input type='checkbox' id='activeUser' onChange={() => refreshTable(true)} />
            &nbsp;{strings.match.active_user}
          </label>
          {
            Object.keys(strings.member.level_type_list).map(key => (
              <label key={key} className='m-l-15'>
                <input type='checkbox' name='userLevel' value={key} onChange={() => refreshTable(true)} />
                &nbsp;{strings.member.level_type_list[key]}
              </label>
            ))
          }
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'email', orderable: true,
            }, {
              targets: [columnNo++], data: 'profile', orderable: false,
              createdCell: (td, value, item) => {
                if (!value) {
                  $(td).image_lightbox(item.admin_profile, () => {
                    context.showGallery([item.admin_profile.getFullUrl()]);
                  }, item.single);
                } else {
                  $(td).image_lightbox(value, () => {
                    context.showGallery(value.split('#').map(it => it.getFullUrl()));
                  }, item.single);
                }
              }
            }, {
              targets: [columnNo++], data: 'nickname', orderable: true,
              createdCell: (td, value, item) => {
                $(td).nickname_label(item.nickname, item.gender, () => onCell(item.id));
              }
            }, {
              targets: [columnNo++], data: 'status', orderable: true,
              createdCell: (td, value, item) => {
                $(td).user_status(item);
              }
            }, {
              targets: [columnNo++], data: 'active', orderable: true,
              createdCell: (td, value, item) => {
                $(td).html('<input type="checkbox"' + (value == 1 ? ' checked ' : '') + ' />')
                  .find('input')
                  .on('change', () => {
                    if (item.partner_type != PARTNER.CHARGE) {
                      return;
                    }
                    onToggleActive(item.id);
                  });
              }
            }, {
              targets: [columnNo++], data: 'level_type', orderable: true,
              render: value => strings.member.level_type_list[value]
            }, {
              targets: [columnNo++], data: 'level_look', orderable: true,
              render: value => `Lv${value}${value <= 2 ? `(${strings.member.good_look_user})` : ''}`
            }, {
              targets: [columnNo++], data: 'admin_check', orderable: true,
              createdCell: (td, value) => {
                $(td).html(value == 1 ?
                  '<span class="text-success"><i class="fa fa-check"></i></span>' :
                  '<span class=""><i class="fa fa-minus"></i></span>'
                );
              }
            }, {
              targets: [columnNo++], data: 'birth', orderable: true,
              render: value => value.ageFormat()
            }, {
              targets: [columnNo++], data: 'phone', orderable: true,
              createdCell: (td, value, item) => {
                $(td).html(item.phone + '<br/>' + item.name);
              }
            }, {
              targets: [columnNo++], data: 'location', orderable: false,
            }, {
              targets: [columnNo++], data: 'job', orderable: false,
            }, {
              targets: [columnNo++], data: 'key', orderable: true,
              render: value => value.currencyFormat()
            }, {
              targets: [columnNo++], data: 'version_name', orderable: true,
              createdCell: (td, value, item) => {
                if (item.login_os == 1) {
                  $(td).html("<span style='color: green'>" + value + " (G)</span>");
                } else {
                  $(td).html("<span style='color: red'>" + value + " (A)</span>");
                }
              }
            }, {
              targets: [columnNo++], data: 'signup_date', orderable: true,
              render: value => value.dateFormat()
            }, {
              targets: [columnNo++], data: 'login_date', orderable: true,
              render: value => value ? value.dateFormat() : '-'
            }, {
              targets: [columnNo++], data: 'card_all', orderable: true,
              render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
            }, {
              targets: [columnNo++], data: 'card_review', orderable: true,
              render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
            }, {
              targets: [columnNo++], data: 'card_final_confirm', orderable: true,
              render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
            }, {
              targets: [columnNo++], data: 'card_on_going', orderable: true,
              render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html('');
                $(td).append(`
                    <button type='button' class='btn btn-success btn-sm m-2'>
                      ${gender == GENDER.FEMALE ? strings.match.match : strings.match.suggest}
                    </button>
                  `)
                  .find('button:eq(0)')
                  .on('click', () => onMatch(item));
              }
            }],
            order: [[16, 'DESC']],
            ajax: {
              url: 'match/user/list',
              data: (req) => {
                let user_type = [];
                $('input[name=userLevel]:checked').each((idx, it) => user_type.push({
                  level_type: $(it).val(),
                  group_name: ''
                }));

                req.status = 0;
                req.active_user = $('#activeUser').is(':checked') ? 1 : 0;
                req.user_type = JSON.stringify(user_type);
                req.gender = genderRef.current;
                req.column = $('#searchColumn').val();
                req.keyword = $('#searchInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.member.id}</th>
            <th>{strings.member.profile}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.common.status}</th>
            <th>{strings.match.active_user}</th>
            <th>{strings.member.level_type}</th>
            <th>{strings.member.level_look}</th>
            <th>{strings.member.admin_check}</th>
            <th>{strings.member.age}</th>
            <th>{strings.member.phone}</th>
            <th>{strings.member.location}</th>
            <th>{strings.member.job}</th>
            <th>{strings.member.key}</th>
            <th>{strings.member.app_version}</th>
            <th>{strings.common.create_date}</th>
            <th>{strings.member.last_login_date}</th>
            <th>{strings.member.card_all}</th>
            <th>{strings.member.card_review}</th>
            <th>{strings.member.card_final_confirm}</th>
            <th>{strings.member.card_on_going}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
      <MatchPopup
        {...popup}
        toggleModal={() => setPopup(prev => {
          return { ...prev, isOpen: false }
        })}
      />
    </div>
  );
}

export default MatchFragment;
