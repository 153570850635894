import { useContext, useRef } from "react";
import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";
import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";

const MemberDetailPopupBlock = ({ user }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.member;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='form-horizontal form-inline'>
          <SearchSelect
            id='blockColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)} />
          <SearchInput
            id='blockInput'
            action={() => refreshTable(true)} />
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'phone', orderable: false,
            }, {
              targets: [columnNo++], data: 'user_email', orderable: false,
              render: value => value || '-',
            }, {
              targets: [columnNo++], data: 'user_profile', orderable: false,
              createdCell: (td, value, item) => {
                if (value) {
                  $(td).image_lightbox(value, () => {
                    context.showGallery(value.split('#').map(it => it.getFullUrl()));
                  }, item.user_single);
                } else {
                  $(td).html('-');
                }
              }
            }, {
              targets: [columnNo++], data: 'user_nickname', orderable: false,
              createdCell: (td, value, item) => {
                if (value) {
                  $(td).nickname_label(
                    item.user_nickname,
                    item.user_gender,
                    () => {
                      // onUser(item.user_id, () => refreshTable(false));
                    }
                  );
                } else {
                  $(td).html('-');
                }
              }
            }, {
              targets: [columnNo++], data: 'user_location', orderable: false,
              render: value => value || '-',
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'member/block_contact_list',
              data: (req) => {
                req.id = user.id;
                req.column = $('#blockColumn').val();
                req.keyword = $('#blockInput').val();
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.member.phone}</th>
            <th>{strings.member.id}</th>
            <th>{strings.member.profile}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.member.location}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );
};

export default MemberDetailPopupBlock;
