module.exports = {
  // API_URL: 'http://localhost:3002',
  API_URL: 'https://api.ritzy.fourhundred.co.kr',
  // API_URL: 'http://dev.ritzy.fourhundred.co.kr:3002',

  HEADER: {
    AUTH_TOKEN: 'x-access-token',
    CONTENT_TYPE: {
      NAME: 'Content-Type',
      JSON: 'application/json',
      FILE: 'multipart/form-data',
      FORM: 'application/x-www-form-urlencoded'
    },
    LANG: 'Accept-Language',
  },

  //. 각종 시간/일자 포맷
  FORMAT: {
    DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
    DATE: 'YYYY-MM-DD',
    TIME: 'HH:mm:ss',
    MONTH: 'YYYY-MM',
    YEAR: 'YYYY'
  },

  //. 응답코드
  RESULT_CODE: {
    SUCCESS: 0,                     //. 정상
    EMPTY_TOKEN: -1,                //. 토큰정보 없음.
    TOKEN_EXPIRED: -2,              //. 토큰 기한 만료
    EMPTY_PARAM: -3,                //. 요청파라미터 누락
    REVIEW_REQUIRE: -4,             //. 매칭후기 미작성
    ERROR: -10,                     //. api 내부 오류
  },

  //. 사용자 상태코드
  USER_STATUS: {
    LEAVE: -3,                      //. 탈퇴
    BLOCK: -2,                      //. 영구정지
    HOLD: -1,                       //. 홀딩
    PENDING: 0,                     //. 심사대기
    NORMAL: 1,                      //. 정상
  },

  //. 심사중 상태코드
  PENDING_STATUS: {
    PENDING: 0,                     //. 최초심사요청
    REJECT: -1,                     //. 심사거절
    EDIT_NEED: 1,                   //. 심사반려(정보수정필요)
    ADD_FAVOR: 2,                   //. 1차심사합격(취향정보작성전)
    COMPLETE: 3,                    //. 심사완료
    REAPPLY: 4,                     //. 재심사요청(심사반려후 수정하여 다시 요청)
  },

  //. 성별
  GENDER: {
    MALE: 'M',
    FEMALE: 'F'
  },

  //. 상태코드
  STATUS: {
    PENDING: 0,         //. 대기
    NORMAL: 1,          //. 정상
    REJECT: 2,          //. 반려
    DELETED: -1,        //. 삭제
    FINISH: 2,          //. 완료
    CANCEL: 2,          //. 취소
    REAPPLY: 3,         //. 재심요청
    YES: 'Y',
    NO: 'N'
  },

  //. 매칭상태코드
  MATCH_STATUS: {
    PENDING: 0,                           //. 여성회원에게 남성프로필 전달,
    FEMALE_WANT_SEE: 1,                   //. 여성회원이 만남희망,
    MALE_WANT_SEE: 2,                     //. 남성회원이 만남희망,
    FINAL_CONFIRM: 3,                     //. 여성회원이 최종컨펌수락,
    SEND_FAVOR_INFO: 4,                   //. 여성회원이 선호정보전달,
    SUGGEST_SCHEDULE: 5,                  //. 일정제안,
    OK_SCHEDULE: 6,                       //. 일정수락,
    SUGGEST_LOCATION: 7,                  //. 만남장소결정
    CHAT_OPEN: 8,                         //. 채팅창활성
    REVIEW_REQUIRE: 9,                    //. 후기작성
    SHARE_CONTRACT: 10,                   //. 연락처공개
    DIRECT_MEET: 11,                      //. 직진만남중
    FEMALE_PASS: -1,                      //. 여성회원이 패스,
    MALE_PASS: -2,                        //. 남성회원이 패스,
    CANCELED: -10,                        //. 여성회원 무응답 혹은 남성회원무응답으로 최소됨
    FINAL_CONFIRM_CANCEL: -100,           //. 여성회원이 최종컨펌취소,
    CONFIRM_NO_REPLY: -101,               //. 여성의 최종컨펌 무응답으로 취소됨
    FAVOR_INFO_NO_REPLY: -102,            //. 여성의 선호정보 무응답으로 취소됨
    SCHEDULE_NO_REPLY: -103,              //. 일정제안 무응답으로 취소됨
    SCHEDULE_ACCEPT_NO_REPLY: -104,       //. 일정제안 수락 무응답으로 취소됨
    SCHEDULE_NOT_SELECTED: -105,          //. 만남일정이 맞지 않아 취소됨(일정제안회수 4회 초과로 발생)
    LOCATION_NO_REPLY: -106,              //. 만남일정확정후 남성이 만남장소를 전달하지 않아 취소됨
    CHAT_ROOM_LEAVE: -107,                //. 일방이 채팅방 나가기를 하여 취소됨
    USER_BLAME: -108,                     //. 회원신고로 취소됨
    CHAT_SCHEDULE_CANCEL: -109,           //. 채팅방에서 상대회원의 잦은 일정변경으로 피제안자가 매칭취소
    CHAT_3_DAYS_OVER: -110,               //. 3일간 채팅하기방 종료
  },

  //. 매칭로그코드 (MATCH_STATUS 코드외에 로그저장에 필요한 코드로 별도로 추가됨)
  MATCH_LOG_STATUS: {
    SCHEDULE_IN_CHAT: 1000,         //. 채팅방에서 일정변경
    CHAT_ROOM_PAUSE: 1001,          //. 채팅방에서 일정변경으로 인한 채팅방 비활성화
    VOICE_CALL: 1002,               //. 보이스콜
    REACTIVATE: 1003,               //. 재활성화신청
    REACTIVATE_OK: 1004,            //. 재활성화수락
    SCHEDULE_REACTIVATE: 1005,      //. 재활성화이후 일정변경
    CONTRACT_SEND: 1006,            //. 연락처전달
    CONTRACT_ACCEPT: 1007,          //. 연락처수락
    DIRECT_SEND: 1008,              //. 진지한 만남 신청
    DIRECT_ACCEPT: 1009,            //. 진지한 만남 수락
    CURATOR_NEW: 1010,              //. 큐레이터의 새 제안
    CURATOR_MALE_WANT: 1011,        //. 큐레이터 제안 카드 남성이 매칭 희망
    CURATOR_MALE_PASS: 1012,        //. 큐레이터 제안 카드 남성이 패스
    CURATOR_ADMIN_DENY: 1013,       //. 큐레이터 제안 카드 어드민 거절
  },

  //. 큐레이터 제안상태코드
  CURATOR_STATUS: {
    ADMIN_DENY: -2,         //. 관리자 거절
    MALE_PASS: -1,          //. 남성이 패스
    NONE: 0,                //. 큐레이터의 제안이 아닌 CMS 가 전달한 카드
    PENDING: 1,             //. 큐레이터가 제안함
    MALE_WANT_SEE: 2,       //. 남성이 만남을 희망
    FROM_CURATOR: 3,        //. 큐레이터 제안으로 하여 시작된 매칭
  },

  // .파트너타입
  PARTNER: {
    NONE: 0,              //.
    CHARGE: 1,            //. 전담파트너
    SHARE: 2,             //. 공유파트너
  }
}
