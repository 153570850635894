import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import DateTime from "react-datetime";
import moment from "moment";

import strings from "../../../lang/strings";
import ImageSelect from "../../../components/control/image-select";
import { API_URL, FORMAT, STATUS } from "../../../config/const";
import { PageSettings } from "../../../config/page-settings";

const MeetingPopupInfo = ({ id }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState({});
  const [user, setUser] = useState({});
  const [photos, setPhotos] = useState([]);
  const [thumbnail, setThumbnail] = useState({
    url: '',
    origin: '',
    file: null
  });

  useEffect(() => getInfo(), [id]);

  const getInfo = () => {
    context.get(
      'meeting/detail/' + id,
      {},
      response => {
        const meeting = response.meeting;
        setUser(response.user);
        setInfo(meeting);
        setPhotos(meeting.photo.split('#').map(it => {
          return {
            url: it.getFullUrl().getThumbnailUrl(),
            origin: it.getFullUrl().getThumbnailUrl(),
            file: null
          };
        }));
        /*setThumbnail({
          url: meeting.thumbnail.getFullUrl().getThumbnailUrl(),
          origin: meeting.thumbnail.getFullUrl().getThumbnailUrl(),
          file: null
        });*/
      }
    );
  };

  const onChange = e => {
    setInfo(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  const onThumbnailChange = e => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setThumbnail(prev => {
        return {
          ...prev,
          url: reader.result,
          file: file
        }
      });
    };
    reader.readAsDataURL(file);
  }

  const onThumbnailDelete = () => {
    setThumbnail(prev => {
      return {
        ...prev,
        url: prev.file != null ? prev.origin : '',
        origin: prev.file != null ? prev.origin : '',
        file: null,
      };
    });
  }

  const onImageChange = (e, idx) => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setPhotos(prev => {
        return prev.map((it, index) => {
          if (index == idx) {
            it.url = reader.result;
            it.file = file;
          }
          return it;
        })
      });
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = index => {
    setPhotos(prev => {
      return prev.map((it, idx) => {
        if (idx == index) {
          if (it.file != null) {
            it.url = it.origin;
            it.file = null;
          } else {
            it.url = '';
            it.origin = '';
          }
        }
        return it;
      });
    });
  }

  const toggleMood = code => {
    setInfo(prev => {
      let list = prev.mood.split(',').map(it => Number(it)).filter(it => it > 0);
      if (list.includes(code)) {
        list.splice(list.indexOf(code), 1);
      } else {
        list.push(code);
      }
      return {
        ...prev,
        mood: list.join(',')
      };
    });
  }

  const onSave = e => {
    e.preventDefault();

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      if (/*thumbnail.file != null || */photos.filter(it => it.file != null).length > 0) {
        let formData = new FormData();
        /*if (thumbnail.file != null) {
          formData.append('file', thumbnail.file, thumbnail.file.name);
        }*/
        photos.filter(it => it.file != null).map(it => it.file).forEach(file => {
          formData.append('file', file, file.name);
        });

        context.post(
          'upload/images/meeting',
          formData,
          response => {
            save(response.image);
          }
        );
        return;
      }

      save();
    });
  }

  const save = (upload_files = []) => {
    /*let thumb = thumbnail.origin;
    if (thumbnail.file != null && upload_files.length > 0) {
      thumb = upload_files.shift();
    }*/

    const photo = photos.map(it => {
      if (it.file != null && upload_files.length > 0) {
        return upload_files.shift();
      }
      return it.url.replace(API_URL + '/', '');
    }).join('#');

    context.post(
      'meeting/save',
      {
        id: info?.id,
        photo_public: info?.photo_public,
        title: info?.title,
        // thumbnail: thumb,
        money: info?.money,
        location: info?.location,
        mood: info?.mood,
        date: info?.date,
        content: info?.content,
        photo,
      },
      response => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        setInfo(response.meeting);
      }
    );
  }

  return (
    <div className='row'>
      <form onSubmit={onSave}>
        <div className="col-xs-12">
          <div className='row'>
            <label className='col-xl-2 col-form-label'>ID</label>
            <label className='col-xl-8 col-form-label'>{info?.id}</label>
            {
              (info?.status == STATUS.NORMAL || info?.chat_open == STATUS.NORMAL) &&
              <button className='btn btn-sm btn-primary col-xl-2'>{strings.common.save}</button>
            }
          </div>
          <div className='row m-t-5'>
            <label className='col-xl-2 col-form-label'>{strings.common.status}</label>
            {(() => {
              switch (info?.status) {
                case STATUS.NORMAL:
                  return <label className='col-xl-2 col-form-label text-info'>[{strings.meeting.in_progress}]</label>;
                case STATUS.DELETED:
                  return <label className='col-xl-2 col-form-label text-danger'>[{strings.common.deleted}]</label>;
                case STATUS.FINISH:
                  if (info?.chat_open == STATUS.NORMAL) {
                    return <label className='col-xl-2 col-form-label text-grey'>[{strings.meeting.canceled}]</label>;
                  } else if (info?.chat_open == STATUS.FINISH) {
                    return <label className='col-xl-2 col-form-label text-grey'>[{strings.common.completed}]</label>;
                  } else {
                    return <label className='col-xl-2 col-form-label text-info'>[{strings.meeting.in_progress}]</label>;
                  }
              }
            })()}
          </div>
          {/*<div className='row m-t-5'>
            <label className='col-xl-2 col-form-label'>{strings.meeting.thumbnail}</label>
            <div className='col-xs-10'>
              <div className='row'>
                <div className='col-xs-4 col-sm-3 col-md-4 col-xl-4 col-xxl-3'>
                  <ImageSelect
                    height='80%'
                    image_url={thumbnail.url}
                    onSelect={onThumbnailChange}
                    onDelete={onThumbnailDelete}/>
                </div>
              </div>
            </div>
          </div>*/}
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.meeting.writer}</label>
            <label className='col-xl-8 col-form-label'>{user?.nickname} ({user?.email})</label>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.mini_public}</label>
            <div className='col-xs-8 form-inline'>
              <label className='control-label'
                     onClick={() => onChange({ target: { id: 'photo_public', value: STATUS.YES } })}>
                <input className='' type='radio' name='photo_public'
                       checked={(info?.photo_public ?? STATUS.YES) == STATUS.YES}
                       readOnly
                       value={STATUS.YES}/>&nbsp;{strings.lounge.public}
              </label>
              <label className='control-label m-l-10'
                     onClick={() => onChange({ target: { id: 'photo_public', value: STATUS.NO } })}>
                <input className='' type='radio' name='photo_public'
                       checked={info?.photo_public == STATUS.NO}
                       readOnly
                       value={STATUS.NO}/>&nbsp;{strings.lounge.private}
              </label>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.common.title}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="title"
                     value={info?.title || ''} required
                     onChange={onChange}/>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.meeting.money}</label>
            <div className="col-xl-8">
              <select className='form-control' id='money' onChange={onChange} value={info?.money || 0}>
                {context.getSettingList('meet', 'money').map(it => (
                  <option value={it.code} key={it.code}>{it.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.meeting.location}</label>
            <div className="col-xl-8">
              <input type='text' autoComplete='off' className='form-control' id="location"
                     value={info?.location || ''} required
                     onChange={onChange}/>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.meeting.mood}</label>
            <div className="col-xl-9 p-t-5 p-b-5">
              {context.getSettingList('meet', 'mood').map(it => (
                <button type='button'
                        key={`mood-${it.code}`}
                        onClick={() => toggleMood(it.code)}
                        className={classnames('btn btn-sm btn-outline-gray m-r-5 m-b-5', { 'btn-outline-dark': info?.mood?.split(',').map(it => Number(it)).includes(it.code) })}>
                  {it.name}
                </button>
              ))}
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.meeting.date}</label>
            <div className="col-xl-8">
              <DateTime
                initialViewMode='time'
                value={moment(info?.date)}
                dateFormat={FORMAT.DATE}
                timeFormat={true}
                inputProps={{ placeholder: strings.meeting.date }}
                closeOnSelect={true}
                timeConstraints={{
                  hours: { min: 10, max: 22, step: 1 },
                  minutes: { step: 30 }
                }}
                required
                onChange={(e) => {
                  setInfo(prev => {
                    return {
                      ...prev,
                      date: typeof e === 'string' ? e : e.format(FORMAT.DATE_TIME)
                    }
                  });
                }}/>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.common.content}</label>
            <div className="col-xl-8">
              <textarea className='form-control' id="content"
                        value={info?.content || ''} rows={15} required
                        onChange={onChange}/>
            </div>
          </div>
          <div className='row m-t-5'>
            <label className='col-xl-2 col-form-label'>{strings.common.image}</label>
            <div className='col-xs-10'>
              <div className='row'>
                {photos.map((image, index) => (
                  <div className='col-xs-4 col-sm-3 col-md-3 col-xl-2 col-xxl-2' key={index}>
                    <ImageSelect
                      height='100%'
                      image_url={image.url}
                      onSelect={e => onImageChange(e, index)}
                      onDelete={() => onImageDelete(index)}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.lounge.blame_all_cnt}</label>
            <label className='col-xl-8 col-form-label'>{info?.blame_cnt?.currencyFormat() ?? '-'}</label>
          </div>
          <div className='row'>
            <label className='col-xl-2 col-form-label'>{strings.common.create_date}</label>
            <label className='col-xl-8 col-form-label'>{info?.create_date ?? '-'}</label>
          </div>
        </div>
      </form>
    </div>
  );
}

export default MeetingPopupInfo;
