import { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import { Panel, PanelBody } from "../../components/panel/panel";
import strings from "../../lang/strings";
import TermFragment from "./fragment/term-fragment";

const Term = () => {

  const terms = [
    { type: 'usage', title: strings.term.usage },
    { type: 'privacy', title: strings.term.privacy },
    { type: 'position', title: strings.term.position },
    { type: 'marketing', title: strings.term.marketing },
    { type: 'sensitive', title: strings.term.sensitive },
  ]

  const [activeTab, setActiveTab] = useState(terms[0].type);

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {terms.map(({ type, title }, idx) => (
          <NavItem key={idx}>
            <NavLink
              className={classnames({ active: activeTab === type })}
              onClick={() => setActiveTab(type)}>
              <span className="d-none d-xs-inline">{title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        <TermFragment type={activeTab}/>
      </PanelBody>
    </Panel>
  );
}

export default Term;
