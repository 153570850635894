import { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { GENDER, STATUS } from "../../../config/const";
import classnames from "classnames";

const Star = ({ score }) => {
  return (
    <div>
      {[...Array(5).keys()].map(it => (
        <i key={it} className={classnames('fa fa-star', { 'text-yellow': it < score })}/>
      ))}
    </div>
  );
}

const ReviewPopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);

  const [review, setReview] = useState(null);
  const [stars, setStars] = useState([]);

  const getInfo = () => {
    context.get(
      'meeting/review/' + id,
      {},
      response => {
        setReview(response.review);
        setStars(response.stars);
      }
    );
  }

  const toggleModal = result => {
    onClose?.(result);
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} onOpened={() => getInfo()} size={'md'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.meeting.review_detail}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className='row'>
            <label className='col-xl-3'>{strings.meeting.is_meet}</label>
            <label className='col-xl-9'>
              {review?.success == STATUS.YES ? strings.meeting.meet_success : strings.meeting.meet_fail}
            </label>
          </div>
          <div className='row m-t-10'>
            <label className='col-xl-3'>{strings.meeting.problem}</label>
            <label className='col-xl-9'>{review?.content?.nl2br()}</label>
          </div>
          <div className='row m-t-10'>
            <label className='col-xl-3'>{strings.meeting.manner}</label>
            <div className='col-xl-9'>
              {stars.map((it, idx) => (
                <div key={idx} className='d-flex align-items-center m-b-10'>
                  <img
                    style={{ width: 40, height: 40, borderRadius: 20, marginRight: 10 }}
                    src={it.profile.split('#').map(it => it.getFullUrl().getThumbnailUrl())[0]}/>
                  <div className='d-flex flex-column'>
                    <label className='m-b-0'>
                      <span className={it.gender == GENDER.MALE ? 'male' : 'female'}>{it.nickname}</span>
                      &nbsp;({it.alias})
                    </label>
                    <Star score={it.star}/>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
      </ModalFooter>
    </Modal>
  );
}

export default ReviewPopup;
