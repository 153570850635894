import classnames from 'classnames';
import querystring from 'query-string';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';

import { Panel, PanelBody } from '../../components/panel/panel';
import strings from '../../lang/strings';
import IapLogFragment from './fragment/log-fragment';
import FreeKeyLogFragment from "./fragment/free-key-log-fragment";

const IapLog = ({ location, history }) => {

  const tabs = [
    { tab: 0, title: strings.iap.total_log },
    { tab: 1, title: strings.iap.normal_log },
    { tab: 2, title: strings.iap.refund_log },
    { tab: 3, title: strings.iap.free_key_log },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].tab);

  useEffect(() => {
    const searchQS = querystring.parse(location.search);
    setActiveTab(searchQS.tab || tabs[0].tab);
  }, [location.search]);

  const toggleTab = tab => {
    const searchQS = { tab };
    history.push('?' + querystring.stringify(searchQS));
  }

  return (
    <Panel>
      <Nav tabs className="nav-tabs-inverse">
        {tabs.map((menu, idx) => (
          <NavItem key={idx}>
            <NavLink
              className={classnames({ active: activeTab == menu.tab })}
              onClick={() => toggleTab(menu.tab)}>
              <span className="d-none d-xs-inline">{menu.title}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PanelBody>
        {
          activeTab < 3 ?
            <IapLogFragment tab={activeTab} />
            :
            <FreeKeyLogFragment />
        }

      </PanelBody>
    </Panel>
  );

}

export default withRouter(IapLog);
