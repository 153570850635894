import React, { useContext, useEffect, useRef } from "react";
import $ from 'jquery';

import SearchSelect from "../../../components/control/search-select";
import SearchInput from "../../../components/control/search-input";
import DataTable from "../../../components/control/data-table";
import strings from "../../../lang/strings";
import { PageSettings } from "../../../config/page-settings";
import { GENDER, PARTNER, PENDING_STATUS, USER_STATUS } from "../../../config/const";
import { withRouter } from "react-router-dom";
import MemberStatus from "../status";
import { inject } from "mobx-react";
import LocationTable from "../../../components/control/location-table";

export const MEMBER_TYPE = {
  정상활동: 'normal',
  전체: 'all'
}

const MemberNormalFragment = ({ gender, type, history, rootStore }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const searchOptions = strings.search_option.member;
  const genderRef = useRef(gender);
  const locationRef = useRef();

  useEffect(() => {
    if (type == MEMBER_TYPE.전체) {
      $('#memberStatus').val('');
    }
    $('#ageFrom').val('');
    $('#ageTo').val('');
    $('#searchColumn').val(searchOptions[0].value);
    $('#searchInput').val('');
    $('#market').prop('checked', false);

    genderRef.current = gender;

    refreshTable(true);
  }, [gender]);

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };

  const toggleVip = (user_id, vip) => {
    const content = vip == 1 ? strings.modal.vip_confirm : strings.modal.vip_release_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/vip',
        {
          id: user_id,
          vip
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  }

  const releaseHold = user_id => {
    context.showConfirm(strings.modal.hold_release_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/release-hold',
        {
          id: user_id,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  }

  const onToggleActive = user_id => {
    context.post(
      'member/active',
      { id: user_id },
      () => refreshTable(false)
    );
  }

  const onBlock = user => {
    const content = user.status == USER_STATUS.BLOCK ? strings.modal.unblock_confirm : strings.modal.block_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/block',
        {
          id: user.id,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  };

  const onDelete = user_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'member/' + user_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <div className='row'>
      <div className='col-md-8'>
        <div className='form-horizontal form-inline'>
          {
            type == MEMBER_TYPE.전체 &&
            <SearchSelect
              id='memberStatus'
              value={''}
              data={[
                { value: '', name: strings.member.total_member },
                { value: USER_STATUS.NORMAL, name: strings.member.normal },
                { value: USER_STATUS.PENDING, name: strings.member.pending },
                { value: 'SLEEP', name: strings.member.sleep },
                { value: USER_STATUS.BLOCK, name: strings.member.block },
                { value: USER_STATUS.LEAVE, name: strings.member.leave },
                { value: 'VIP', name: 'VIP' },
              ]}
              containerStyle={{ marginRight: 10 }}
              onChange={() => refreshTable(true)} />
          }
          <SearchSelect
            id='searchColumn'
            value={searchOptions[0].value}
            data={searchOptions}
            onChange={() => refreshTable(true)} />
          <SearchInput
            id='searchInput'
            action={() => refreshTable(true)} />
          {/*<span className='width-80 text-right'>{strings.member.birth}:&nbsp;&nbsp;</span>
          <SearchInput
            id='ageFrom'
            type='number'
            className='width-100'
            placeholder={1980}
            action={() => refreshTable(true)} />
          <span className=''>&nbsp;&nbsp;~&nbsp;&nbsp;</span>
          <SearchInput
            id='ageTo'
            type='number'
            className='width-100'
            placeholder={1990}
            value={''}
            action={() => refreshTable(true)} />*/}
          <label className='m-l-10'>
            <input type='checkbox' id='market' />&nbsp;{strings.member.market_agree}
          </label>
          <button type='button' className='btn btn-success btn-sm ml-md-2'
                  onClick={() => refreshTable(true)}>{strings.common.search}</button>
          <label className='m-l-15'>
            <input type='checkbox' id='activeUser' onChange={() => refreshTable(true)} />
            &nbsp;{strings.match.active_user}
          </label>
        </div>
        <div className='form-horizontal form-inline m-t-10'>
          <LocationTable ref={locationRef} />
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'email', orderable: true,
            }, {
              targets: [columnNo++], data: 'profile', orderable: false,
              createdCell: (td, value, item) => {
                if (!value) {
                  $(td).image_lightbox(item.pending_profile ?? item.admin_profile, () => {
                    context.showGallery(item.pending_profile?.map(it => it.getFullUrl()) ?? [item.admin_profile.getFullUrl()]);
                  }, item.single);
                } else {
                  $(td).image_lightbox(value, () => {
                    context.showGallery(value.split('#').map(it => it.getFullUrl()));
                  }, item.single);
                }
              }
            }, {
              targets: [columnNo++], data: 'nickname', orderable: true,
              createdCell: (td, value, item) => {
                $(td).nickname_label(
                  item.nickname ? item.nickname : item.pending_nickname,
                  item.gender,
                  () => onCell(item.id)
                );
                if (item.market == 1) {
                  $(td)
                    .css('background-color', 'lightcyan')
                    .attr('title', strings.member.market_agree);
                }
              }
            }, {
              targets: [columnNo++], data: 'status', orderable: true,
              createdCell: (td, value, item) => {
                $(td).user_status(item);
                if (item.status == USER_STATUS.HOLD && rootStore.isSuper) {
                  $(td).append(`<br/>[<a href="javascript:void(0);">${strings.member.release}</a>]`)
                    .find('a')
                    .bind('click', () => releaseHold(item.id));
                }
              }
            }, {
              targets: [columnNo++], data: 'active', orderable: true,
              createdCell: (td, value, item) => {
                $(td).html('<input type="checkbox"' + (value == 1 ? ' checked ' : '') + ' />')
                  .find('input')
                  .on('change', () => {
                    if (item.partner_type != PARTNER.CHARGE) {
                      return;
                    }
                    onToggleActive(item.id);
                  });
              }
            }, {
              targets: [columnNo++], data: 'level_type', orderable: true,
              render: value => strings.member.level_type_list[value]
            }, {
              targets: [columnNo++], data: 'level_look', orderable: true,
              createdCell: (td, value, item) => {
                if (item.gender == GENDER.MALE) {
                  $(td).html('n/a');
                  return;
                }
                $(td).html(`Lv${value}${value <= 2 ? `(${strings.member.good_look_user})` : ''}`);
              }
            }, {
              targets: [columnNo++], data: 'admin_check', orderable: true,
              createdCell: (td, value) => {
                $(td).html(value == 1 ?
                  '<span class="text-success"><i class="fa fa-check"></i></span>' :
                  '<span class=""><i class="fa fa-minus"></i></span>'
                );
              }
            }, {
              targets: [columnNo++], data: 'birth', orderable: true,
              render: value => value.ageFormat()
            }, {
              targets: [columnNo++], data: 'phone', orderable: true,
              createdCell: (td, value, item) => {
                $(td).html(item.phone + '<br/>' + item.name);
              }
            }, {
              targets: [columnNo++], data: 'location', orderable: false,
            }, {
              targets: [columnNo++], data: 'job', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(value ? value : item.pending_job);
              }
            }, {
              targets: [columnNo++], data: 'key', orderable: true,
              render: value => value.currencyFormat()
            }, {
              targets: [columnNo++], data: 'version_name', orderable: true,
              createdCell: (td, value, item) => {
                if (item.login_os == 1) {
                  $(td).html("<span style='color: green'>" + value + " (G)</span>");
                } else {
                  $(td).html("<span style='color: red'>" + value + " (A)</span>");
                }
              }
            }, {
              targets: [columnNo++], data: 'signup_date', orderable: true,
              render: value => value.dateFormat()
            }, {
              targets: [columnNo++], data: 'login_date', orderable: true,
              render: value => value ? value.dateFormat() : '-'
            }, {
              targets: [columnNo++], data: 'card_all', orderable: true,
              render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
            }, {
              targets: [columnNo++], data: 'card_review', orderable: true,
              render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
            }, {
              targets: [columnNo++], data: 'card_final_confirm', orderable: true,
              render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
            }, {
              targets: [columnNo++], data: 'card_on_going', orderable: true,
              render: value => value == 0 ? '-' : strings.formatString(strings.common.count, value.currencyFormat())
            }, {
              targets: [columnNo++], data: 'vip', orderable: true,
              createdCell: (td, value, item) => {
                $(td).html('');
                if (value == 1) {
                  $(td).html(`<div><span style='color: red'>${strings.member.vip_list[1]}</span></div>`);
                  if (item.partner_type == PARTNER.CHARGE) {
                    $(td).append(`[<a href="javascript:void(0);">${strings.member.vip_release}</a>]`)
                      .find('a')
                      .bind('click', () => {
                        if (item.partner_type != PARTNER.CHARGE) {
                          return;
                        }
                        toggleVip(item.id, 0);
                      });
                  }
                } else if (item.partner_type == PARTNER.CHARGE) {
                  $(td).html(`[<a href="javascript:void(0);">${strings.member.vip_setting}</a>]`)
                    .find('a')
                    .bind('click', () => {
                      if (item.partner_type != PARTNER.CHARGE) {
                        return;
                      }
                      toggleVip(item.id, 1);
                    });
                }
              }
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html('');
                if (
                  item.pending_status == PENDING_STATUS.PENDING ||
                  item.pending_status == PENDING_STATUS.EDIT_NEED
                ) {
                  if (item.partner_type == PARTNER.CHARGE) {
                    $(td).html(`<button type='button' class='btn btn-outline-success btn-sm m-2'>${strings.member.inspect}</button>`)
                      .find('button:eq(0)')
                      .on('click', () => history.push(`/member/detail/${item.id}`));
                  }
                } else {
                  if (rootStore.isSuper) {
                    $(td).html(`<button type='button' class='btn btn-outline-dark btn-sm text-nowrap'>${item.status == USER_STATUS.BLOCK ? strings.member.unblock : strings.member.block}</button>`)
                      .find('button:eq(0)')
                      .on('click', () => onBlock(item));
                  }
                }
                if (rootStore.isSuper) {
                  $(td).append(`<br/><button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.member.db_delete}</button>`)
                    .find('button:eq(1)')
                    .on('click', () => onDelete(item.id));
                }
              }
            }],
            order: [[0, 'DESC']],
            ajax: {
              url: 'member/list',
              data: (req) => {
                req.type = type;
                req.status = type == MEMBER_TYPE.전체 ? $('#memberStatus').val() : USER_STATUS.NORMAL;
                req.age_from = $('#ageFrom').val();
                req.age_to = $('#ageTo').val();
                req.gender = genderRef.current;
                req.column = $('#searchColumn').val();
                req.keyword = $('#searchInput').val();
                req.market = $('#market').is(':checked') ? 1 : 0;
                req.active_user = $('#activeUser').is(':checked') ? 1 : 0;
                req.user_type = JSON.stringify(locationRef.current?.getSelected());
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.member.id}</th>
            <th>{strings.member.profile}</th>
            <th>{strings.common.nickname}</th>
            <th>{strings.common.status}</th>
            <th>{strings.match.active_user}</th>
            <th>{strings.member.level_type}</th>
            <th>{strings.member.level_look}</th>
            <th>{strings.member.admin_check}</th>
            <th>{strings.member.age}</th>
            <th>{strings.member.phone}</th>
            <th>{strings.member.location}</th>
            <th>{strings.member.job}</th>
            <th>{strings.member.key}</th>
            <th>{strings.member.app_version}</th>
            <th>{strings.common.create_date}</th>
            <th>{strings.member.last_login_date}</th>
            <th>{strings.member.card_all}</th>
            <th>{strings.member.card_review}</th>
            <th>{strings.member.card_final_confirm}</th>
            <th>{strings.member.card_on_going}</th>
            <th>{strings.member.vip}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );
}

export default withRouter(inject('rootStore')(MemberNormalFragment));
